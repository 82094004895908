import { ISearchType } from '../../types/search.type';
import { texts } from '../../text';
import { formatCount } from '../papersDiscussionRadio/hooks/usePaperCount';

export const getSearchPlaceholder = (
  type: ISearchType,
  counts: {
    numPapers?: number;
    numTopics?: number;
    numCommunities?: number;
  }
): string => {
  const placeholderMap: Record<ISearchType, { count?: number; label: string }> =
    {
      [ISearchType.Papers]: {
        count: counts?.numPapers,
        label: 'papers'
      },
      [ISearchType.Topics]: {
        count: counts?.numTopics,
        label: 'topics'
      },
      [ISearchType.Communities]: {
        count: counts?.numCommunities,
        label: 'communities'
      }
    };

  const { count, label } = placeholderMap[type] || {};

  return count
    ? `Search ${formatCount(count)} ${label}`
    : texts.searchFieldPlaceHolder;
};
