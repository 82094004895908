import { useCallback } from 'react';
import { useAppDispatch } from '../../../state';
import {
  getUserPost,
  updateUserPostByAdmin,
  updateUserPostByCreator
} from '../../../state/actions/userPostActions';
import { setUserPost } from '../../../state/slices/userPostReducer';

interface IUseUpdatePostParams {
  id?: string;
  postName: string;
  markdown: string;
  selectedTopicTags: string[];
  selectedCommunityTags: string[];
  isUserAdmin: boolean;
  isUserCreator: boolean;
}

export function useUpdatePost(
  onSuccess?: () => void,
  onError?: (error: any) => void
) {
  const dispatch = useAppDispatch();

  return useCallback(
    async ({
      id,
      postName,
      markdown,
      selectedTopicTags,
      selectedCommunityTags,
      isUserAdmin,
      isUserCreator
    }: IUseUpdatePostParams) => {
      try {
        const updatedPost = isUserAdmin
          ? await dispatch(
              updateUserPostByAdmin({
                id,
                data: {
                  title: postName,
                  markdown,
                  topics: selectedTopicTags,
                  communities: selectedCommunityTags
                }
              })
            ).unwrap()
          : isUserCreator
            ? await dispatch(
                updateUserPostByCreator({
                  id,
                  data: {
                    markdown,
                    topics: selectedTopicTags,
                    communities: selectedCommunityTags
                  }
                })
              ).unwrap()
            : null;

        if (updatedPost) {
          dispatch(setUserPost(updatedPost));
        } else {
          await dispatch(getUserPost({ id })).unwrap();
        }

        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        if (onError) {
          onError(error);
        }
      }
    },
    [dispatch, onSuccess, onError]
  );
}
