import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getUserPost,
  updateUserPostByAdmin,
  updateUserPostByCreator,
  deleteUserPost,
  createUserPost
} from '../actions/userPostActions';
import { IUserPost } from '../../types/userPost.type';

interface UserPostState {
  post: IUserPost | null;
  loading: {
    get: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
  };
  error: {
    get: string | null;
    create: string | null;
    update: string | null;
    delete: string | null;
  };
}

const initialState: UserPostState = {
  post: null,
  loading: {
    get: false,
    create: false,
    update: false,
    delete: false
  },
  error: {
    get: null,
    create: null,
    update: null,
    delete: null
  }
};

const userPostSlice = createSlice({
  name: 'userPost',
  initialState,
  reducers: {
    clearUserPost(state) {
      state.post = null;
      state.loading = {
        get: false,
        create: false,
        update: false,
        delete: false
      };
      state.error = { get: null, create: null, update: null, delete: null };
    },
    setUserPost(state, action: PayloadAction<IUserPost>) {
      state.post = action.payload;
    }
  },
  extraReducers: (builder) => {
    // GET
    builder
      .addCase(getUserPost.pending, (state) => {
        state.loading.get = true;
        state.error.get = null;
      })
      .addCase(
        getUserPost.fulfilled,
        (state, action: PayloadAction<IUserPost>) => {
          state.post = action.payload;
          state.loading.get = false;
        }
      )
      .addCase(getUserPost.rejected, (state, action) => {
        state.error.get = action.error.message || 'Failed to fetch the post';
        state.loading.get = false;
      })

      // CREATE
      .addCase(createUserPost.pending, (state) => {
        state.loading.create = true;
        state.error.create = null;
      })
      .addCase(
        createUserPost.fulfilled,
        (state, action: PayloadAction<IUserPost>) => {
          state.post = action.payload;
          state.loading.create = false;
        }
      )
      .addCase(createUserPost.rejected, (state, action) => {
        state.error.create = action.payload || 'Failed to create the post';
        state.loading.create = false;
      })
      // UPDATE by Creator
      .addCase(updateUserPostByCreator.pending, (state) => {
        state.loading.update = true;
        state.error.update = null;
      })

      .addCase(
        updateUserPostByCreator.fulfilled,
        (state, action: PayloadAction<IUserPost>) => {
          state.post = { ...state.post, ...action.payload };
          state.loading.update = false;
        }
      )
      .addCase(updateUserPostByCreator.rejected, (state, action) => {
        state.error.update =
          action.error.message || 'Failed to update the post';
        state.loading.update = false;
      })
      // UPDATE by Admin
      .addCase(updateUserPostByAdmin.pending, (state) => {
        state.loading.update = true;
        state.error.update = null;
      })
      .addCase(
        updateUserPostByAdmin.fulfilled,
        (state, action: PayloadAction<IUserPost>) => {
          state.post = { ...state.post, ...action.payload };
          state.loading.update = false;
        }
      )
      .addCase(updateUserPostByAdmin.rejected, (state, action) => {
        state.error.update =
          action.error.message || 'Failed to update the post';
        state.loading.update = false;
      })

      // Delete by Admin
      .addCase(deleteUserPost.pending, (state) => {
        state.loading.delete = true;
        state.error.delete = null;
      })
      .addCase(
        deleteUserPost.fulfilled,
        (state, action: PayloadAction<number>) => {
          if (state.post?.id === action.payload) {
            state.post = null;
          }
          state.loading.delete = false;
        }
      )
      .addCase(deleteUserPost.rejected, (state, action) => {
        state.error.delete =
          action.error.message || 'Failed to delete the post';
        state.loading.delete = false;
      });
  }
});

export const { clearUserPost, setUserPost } = userPostSlice.actions;

export const userPostReducer = userPostSlice.reducer;
