import { FC } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { createLink } from '../../../utils/helperFunctions';
import { useAppSelector } from '../../../state';
import { IPaperCitations, IPaperReference } from '../../../types/paper.type';

interface CitesCitedItemProps {
  items: (IPaperCitations | IPaperReference)[];
}

/**
 * CitesCitedItem component to display citations or references.
 *
 * @param {CitesCitedItemProps} props - The component props.
 * @returns {JSX.Element} The rendered list of items.
 */
export const CitesCitedItem: FC<CitesCitedItemProps> = ({ items }) => {
  const viewMode = useAppSelector((state) => state.viewModeCitesCitedBy.mode);
  const isTripleView = viewMode === 'triple';

  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{
        450: 1,
        600: isTripleView ? 2 : 1,
        900: isTripleView ? 3 : 1
      }}
      className="w-full"
    >
      <Masonry gutter="14px">
        {items.map((item, index) => {
          const isCitation = 'citing' in item;
          const identifier = isCitation
            ? item.citing
            : (item as IPaperReference).cited;
          const link = createLink('/paper/' + item.oipubPaperId);

          return (
            <div key={identifier + '' + index} className="item">
              <p>
                <strong>DOI:</strong> {identifier}
              </p>
              <p>
                <strong>Title:</strong>{' '}
                {item.title ? (
                  <a
                    href={link}
                    className="text-blue-500 underline hover:opacity-75"
                  >
                    {item.title}
                  </a>
                ) : (
                  'The paper is absent in OiPub.'
                )}
              </p>
            </div>
          );
        })}
      </Masonry>
    </ResponsiveMasonry>
  );
};
