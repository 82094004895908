import React, { useEffect, useState } from 'react';

import { thousandSeparator } from '../../utils/numbers-formatter';
import pluralize from 'pluralize';
import { Detail } from '../paper';
import { ApiTopicResponse } from '../../api/oipub-api';
import { Link } from 'react-router-dom';
import { createLink } from '../../utils/helperFunctions';

export interface Props {
  topics: ApiTopicResponse[] | null | undefined;
  created?: string[] | null | undefined;
  title?: string;
}

export const CommunityMetaData: React.FC<Props> = (props) => {
  const { topics } = props;
  const showTopicsCount = 2;

  const [showTopics, setShowTopics] = useState(false);
  const [renderedTopics, setRenderedTopics] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const renderedTopicsArr: JSX.Element[] = [];
    if (topics) {
      for (const topic of topics.slice(0, showTopicsCount)) {
        if (
          renderedTopicsArr.length < showTopicsCount + 1 &&
          topic.name &&
          topic.urlKey
        ) {
          renderedTopicsArr.push(
            <Link
              to={createLink(`/topic/${topic.urlKey}/papers`)}
              className="btnSmall text-small mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline px-2 leading-none py-2 mb-2 font-medium transition-colors duration-200 bg-white border border-current rounded text-center"
              key={topic.id}
            >
              {topic.name}
            </Link>
          );
        }
      }
    }
    setRenderedTopics(renderedTopicsArr);
  }, [topics]);

  return (
    <aside className="tablet:block tablet:mr-0 flex flex-wrap">
      {/* {publishedYear && publishedYear !== null && publishedYear !== 0 && publishedMonth && publishedMonth !== null ? (
        <Detail heading="Publication Date">{paperPublishedDateNumbers(publishedYear, publishedMonth)}</Detail>
      ) : (
        ""
      )} */}
      {topics && topics.length > 0 && (
        <Detail heading={`${props.title || 'Topics'}`} className="mb-2">
          <div className=" flex flex-col max-w-2xl color-itemContent font-roboto text-ssm mb-2">
            <div className=" flex flex-wrap">{renderedTopics}</div>
            {showTopics && (
              <div className="flex flex-wrap max-w-2xl ">
                {topics &&
                  topics.slice(showTopicsCount, topics.length).map((topic) =>
                    // Ensure topic has a name and urlKey before rendering it
                    topic.name && topic.urlKey ? (
                      <Link
                        to={createLink(`/topic/${topic.urlKey}`)} // Correctly use 'Link' and 'to' attribute
                        className="btnSmall text-small mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline px-2 leading-none py-2 mb-2 font-medium transition-colors duration-200 bg-white border border-current rounded text-center"
                        key={topic.id}
                      >
                        {topic.name}
                      </Link>
                    ) : null
                  )}
              </div>
            )}
            {topics.length > 2 && !showTopics && (
              <div className=" text-small text-gray-600 mb-1">
                <div className="">
                  and {thousandSeparator(topics.length - showTopicsCount)} other{' '}
                  {pluralize('topic', topics.length - 2)}
                </div>
              </div>
            )}
          </div>
          {topics && topics.length > 2 && (
            <div
              className="hover:text-gray-700 flex-shrink-0 w-full text-xs text-gray-600 transition-colors duration-200 cursor-pointer"
              onClick={() => setShowTopics(!showTopics)}
            >
              <button className="btnSmall text-white bg-gradient-to-r text-small from-primaryLight to-primaryDefault px-4 py-2  gap-1 rounded opacity-100 hover:opacity-50 font-medium focus:outline-none w-40 text-center uppercase">
                {showTopics ? 'Hide all topics' : 'Show all topics'}{' '}
              </button>
            </div>
          )}
        </Detail>
      )}
    </aside>
  );
};
