import classNames from 'classnames';

interface Props {
  className?: string;
}

export const EditPostIcon: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
        fill="#EE9844"
      />
      <path
        d="M17.2172 8.05044C17.4284 8.26169 17.4284 8.60294 17.2172 8.81419L16.2259 9.80544L14.1947 7.77419L15.1859 6.78294C15.2871 6.68151 15.4245 6.62451 15.5678 6.62451C15.7111 6.62451 15.8485 6.68151 15.9497 6.78294L17.2172 8.05044ZM7.62427 16.105V14.4584C7.62427 14.3825 7.65135 14.3175 7.70552 14.2634L13.6151 8.35377L15.6464 10.385L9.73135 16.2946C9.6826 16.3488 9.61218 16.3759 9.54177 16.3759H7.8951C7.74343 16.3759 7.62427 16.2567 7.62427 16.105Z"
        fill="white"
      />
    </svg>
  );
};
