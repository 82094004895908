import React from 'react';
import { useAppDispatch, useAppSelector } from '../../state';
import { ViewLine } from '../../svgs/ViewLine';
import { ViewThreeInLine } from '../../svgs/ViewThreeInLine';
import { setViewModeCitesCitedBy } from '../../state/actions/viewModeCitesCitedByActions';

export const CitesCitedByViewToggle: React.FC = () => {
  const dispatch = useAppDispatch();

  const isTripleView =
    useAppSelector((state) => state.viewModeCitesCitedBy.mode) === 'triple';

  const handleSetViewMode = (mode: 'single' | 'triple') => {
    dispatch(setViewModeCitesCitedBy(mode));
  };

  return (
    <div className="flex justify-center w-full pt-0 mb-2 px-4">
      <div className="flex w-full justify-end">
        <div className="flex justify-end btnsOrder">
          <button
            onClick={() => handleSetViewMode('single')}
            className={!isTripleView ? 'active-class' : ''}
            aria-label="Single View"
          >
            <ViewLine />
          </button>
          <button
            onClick={() => handleSetViewMode('triple')}
            className={isTripleView ? 'active-class' : ''}
            aria-label="Triple View"
          >
            <ViewThreeInLine />
          </button>
        </div>
      </div>
    </div>
  );
};
