import { AuthServiceInstance } from '../../api/services/auth';

interface FetchOptions extends RequestInit {
  body?: any;
}

/**
 * Sends a protected request with JWT automatically included in headers.
 * Handles unauthorized responses by logging out the user.
 *
 * @param input - The resource that you wish to fetch.
 * @param init - An object containing any custom settings that you want to apply to the request.
 * @returns A promise that resolves to the Response object.
 */
export const sendProtectedRequest = async (
  input: RequestInfo,
  init?: FetchOptions
): Promise<Response> => {
  const token = AuthServiceInstance.getAccessToken();
  const expiry = AuthServiceInstance.getExpiry();

  const headers = {
    'Content-Type': 'application/json',
    ...(init?.headers || {}),
    ...(token && expiry && new Date(expiry) > new Date()
      ? { Authorization: `Bearer ${token}` }
      : {})
  };

  let body;
  // Check if body is an object and not already stringified, then stringify it
  if (init?.body && typeof init.body !== 'string') {
    body = JSON.stringify(init.body);
  } else {
    body = init?.body;
  }

  const modifiedInit: FetchOptions = {
    ...init,
    headers,
    body
  };

  try {
    const response = await fetch(input, modifiedInit);

    // If unauthorized, clear auth
    if (response.status === 401) {
      AuthServiceInstance.clearAuth();
    }

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('sendProtectedRequest error:', error);
    throw error;
  }
};
