import React, { useState } from 'react';
import { ApiPaperLink } from '../../api/oipub-api';
import { paperPublishedDateNumbers } from '../../utils/month-formatter';
import { analytics } from '../../analytics';
import { thousandSeparator } from '../../utils/numbers-formatter';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../state';
import { IPaper } from '../../types/paper.type';
import { createLink } from '../../utils/helperFunctions';
import {
  selectPaperCitations,
  selectPaperReferences
} from '../../state/selectors/paperSelector';
import { LinkCopy } from '../../svgs/LinkCopy';

interface DetailProps {
  heading: string;
  className?: string;
  children: React.ReactNode;
}

export const Detail: React.FC<DetailProps> = ({
  heading,
  className,
  children
}) => {
  return (
    <div className="tablet:w-full flex-shrink mb-6">
      <div
        className={`mb-0 text-xs font-bold tracking-wide uppercase ${className ?? ''}`}
      >
        {heading}
      </div>
      <h3 className="text-sm leading-snug text-gray-900">{children}</h3>
    </div>
  );
};

export const MetaDatav2: React.FC<IPaper> = () => {
  const paper = useAppSelector((state) => state.paper.paper);
  const doi = paper?.doi;
  const doiLink = `https://doi.org/${doi}`;
  const journal = paper?.journal;
  const publishedYear = paper?.publishedYear;
  const publishedMonth = paper?.publishedMonth;
  const publishedDay = paper?.publishedDay;
  const showAuthorsCount = 2;
  const renderedAuthors: JSX.Element[] = [];
  const renderedTopicsKeywords: JSX.Element[] = [];
  const [showAuthors, setShowAuthors] = useState(false);
  const [showTopicsKeywords, setShowTopicsKeywords] = useState(false);
  const references = useAppSelector(selectPaperReferences);
  const citations = useAppSelector(selectPaperCitations);

  const [copied, setIsCopied] = useState(false);

  const handleCopyDOI = () => {
    if (doi) {
      navigator.clipboard.writeText(doi);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    }
  };

  const formattedDate = paperPublishedDateNumbers(
    publishedYear,
    publishedMonth,
    publishedDay
  );

  if (paper?.authors) {
    for (const author of paper.authors.slice(0, showAuthorsCount)) {
      if (renderedAuthors.length < showAuthorsCount + 1) {
        renderedAuthors.push(
          <div
            className="btnSmall text-small mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline px-2 leading-none py-2  mb-2 font-medium transition-colors duration-200 bg-white border border-current rounded text-center"
            key={author.name}
          >
            {author.name}
          </div>
        );
      }
    }
  }

  if (paper?.tags) {
    for (const tag of paper.tags.slice(0, showAuthorsCount)) {
      if (renderedTopicsKeywords.length < showAuthorsCount) {
        if (tag && tag.urlKey && tag.id) {
          renderedTopicsKeywords.push(
            <Link
              to={createLink(`/topic/${tag.urlKey}/papers`)}
              className="btnSmall text-small mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline px-2 leading-none py-2 mb-2 font-medium transition-colors duration-200 bg-white border border-current rounded text-center"
              key={tag.id}
            >
              {tag.name}
            </Link>
          );
        }
      }
    }
  }

  function handleExternalLink() {
    analytics.events.clickPaperLink();
  }
  const firstLinkUrl = paper && paper.links ? paper.links[0]?.url ?? '' : '';
  const getUrl = (apiPaperLink: ApiPaperLink) => {
    return apiPaperLink.url || '';
  };

  return (
    <aside className="tablet:block tablet:mr-0 block">
      <div className="doiLink relative pr-4">
        <Detail heading="DOI">
          {doi ? (
            <>
              <a href={doiLink} target="_blank" rel="noopener noreferrer">
                {doi}
              </a>
              <button
                onClick={handleCopyDOI}
                className="right-0 top-0 absolute"
              >
                <LinkCopy className={copied ? 'iconActive' : ''} />
              </button>
            </>
          ) : (
            'N/A'
          )}
        </Detail>
      </div>
      {publishedYear && (
        <Detail heading="Publication Date">{formattedDate}</Detail>
      )}
      {citations ? (
        <div className="textInsideYellow">
          <Detail heading="Cited By">
            {citations.length < 10 ? `0${citations.length}` : citations.length}
          </Detail>
        </div>
      ) : (
        <div className="textInsideYellow">
          <Detail heading="Cited By">--</Detail>
        </div>
      )}
      {references ? (
        <div className="textInsideYellow">
          <Detail heading="Cites" className="textInsideYellow">
            {references.length < 10
              ? `0${references.length}`
              : references.length}
          </Detail>
        </div>
      ) : (
        <div className="textInsideYellow">
          <Detail heading="Cites" className="textInsideYellow">
            --
          </Detail>
        </div>
      )}
      {journal?.title && <Detail heading="Journal">{journal.title}</Detail>}
      {journal?.volume && <Detail heading="Volume">{journal.volume}</Detail>}
      {journal?.issue && <Detail heading="Issue">{journal.issue}</Detail>}
      {journal?.publisher && (
        <Detail heading="Publisher">{journal.publisher}</Detail>
      )}
      {/* <div className="mb-3  mr-3 pl-1">
        <button className="btnSmall text-white bg-gradient-to-r text-small from-primaryLight to-primaryDefault px-4 py-2  gap-1 rounded opacity-100 hover:opacity-50 font-medium focus:outline-none w-full text-center uppercase">
          View All Communities
        </button>
      </div> */}
      {paper?.authors && paper.authors.length !== 0 && (
        <Detail heading="Authors" className="mb-2">
          <div className=" flex flex-col max-w-2xl color-itemContent font-roboto text-ssm">
            <div className=" flex flex-wrap">{renderedAuthors}</div>
            {showAuthors && (
              <div className="flex flex-wrap max-w-2xl ">
                {paper &&
                  paper.authors &&
                  paper.authors
                    .slice(showAuthorsCount, paper.authors.length)
                    .map((a) => (
                      <div
                        className="btnSmall text-small mr-1  text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline px-2 leading-none py-2  mb-2 font-medium transition-colors duration-200 bg-white border border-current rounded text-center"
                        key={a.name}
                      >
                        {a.name}
                      </div>
                    ))}
              </div>
            )}
            {paper &&
              paper.authors &&
              paper.authors.length > 2 &&
              !showAuthors && (
                <div className=" text-small text-gray-600 mb-1">
                  <div className="">
                    and{' '}
                    {thousandSeparator(paper.authors.length - showAuthorsCount)}{' '}
                    other {pluralize('author', paper.authors.length - 2)}
                  </div>
                </div>
              )}
          </div>
          {paper.authors && paper.authors.length > 2 && (
            <div
              className="hover:text-gray-700 flex-shrink-0 w-full text-xs text-gray-600 transition-colors duration-200 cursor-pointer mt-1"
              onClick={() => setShowAuthors(!showAuthors)}
            >
              <button className="btnSmall text-white bg-gradient-to-r text-small from-primaryLight to-primaryDefault px-4 py-2  gap-1 rounded opacity-100 hover:opacity-50 font-medium focus:outline-none w-40 text-center uppercase">
                {showAuthors ? 'Hide all authors' : 'Show all authors'}{' '}
              </button>
            </div>
          )}
        </Detail>
      )}
      {paper?.tags && paper.tags.length !== 0 && (
        <Detail heading="Topics" className="mb-2">
          <div className=" flex flex-col max-w-2xl color-itemContent font-roboto text-ssm">
            <div className=" flex flex-wrap">{renderedTopicsKeywords}</div>
            {showTopicsKeywords && (
              <div className="flex flex-wrap max-w-2xl ">
                {paper.tags &&
                  paper.tags
                    .slice(showAuthorsCount, paper.tags.length)
                    .map((tag) => {
                      if (tag && tag.urlKey && tag.id) {
                        return (
                          <Link
                            to={createLink(`/topic/${tag.urlKey}/papers`)}
                            className="btnSmall text-small mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline px-2 leading-none py-2 mb-2 font-medium transition-colors duration-200 bg-white border border-current rounded text-center"
                            key={tag.id}
                          >
                            {tag.name}
                          </Link>
                        );
                      }
                      return null;
                    })}
              </div>
            )}
            {paper &&
              paper.tags &&
              paper.tags.length > 2 &&
              !showTopicsKeywords && (
                <div className=" text-small text-gray-600 mb-1">
                  <div className="">
                    and{' '}
                    {thousandSeparator(paper.tags.length - showAuthorsCount)}{' '}
                    other {pluralize('topic', paper.tags.length - 2)}
                  </div>
                </div>
              )}
          </div>
          {paper.tags && paper.tags.length > 2 && (
            <div
              className="hover:text-gray-700 flex-shrink-0 w-full text-xs text-gray-600 transition-colors duration-200 cursor-pointer mt-1"
              onClick={() => setShowTopicsKeywords(!showTopicsKeywords)}
            >
              <button className="btnSmall text-white bg-gradient-to-r text-small from-primaryLight to-primaryDefault px-4 py-2  gap-1 rounded opacity-100 hover:opacity-50 font-medium focus:outline-none w-40 text-center uppercase">
                {showTopicsKeywords ? 'Hide all topics' : 'Show all topics'}
              </button>
            </div>
          )}
        </Detail>
      )}
      {firstLinkUrl && (
        <Detail heading="Alternative Links" className="mb-0">
          <div className="tablet:ml-auto block tablet:mb-0">
            {paper?.links &&
              paper.links.length > 0 &&
              paper.links.map((l) => {
                const url = getUrl(l);
                return (
                  <a
                    className="text-primary-500 hover:text-primary-400 active:text-primary-200 text-small font-medium transition-colors duration-200 mt-2 flex"
                    href={url}
                    key={l.url}
                    onClick={handleExternalLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {url}
                  </a>
                );
              })}
          </div>
        </Detail>
      )}
    </aside>
  );
};
