import classNames from 'classnames';

interface Props {
  className?: string;
}

export const HasLinks: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block w-full h-full', className)}
      viewBox="0 0 16 16"
      fill="currentColor"
    >
      <path d="M8.70659 5.40666L9.64993 6.34999C10.0833 6.78334 10.4271 7.29781 10.6617 7.86403C10.8962 8.43024 11.0169 9.03712 11.0169 9.64999C11.0169 10.2629 10.8962 10.8697 10.6617 11.436C10.4271 12.0022 10.0833 12.5166 9.64993 12.95L9.41393 13.1853C8.53871 14.0605 7.35167 14.5522 6.11393 14.5522C4.87619 14.5522 3.68914 14.0605 2.81393 13.1853C1.93871 12.3101 1.44702 11.1231 1.44702 9.88533C1.44702 8.64759 1.93871 7.46054 2.81393 6.58533L3.75726 7.52866C3.44551 7.83769 3.19786 8.20526 3.02854 8.61026C2.85923 9.01526 2.77157 9.44972 2.77061 9.88868C2.76965 10.3276 2.8554 10.7625 3.02294 11.1682C3.19049 11.574 3.43652 11.9426 3.74691 12.253C4.05731 12.5634 4.42596 12.8094 4.8317 12.977C5.23743 13.1445 5.67227 13.2303 6.11124 13.2293C6.5502 13.2283 6.98466 13.1407 7.38966 12.9714C7.79466 12.8021 8.16222 12.5544 8.47126 12.2427L8.70726 12.0067C9.33216 11.3816 9.68321 10.5339 9.68321 9.64999C9.68321 8.76611 9.33216 7.91842 8.70726 7.29332L7.76393 6.34999L8.70726 5.40733L8.70659 5.40666ZM13.1853 9.41399L12.2426 8.47132C12.5543 8.16229 12.802 7.79472 12.9713 7.38972C13.1406 6.98472 13.2283 6.55027 13.2292 6.1113C13.2302 5.67234 13.1444 5.2375 12.9769 4.83176C12.8094 4.42603 12.5633 4.05738 12.2529 3.74698C11.9425 3.43658 11.5739 3.19055 11.1682 3.02301C10.7624 2.85547 10.3276 2.76972 9.88862 2.77068C9.44965 2.77164 9.01519 2.85929 8.61019 3.02861C8.2052 3.19793 7.83763 3.44557 7.52859 3.75733L7.29259 3.99333C6.66769 4.61842 6.31664 5.46611 6.31664 6.34999C6.31664 7.23387 6.66769 8.08157 7.29259 8.70666L8.23593 9.64999L7.29259 10.5927L6.34993 9.64999C5.91653 9.21665 5.57275 8.70218 5.3382 8.13596C5.10364 7.56974 4.98292 6.96287 4.98292 6.34999C4.98292 5.73712 5.10364 5.13024 5.3382 4.56403C5.57275 3.99781 5.91653 3.48334 6.34993 3.04999L6.58593 2.81466C7.46114 1.93944 8.64819 1.44775 9.88593 1.44775C11.1237 1.44775 12.3107 1.93944 13.1859 2.81466C14.0611 3.68987 14.5528 4.87692 14.5528 6.11466C14.5528 7.3524 14.0611 8.53944 13.1859 9.41466L13.1853 9.41399Z" />
    </svg>
  );
};
