import { FC } from 'react';
import { Link } from 'react-router-dom';
import { DatePost } from '../../svgs/DatePost';
import { CommentPost } from '../../svgs/CommentPost';
import { ShareButton } from '../../components/share';
import { formatDate } from '../../utils/userPostHelper';
import { IUserPost } from '../../types/userPost.type';
import { createLink } from '../../utils/helperFunctions';

interface Props {
  userPost: IUserPost;
  showThreeItems?: boolean;
}

export const UserPostSummary: FC<Props> = ({ userPost, showThreeItems }) => {
  const postLink = createLink(`/posts/${userPost.id}`);

  const currentUrl = window.location.origin;
  const fullUrl = currentUrl + postLink;

  return (
    <>
      <div
        className="border-b-gray-400 last:border-b-0 hover:bg-primary-100 
      block w-full max-w-3xl mx-auto transition-colors duration-200 border-b"
      >
        <Link to={postLink} className="item w-full ">
          <div className="itemTitle font-bold flex mb-3 cursor-pointer">
            <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight">
              {userPost.title}
            </div>
          </div>
          <div className="itemContent color-itemContent text-itemContent mb-5">
            {(userPost.markdown?.match(/(.*?[.!?])\s/) || [])[1] ||
              userPost.markdown}
          </div>
          <hr />
          <div
            className={`flex justify-between mt-4 ${showThreeItems ? 'flex-col-reverse' : ''}`}
          >
            <div className="itemActions items-center flex justify-between text-black">
              {userPost.createdUtc ? (
                <div className="itemActionDate">
                  <div className="flex items-center">
                    <DatePost />
                    <span className="text-half ml-1">
                      {formatDate(userPost.createdUtc)}
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="itemActionComments">
                <div className="flex items-center">
                  <CommentPost className="flex-shrink-0" />
                  <span className="text-half w-full ml-1">
                    {/* {countFromHook || '0'} */}
                  </span>
                </div>
              </div>
              <div className="itemActionShare">
                <div className="flex items-center">
                  <ShareButton url={fullUrl} />
                </div>
              </div>
            </div>
            <div
              className={`authorLink font-roboto text-half ${showThreeItems ? 'mb-4' : ''}`}
            ></div>
          </div>
        </Link>
      </div>
    </>
  );
};
