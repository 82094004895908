import { AppState } from '../store';

export const selectTopics = (state: AppState) => state.topics.topics;
export const selectTopicsLoading = (state: AppState) => state.topics.loading;
export const selectTopicsError = (state: AppState) => state.topics.error;

export const selectTopicsCreation = (state: AppState) =>
  state.topics.creationDefaultTopics;
export const selectTopicsCreationLoading = (state: AppState) =>
  state.topics.creationDefaultLoading;
export const selectTopicsCreationError = (state: AppState) =>
  state.topics.creationDefaultError;

export const selectLandingTopics = (state: AppState) =>
  state.topics.landingTopics;
export const selectLandingTopicsLoading = (state: AppState) =>
  state.topics.landingLoading;
export const selectLandingTopicsError = (state: AppState) =>
  state.topics.landingErrors;

export const selectTopicsSearchResults = (state: AppState) =>
  state.topics.searchResults;
export const selectTopicsSearchLoading = (state: AppState) =>
  state.topics.searchLoading;
export const selectTopicsSearchError = (state: AppState) =>
  state.topics.searchError;

export const selectTopicsCreationSearchResults = (state: AppState) =>
  state.topics.creationSearchTopics;
export const selectTopicsCreationSearchLoading = (state: AppState) =>
  state.topics.creationSearchLoading;
export const selectTopicsCreationSearchError = (state: AppState) =>
  state.topics.creationSearchError;
