import { useParams } from 'react-router-dom';
import { getCommunity, useAppDispatch } from '../../../state';
import { useEffect } from 'react';

export function useGetCommunity() {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();

  useEffect(() => {
    if (urlKey) {
      const promise = dispatch(getCommunity({ urlKey }));
      promise.unwrap().catch((er) => {
        if (er?.name !== 'AbortError') {
        }
      });

      return () => {
        promise.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, urlKey]);
}
