import { FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ApiCreateUserPostRequest } from '../../api';
import {
  Button,
  Field,
  Input,
  Label,
  Textarea,
  Validation
} from '../../components/form';
import { ApiTopic } from '../../api/oipub-api';

interface Props {
  initialValues: ApiCreateUserPostRequest;
  topics: ApiTopic[];
  onSubmit(_data: ApiCreateUserPostRequest): void;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  topicIds: Yup.array()
});

export const NewPostForm: FC<Props> = ({ initialValues, onSubmit }) => {
  const formik = useFormik<ApiCreateUserPostRequest>({
    initialValues,
    onSubmit,
    validationSchema
  });

  return (
    <form
      className="w-full max-w-md mx-auto mt-10"
      onSubmit={formik.handleSubmit}
    >
      <Field>
        <Label htmlFor="title">Title</Label>
        <Input
          id="title"
          name="title"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        {formik.errors.title && formik.touched.title && (
          <Validation message={formik.errors.title} />
        )}
      </Field>
      {formik.values.topicIds.length > 0 && (
        <Field>
          <Label htmlFor="title">Topic</Label>
          {/* {formik.values.topicIds.map((t) => {
            const topic = topics.find((apit) => apit.id === t);
            if (!topic) return null;
            return <NavLink to={`/topic/${topic.name}`}>{topic.name}</NavLink>;
          })} */}
        </Field>
      )}
      <Field>
        <Label htmlFor="description">Description</Label>
        <Textarea
          id="description"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
          minRows={10}
        />
        {formik.errors.description && formik.touched.description && (
          <Validation message={formik.errors.description} />
        )}
      </Field>
      <div className="flex items-baseline">
        <Button type="submit">Create</Button>
      </div>
    </form>
  );
};
