import { FC } from 'react';
import { CreatePostPage } from './CreatePostPage';
import { usePostForm } from '../hooks/usePostForm';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { ALLOWED_TAGS, ALLOWED_ATTR } from '../../../utils/constants';
import { createLink } from '../../../utils/helperFunctions';
import { useAppDispatch } from '../../../state';
import { createUserPost } from '../../../state/actions/userPostActions';
import { unwrapResult } from '@reduxjs/toolkit';

interface Props {
  closeModal: () => void;
}

export const NewPostContainer: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const {
    postName,
    markdown,
    selectedTopicTags,
    selectedCommunityTags,
    errors,
    inputTopicsValue,
    inputCommunityTags,
    topicTags,
    communityTags,
    onChangePostName,
    onChangeMarkdown,
    removeTopic,
    addTopic,
    removeCommunity,
    addCommunity,
    filterTopics,
    filterCommunities,
    fetchMoreTopics,
    hasMoreTopicPage,
    fetchMoreCommunities,
    hasMoreCommunityPage
  } = usePostForm();

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const topicUrlKeys = selectedTopicTags.map((tag) => tag.urlKey);
      const communityUrlKeys = selectedCommunityTags.map((tag) => tag.urlKey);
      const sanitizedMarkdown = DOMPurify.sanitize(markdown, {
        ALLOWED_TAGS: ALLOWED_TAGS,
        ALLOWED_ATTR: ALLOWED_ATTR
      });
      const action = await dispatch(
        createUserPost({
          title: postName,
          markdown: sanitizedMarkdown,
          topics: topicUrlKeys,
          communities: communityUrlKeys
        })
      );

      const post = unwrapResult(action);

      if (post) {
        navigate(createLink(`/posts/${post.id}`), { state: { post } });
      }

      props.closeModal();
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error('Failed to create post:', error);
    }
  };

  return (
    <CreatePostPage
      topicTags={topicTags}
      communityTags={communityTags}
      selectedTopicTags={selectedTopicTags}
      selectedCommunityTags={selectedCommunityTags}
      removeTopic={removeTopic}
      filterTopics={filterTopics}
      addTopic={addTopic}
      addCommunity={addCommunity}
      removeCommunity={removeCommunity}
      filterCommunities={filterCommunities}
      setPostName={onChangePostName}
      postName={postName}
      setMarkdown={onChangeMarkdown}
      markdown={markdown}
      handleCreate={handleCreate}
      errors={errors}
      inputTopicsValue={inputTopicsValue}
      inputCommunityTags={inputCommunityTags}
      fetchMoreTopics={fetchMoreTopics}
      hasMoreTopicPage={hasMoreTopicPage}
      fetchMoreCommunities={fetchMoreCommunities}
      hasMoreCommunityPage={hasMoreCommunityPage}
    />
  );
};
