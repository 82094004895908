import classNames from 'classnames';

interface Props {
  className?: string;
}

export const ArrowRight: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="currentColor"
    >
      <path
        d="M10.625 7.5L4.375 13.75L3.5 12.875L8.875 7.5L3.5 2.125L4.375 1.25L10.625 7.5Z"
        fill="#1A202C"
      />
    </svg>
  );
};
