import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import classNames from 'classnames';
import { textSizes } from './textSizes';
import { twRounded } from './rounded';

interface Props {
  id?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement> | undefined;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  style?: React.CSSProperties;
  name?: string;
  label?: string;
  value?: string;
  rounded?: string;
  placeholder?: string | undefined;
  maxLength?: number | undefined;
  size?: string;
  defaultValue?: string | undefined;
  required?: boolean;
  rows?: number;
  disabled?: boolean;
}

// eslint-disable-next-line react/display-name
export const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      name,
      onBlur,
      onChange,
      onFocus,
      rounded,
      placeholder,
      maxLength,
      className = '',
      value,
      size = 'base',
      defaultValue,
      required,
      rows = 4,
      disabled = false
    },
    ref
  ) => {
    const textareaClass = classNames(
      'tw-px-4',
      'tw-py-4',
      `${twRounded[rounded as keyof typeof twRounded]}`,
      `${textSizes[size as keyof typeof textSizes]}`,
      'focus:tw-outline-none',
      className,
      { 'opacity-50 cursor-not-allowed': disabled }
    );

    return (
      <textarea
        ref={ref}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        className={textareaClass}
        value={value}
        defaultValue={defaultValue}
        rows={rows}
        required={required}
        disabled={disabled}
      />
    );
  }
);
