import axios from 'axios';
import { AuthServiceInstance } from './api/services/auth';

export function startup() {
  axios.interceptors.request.use((config) => {
    const token = AuthServiceInstance.getAccessToken();
    const expiry = AuthServiceInstance.getExpiry();
    if (token && expiry && new Date(expiry) > new Date()) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
}
