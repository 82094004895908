import { SET_VIEW_MODE, ViewModeAction } from '../actions/viewmodeActions';

export interface ViewState {
  mode: 'single' | 'triple';
}

const initialState: ViewState = {
  mode: 'single'
};

const viewReducer = (
  state = initialState,
  action: ViewModeAction
): ViewState => {
  switch (action.type) {
    case SET_VIEW_MODE:
      return { ...state, mode: action.payload };
    default:
      return state;
  }
};

export default viewReducer;
