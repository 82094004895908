export enum ISearchType {
  Papers = 'papers',
  Topics = 'topics',
  Communities = 'communities'
}

export interface ISearchResults {
  results: ISearchResults[];
  numResults: number;
  page: number;
  numPages: number;
  pageSize: number;

  numAllResults: number;
  numWorkItemResults: number;
  numTopicResults: number;
  numAuthorResults: number;
}
