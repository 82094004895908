import { AppState } from '../store';

export const selectUserPostState = (state: AppState) => state.userPost;

export const selectUserPost = (state: AppState) =>
  selectUserPostState(state).post;

export const selectUserPostLoading = (state: AppState) =>
  selectUserPostState(state).loading;

export const selectUserPostGetLoading = (state: AppState) =>
  selectUserPostState(state).loading.get;

export const selectUserPostUpdateLoading = (state: AppState) =>
  selectUserPostState(state).loading.update;

export const selectUserPostDeleteLoading = (state: AppState) =>
  selectUserPostState(state).loading.delete;

export const selectUserPostError = (state: AppState) =>
  selectUserPostState(state).error;

export const selectUserPostGetError = (state: AppState) =>
  selectUserPostState(state).error.get;

export const selectUserPostUpdateError = (state: AppState) =>
  selectUserPostState(state).error.update;

export const selectUserPostDeleteError = (state: AppState) =>
  selectUserPostState(state).error.delete;
