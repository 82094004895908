import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../state';
import { selectCommunityData } from '../../../state/selectors/communitySelector';
import { useTopicsFormData } from '../../post/hooks/form';

export const useCommunityForm = (isEditMode: boolean) => {
  const communityData = useAppSelector(selectCommunityData);
  const {
    selectedTags: selectedTopicTags,
    setSelectedTags: setSelectedTopicTags,
    availableTags: topicTags,
    inputValue: inputTopicsValue,
    setInputValue: setInputTopicsValue,
    removeTag: removeTopic,
    addTag: addTopic,
    handleInputChange: filterTopics,
    fetchMoreData: fetchMoreTopics,
    hasMore: hasMoreTopicPage
  } = useTopicsFormData();

  const [communityName, setCommunityName] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>({});

  // Set initial community data
  useEffect(() => {
    if (isEditMode && communityData) {
      setCommunityName(communityData.name || '');
      setDescription(communityData.description || '');
      const initialTags =
        communityData.topicTags?.map((tag) => ({
          name: tag.name ?? '',
          urlKey: tag.urlKey ?? ''
        })) || [];
      setSelectedTopicTags(initialTags);
    }
  }, [communityData, isEditMode, setSelectedTopicTags]);

  const handleCommunityNameChange = (name: string) => setCommunityName(name);
  const handleDescriptionChange = (description: string) =>
    setDescription(description);

  return {
    communityName,
    setCommunityName: handleCommunityNameChange,
    description,
    setDescription: handleDescriptionChange,
    topicTags,
    selectedTopicTags,
    setSelectedTopicTags,
    inputTopicsValue,
    setInputTopicsValue,
    errors,
    setErrors,
    onRemoveTopic: removeTopic,
    onAddTopic: addTopic,
    onFilterTopics: filterTopics,
    fetchMoreTopics,
    hasMoreTopicPage
  };
};
