import { FC } from 'react';
import { Link } from 'react-router-dom';
import { DatePost } from '../../svgs/DatePost';
import { CommentPost } from '../../svgs/CommentPost';
import { ShareButton } from '../../components/share';
import { formatDate } from '../../utils/userPostHelper';
import { UserPostDiscussions } from '../../svgs/UserPostDiscussions';
import { thousandSeparator } from '../../utils/numbers-formatter';
import { createLink } from '../../utils/helperFunctions';
import { IUserPostPreview } from '../../types/userPost.type';
import { IDiscussionsPage } from '../../types/discussions.type';
import { useUserPostPreviewData } from './hooks/useUserPostPreviewData';

interface Props {
  discussion: IDiscussionsPage;
  userPost: IUserPostPreview;
  isDiscussion?: boolean;
  showThreeItems?: boolean;
}

export const DiscussionUserPostPreview: FC<Props> = ({
  discussion,
  userPost,
  isDiscussion,
  showThreeItems
}) => {
  const postLink = createLink(`/posts/${userPost.id}`);

  const { fullUrl, previewContent, renderedTopicsKeywords } =
    useUserPostPreviewData({
      userPost
    });

  const numOfTopicsKeywords = userPost.topics ? userPost.topics.length : 0;
  return (
    <>
      <div
        className="border-b-gray-400 last:border-b-0 hover:bg-primary-100 
      block w-full max-w-3xl mx-auto transition-colors duration-200 border-b"
      >
        <Link
          to={postLink}
          className={`item w-full ${isDiscussion ? 'discussion-class' : ''}`}
        >
          {isDiscussion && (
            <div className="disc_item_icon">
              <UserPostDiscussions className="discussion_icon userpost_discussion_icon" />
            </div>
          )}
          <div className="itemTitle font-bold flex mb-3 cursor-pointer">
            <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight">
              {userPost.title}
            </div>
          </div>
          <div className="ql-snow">
            <div
              className="itemContent color-itemContent text-itemContent mb-5 ql-editor ql-editor--show"
              dangerouslySetInnerHTML={{ __html: previewContent || '' }}
            ></div>
          </div>
          <hr />
          <div
            className={`flex justify-between mt-4 ${showThreeItems ? 'flex-col-reverse' : ''}`}
          >
            <div className="itemActions items-center flex justify-between text-black">
              {discussion.createdUtc ? (
                <div className="itemActionDate">
                  <div className="flex items-center">
                    <DatePost />
                    <span className="text-half ml-1">
                      {formatDate(discussion.createdUtc)}
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="itemActionComments">
                <div className="flex items-center">
                  <CommentPost className="flex-shrink-0" />
                  <span className="text-half w-full ml-1">
                    {discussion.numComments || '0'}
                  </span>
                </div>
              </div>
              <div className="itemActionShare">
                <div className="flex items-center">
                  <ShareButton url={fullUrl} />
                </div>
              </div>
            </div>
            <div
              className={`authorLink font-roboto text-half ${showThreeItems ? 'mb-4' : ''}`}
            >
              {renderedTopicsKeywords}
              {numOfTopicsKeywords > 2 && (
                <span className="text-gray-400">
                  + {thousandSeparator(numOfTopicsKeywords - 2)} {'more'}
                </span>
              )}
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};
