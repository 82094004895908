import { FC, useCallback, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { ApiPaper } from '../../api/oipub-api';
import { SearchPagination } from '../../routes/search/search-pagination';
import { Previewv2 } from '../paper';
import { useAppSelector } from '../../state';
import { Loading } from '../core';
import { useGetPapersByTopicUrlKeyData } from './hooks/useGetPapersByTopicUrlKeyData';
import { ErrorComponent } from '../core/error';
import { texts } from '../../text';
import { Link, useNavigate } from 'react-router-dom';
import { createLink } from '../../utils/helperFunctions';
import { setAndGetTopicRaisePriority } from '../../state/actions/topicPapersRaisePriority';
import { ButtonWithTime } from '../buttonWithTime/buttonWithTime';
import { useAuth } from '../../state/hooks/useAuth';

interface PapersTopicListProps {
  urlKey?: string;
  updatePage: (newPage: number) => void;
  isTripleView: boolean;
  currentDiscussionPage: number;
}

export const PapersTopicList: FC<PapersTopicListProps> = ({
  urlKey,
  updatePage,
  isTripleView,
  currentDiscussionPage
}) => {
  const { user } = useAuth();
  const [apiResult, setApiResult] = useState<string | null>(null);
  const navigate = useNavigate();
  const papersSortMethod = useAppSelector(
    (state) => state.sort.paperTopicSortMethod
  );

  const { papers, loading, error } = useGetPapersByTopicUrlKeyData({
    page: currentDiscussionPage,
    pageSize: 10,
    urlKey: urlKey ?? '',
    sortMethod: papersSortMethod
  });

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [navigate]
  );

  const openSignupModal = useCallback(
    () => handleOpenModalHash('signup'),
    [handleOpenModalHash]
  );

  const openLoginModal = useCallback(
    () => handleOpenModalHash('login'),
    [handleOpenModalHash]
  );

  const handleButtonClick = async () => {
    setApiResult(null);
    try {
      const newPosition = await setAndGetTopicRaisePriority(urlKey!);
      setApiResult(
        `Actual topic position in the priority list: ${newPosition.position}`
      );
    } catch (error: any) {
      if (error instanceof Error) {
        setApiResult(error.message);
      } else {
        setApiResult('An unknown error occurred');
      }
    }
  };

  if (loading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <ErrorComponent error={error}></ErrorComponent>;
  }

  if (papers && papers.page?.length === 0) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl pageContentAbout">
          <p>
            {texts.topicRaisePriority}{' '}
            <Link
              className="hover:text-primary-600 transition-colors duration-200 inline roboto"
              to={createLink('/posts/48')}
            >
              here
            </Link>
          </p>
        </div>
        <div className="flex justify-center mt-10 w-full max-w-3xl">
          <ButtonWithTime
            onClick={handleButtonClick}
            initialTime={5}
            buttonText="Raise Priority"
            className=""
            disabled={!user}
          />
        </div>
        {!user && (
          <div className="mt-10 text-center w-full max-w-3xl">
            <p>
              To enable the “Raise Priority” button, please{' '}
              <button
                className="text-primary-500 font-semibold"
                onClick={openLoginModal}
                type="button"
              >
                Login
              </button>
              {' or '}
              <button
                className="text-primary-500 font-semibold"
                onClick={openSignupModal}
                type="button"
              >
                Sign up
              </button>
            </p>
          </div>
        )}
        {apiResult && user && (
          <div className="mt-10 text-center">
            <p>{apiResult}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className=" flex flex-col  items-center h-auto  w-full py-3 mb-2 px-4">
      <div className="flex w-full flex  max-w-3xl ">
        <div className="flex flex-wrap  w-full">
          {papers && papers.page && papers.page.length > 0 && (
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                600: isTripleView ? 2 : 1,
                900: isTripleView ? 3 : 1
              }}
              className="w-full"
            >
              <Masonry gutter="14px">
                {papers.page.map((paper: ApiPaper, idx: number) => (
                  <Previewv2
                    key={`Previewv2${idx}`}
                    paper={paper}
                    showThreeItems={isTripleView}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </div>
      </div>
      {papers.numPages && currentDiscussionPage && (
        <div className="max-w-3xl mx-auto mt-8 w-full">
          <SearchPagination
            currentPage={currentDiscussionPage}
            lastPage={papers.numPages}
            numOfMobilPages={5}
            onPageChange={updatePage}
          />
        </div>
      )}
    </div>
  );
};
