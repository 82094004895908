import React from 'react';
import { PaperPageContainer } from './paper-container';
import { Loading } from '../../components/core';
import { useAppSelector } from '../../state';
import { useGetUserIsAdmin } from '../../components/user';
import { useGetPaper } from './hooks/useGetPaper';
import { PaperTopBar } from '../../components/core/topBars';
import {
  selectPaper,
  selectPaperError,
  selectPaperLoading
} from '../../state/selectors/paperSelector';
import { NotFound } from '../not-found';

export interface Props {}

export const PaperPage: React.FC<Props> = () => {
  useGetUserIsAdmin();
  useGetPaper();

  const paper = useAppSelector(selectPaper);
  const paperLoading = useAppSelector(selectPaperLoading);
  const paperError = useAppSelector(selectPaperError);

  if (!paper && paperError) {
    return <NotFound />;
  }

  if (paperLoading) return <Loading className="imp-static" />;

  return (
    <PaperTopBar>
      <PaperPageContainer />
    </PaperTopBar>
  );
};
