import { useEffect, useState } from 'react';
import {
  useCommunitiesFormData,
  usePostFormData,
  useTopicsFormData
} from './form';
import { useAppSelector } from '../../../state';
import { selectUserPost } from '../../../state/selectors/userPostSelector';
import { Tag } from '../updatePostV2/updatePostContainer';

export const usePostForm = () => {
  const post = useAppSelector(selectUserPost);

  const {
    postName,
    markdown,
    userCreatorId,
    onChangePostName,
    onChangeMarkdown
  } = usePostFormData();

  const {
    selectedTags: selectedTopicTags,
    setSelectedTags: setSelectedTopicTags,
    availableTags: topicTags,
    inputValue: inputTopicsValue,
    setInputValue: setInputTopicsValue,
    removeTag: removeTopic,
    addTag: addTopic,
    handleInputChange: filterTopics,
    fetchMoreData: fetchMoreTopics,
    hasMore: hasMoreTopicPage
  } = useTopicsFormData();
  const {
    selectedTags: selectedCommunityTags,
    setSelectedTags: setSelectedCommunityTags,
    availableTags: communityTags,
    inputValue: inputCommunityValue,
    setInputValue: setInputCommunityValue,
    removeTag: removeCommunity,
    addTag: addCommunity,
    handleInputChange: filterCommunities,
    fetchMoreData: fetchMoreCommunities,
    hasMore: hasMoreCommunityPage
  } = useCommunitiesFormData();

  const [errors, setErrors] = useState<Record<string, string>>({});

  // Get already selected topics from the post
  useEffect(() => {
    const initialTags: Tag[] =
      post?.tags?.map((tag) => ({
        name: tag.name ?? '',
        urlKey: tag.urlKey ?? ''
      })) || [];
    setSelectedTopicTags(initialTags);
  }, [post, setSelectedTopicTags]);

  // Get already selected communities from the post
  useEffect(() => {
    const initialCommunities: Tag[] =
      post?.communities?.map((tag) => ({
        name: tag.name ?? '',
        urlKey: tag.urlKey ?? ''
      })) || [];
    setSelectedCommunityTags(initialCommunities);
  }, [post, setSelectedCommunityTags]);

  return {
    postName,
    markdown,
    userCreatorId,
    selectedTopicTags,
    setSelectedTopicTags,
    selectedCommunityTags,
    setSelectedCommunityTags,
    errors,
    inputTopicsValue,
    setInputTopicsValue,
    inputCommunityTags: inputCommunityValue,
    setInputCommunityTags: setInputCommunityValue,
    topicTags,
    communityTags,
    setErrors,
    onChangePostName,
    onChangeMarkdown,
    removeTopic,
    addTopic,
    removeCommunity,
    addCommunity,
    filterTopics,
    filterCommunities,
    fetchMoreTopics,
    hasMoreTopicPage,
    fetchMoreCommunities,
    hasMoreCommunityPage
  };
};
