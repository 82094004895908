import classNames from 'classnames';

interface Props {
  className?: string;
}

export const Spinner: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block w-full h-full', className)}
      viewBox="0 0 24 24"
      fill="transparent"
      stroke="currentColor"
    >
      <path
        d="M12 6C13.1867 6 14.3467 6.35189 15.3334 7.01118C16.3201 7.67047 17.0892 8.60754 17.5433 9.7039C17.9974 10.8003 18.1162 12.0067 17.8847 13.1705C17.6532 14.3344 17.0818 15.4035 16.2426 16.2426C15.4035 17.0818 14.3344 17.6532 13.1705 17.8847C12.0067 18.1162 10.8003 17.9974 9.7039 17.5433C8.60754 17.0892 7.67047 16.3201 7.01118 15.3334C6.35189 14.3467 6 13.1867 6 12V9.5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M6 9L3 12" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 9L9 12" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
