import { UiActions } from '../actions/uiActions';

export const initialState = {
  adminMode: false
};

export type SearchState = typeof initialState;

export function uiReducer(
  state: SearchState = initialState,
  action: UiActions
): SearchState {
  switch (action.type) {
    case 'UI_SET_ADMIN_MODE':
      return { ...state, adminMode: action.adminMode };
    default:
      return state;
  }
}
