import { TopBar } from '../../components/core/topBars';
import { AboutPageContainer } from './about-page-container';

interface Props {}

export const AboutPage: React.FC<Props> = () => {
  return (
    <TopBar>
      <AboutPageContainer></AboutPageContainer>
    </TopBar>
  );
};
