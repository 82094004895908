import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../state';
import {
  selectLandingTopics,
  selectLandingTopicsError,
  selectLandingTopicsLoading
} from '../../state/selectors/topicsSelector';
import { getLandingTopicsData } from '../../state/actions/topicsActions';

export const useGetLandingTopicsData = ({
  page,
  pageSize,
  sortMethod
}: {
  page: number;
  pageSize: number;
  sortMethod: string;
}) => {
  const dispatch = useAppDispatch();
  const topics = useAppSelector(selectLandingTopics);
  const loading = useAppSelector(selectLandingTopicsLoading);
  const error = useAppSelector(selectLandingTopicsError);

  useEffect(() => {
    const fetchTopics = async () => {
      await dispatch(
        getLandingTopicsData({
          page,
          pageSize,
          sortMethod
        })
      );
    };

    fetchTopics();
  }, [dispatch, page, pageSize, sortMethod]);

  return { topics, loading, error };
};
