import classNames from 'classnames';
import { textColors } from '../textColors';

export interface Props {
  className?: string;
  size?: boolean;
  color?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const HeadingTwo: React.FC<Props> = (props: Props) => {
  const {
    className = [],
    children,
    color = 'black',
    size = false,
    style = {}
  } = props;
  const headingClass = classNames(
    `${textColors[color as keyof typeof textColors]}`,
    { 'text-2xl': !size },
    className
  );

  return (
    <h2 className={headingClass} style={style}>
      {children}
    </h2>
  );
};
