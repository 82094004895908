import React, { useEffect } from 'react';
import { analytics } from '../../analytics';
import { useLocation } from 'react-router-dom';
import { useGetUserIsAdmin } from '../../components/user';
import { HomeTopBar } from '../../components/core/topBars';
import { ResetPasswordPage } from './reset-password-page';

export interface Props {}

export const ResetPasswordPageContainer: React.FC<Props> = () => {
  useGetUserIsAdmin();
  const location = useLocation();

  useEffect(() => {
    const loc = location.pathname + location.search;
    analytics.page(loc);
  }, [location.pathname, location.search]);

  return (
    <HomeTopBar>
      <ResetPasswordPage></ResetPasswordPage>
    </HomeTopBar>
  );
};
