import { useEffect } from 'react';
import { useAppDispatch } from '../../../state';
import { getIsUserAdmin } from '../../../state/actions/userActions';
import { useAuth } from '../../../state/hooks/useAuth';

export const useGetUserIsAdmin = () => {
  const dispatch = useAppDispatch();
  const { user, logout } = useAuth();

  useEffect(() => {
    if (user) {
      dispatch(getIsUserAdmin());
    } else {
      logout();
    }
  }, [dispatch, logout, user]);
};
