interface Props {}

export const AboutPageContainer: React.FC<Props> = () => {
  return (
    <main className="page-content pageContentAbout tablet:mt-20 tablet:px-4 w-full max-w-3xl px-2 pb-32 mx-auto mt-10">
      <h1>
        <u>About Us</u>
      </h1>
      <h2>Concept</h2>
      <p>
        OiPub is a web platform in the field of open science and knowledge
        sharing that works to make information faster and easier for researchers
        to find &amp; share. We are building a new research categorisation and
        organisation model with a social network built around it. This is made
        possible by our NLP data systems that automatically recognise and track
        research subjects in articles, and adapt article-level metrics (like
        citations, views, etc.) into topic-level metrics. This allows us to
        create information &amp; discussion hubs for any and every topic, which
        users can further combine into custom communities which track research
        and discussion in any custom research niche they desire. Through this,
        OiPub aims to make:
      </p>
      <ul className="list-disc ml-8 mt-2">
        <li>Papers easier to find</li>
        <li>Research niches easier to follow</li>
        <li>Publishing research faster and cheaper</li>
      </ul>
      <p>
        <br />
        OiPub is a project by Omni Iota Science Limited, founded in October 2020
        with the aim of facilitating discovery and discussion of research while
        creating a new form of open access publishing system. OiPub received
        support through the EOSC Digital Innovation Hub
        (https://eosc-dih.eu/oipub/) with its partners and service providers,
        OpenAire Nexus and EGI-ACE. As well as the Malta Council for Science and
        Technology and the Malta Information Technology Agency.
      </p>
      <br />
      <h2>Databases &amp; Acknowledgements</h2>
      <p>
        We thank the following service providers, supporters and partners, whose
        services or support helped us in building OiPub:
      </p>
      <ul className="list-disc ml-8 mt-2">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://eosc-dih.eu/"
          >
            EOSC Digital Innovation Hub
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.egi.eu/project/egi-ace/"
          >
            EGI-ACE
          </a>{' '}
          and the{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.egi.eu/egi-dih/"
          >
            EGI Digital Innovation Hub
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.openaire.eu/"
          >
            OpenAire Nexus
          </a>{' '}
          and the{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://graph.openaire.eu/"
          >
            OpenAire Graph
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bip.imis.athena-innovation.gr/"
          >
            BIP Finder
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://doaj.org/">
            Directory of Open Access Journals (DOAJ)
          </a>
        </li>
      </ul>
      <br />
      <h2>Release Notes</h2>
      <p>
        You can find our release notes in the{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://oipub.com/community/oipub-announcements-community"
        >
          OiPub Announcements Community
        </a>
      </p>
    </main>
  );
};
