import { env } from '../env';

// 1. Discussion Sort Methods +++
export const DISCUSSION_SORT_METHODS = {
  LATEST_DISCUSSION: 'created_desc',
  NUM_RECENT_COMMENTS_DESC: 'num_recent_comments_desc',
  NUM_COMMENTS_DESC: 'num_comments_desc'
} as const;

export type DiscussionSortMethod =
  (typeof DISCUSSION_SORT_METHODS)[keyof typeof DISCUSSION_SORT_METHODS];

// 2. Topic Sort Methods +++
export const TOPIC_SORT_METHODS = {
  LATEST_DISCUSSION: 'discussion_created_desc',
  COMMENT_ACTIVITY: 'comments_activity_desc',
  MOST_PAPERS: 'num_papers_desc',
  MOST_DISCUSSIONS: 'num_discussions_desc'
} as const;

export type TopicSortMethod =
  (typeof TOPIC_SORT_METHODS)[keyof typeof TOPIC_SORT_METHODS];

// 3. Community Sort Methods +++
export const COMMUNITY_SORT_METHODS = {
  ALPHABETICAL_ASC: 'alphabetical_asc',
  ALPHABETICAL_DESC: 'alphabetical_desc',
  LATEST_DISCUSSION: 'discussion_created_desc',
  COMMENT_ACTIVITY: 'comments_activity_desc',
  MOST_PAPERS: 'num_papers_desc',
  MOST_DISCUSSIONS: 'num_discussions_desc'
} as const;

export type CommunitySortMethod =
  (typeof COMMUNITY_SORT_METHODS)[keyof typeof COMMUNITY_SORT_METHODS];

// 4. Comment Sort Methods
export const COMMENT_SORT_METHODS = {
  CREATED_ASC: 'created_asc',
  CREATED_DESC: 'created_desc'
} as const;

export type CommentSortMethod =
  (typeof COMMENT_SORT_METHODS)[keyof typeof COMMENT_SORT_METHODS];

// 5. Paper Community Sort Methods
export const PAPER_COMMUNITY_SORT_METHODS = {
  COMMUNITY_PAPERS_HOT: 'community_papers_hot',
  COMMUNITY_PAPERS_LATEST: 'community_papers_latest',
  COMMUNITY_PAPERS_TOP: 'community_papers_top'
} as const;

export type PaperCommunitySortMethod =
  (typeof PAPER_COMMUNITY_SORT_METHODS)[keyof typeof PAPER_COMMUNITY_SORT_METHODS];

// 6. Paper Topic Sort Methods
export const PAPER_TOPIC_SORT_METHODS = {
  TOPIC_PAPERS_HOT: 'topic_papers_hot',
  TOPIC_PAPERS_LATEST: 'topic_papers_latest',
  TOPIC_PAPERS_TOP: 'topic_papers_top'
} as const;

export type PaperTopicSortMethod =
  (typeof PAPER_TOPIC_SORT_METHODS)[keyof typeof PAPER_TOPIC_SORT_METHODS];

// 7. User Posts Sort Methods
export const USERPOSTS_SORT_METHODS = {
  LAST_COMMENT_DESC: 'last_comment_desc',
  NUM_COMMENTS_DESC: 'num_comments_desc',
  NUM_RECENT_COMMENTS_DESC: 'num_recent_comments_desc'
} as const;

export type UserPostsSortMethod =
  (typeof USERPOSTS_SORT_METHODS)[keyof typeof USERPOSTS_SORT_METHODS];

// Sort Method Display Names

// 1. Discussion Sort Method Display Names +++
export const DISCUSSION_SORT_METHOD_DISPLAY_NAMES: Record<
  DiscussionSortMethod,
  { label: string; tooltip: string }
> = {
  created_desc: {
    label: 'Latest Discussion',
    tooltip:
      'Sorts discussions by their start date, with the newest at the top.'
  },
  num_recent_comments_desc: {
    label: 'Comment Activity',
    tooltip: `Sorts discussions by the number of recent comments (created within the last ${env.recentTimeWindow} hours) and the total number of comments, with the most active at the top.`
  },
  num_comments_desc: {
    label: 'Most Comments',
    tooltip:
      'Sorts discussions by the total number of comments, with the highest count at the top.'
  }
};

// 2. Topic Sort Method Display Names +++
export const TOPIC_SORT_METHOD_DISPLAY_NAMES: Record<
  TopicSortMethod,
  { label: string; tooltip: string }
> = {
  discussion_created_desc: {
    label: 'Latest Discussion',
    tooltip:
      'Sorts topics by the start date of discussions, placing topics with the newest discussions at the top.'
  },
  comments_activity_desc: {
    label: 'Comment Activity',
    tooltip: `Sorts topics based on a combination of recent comment activity within the past ${env.recentTimeWindow} hours and the overall comment count.`
  },
  num_papers_desc: {
    label: 'Most Papers',
    tooltip:
      'Sorts topics by the number of papers, with the largest counts at the top.'
  },
  num_discussions_desc: {
    label: 'Most Discussions',
    tooltip:
      'Sorts topics by the number of discussions, with the largest counts at the top.'
  }
};

// 3. Community Sort Method Display Names +++
export const COMMUNITY_SORT_METHOD_DISPLAY_NAMES: Record<
  CommunitySortMethod,
  { label: string; tooltip: string }
> = {
  alphabetical_asc: {
    label: 'Alphabetical ↑',
    tooltip: 'Sorts communities alphabetically (A-Z).'
  },
  alphabetical_desc: {
    label: 'Alphabetical ↓',
    tooltip: 'Sorts communities alphabetically (Z-A).'
  },
  discussion_created_desc: {
    label: 'Latest Discussion',
    tooltip:
      'Sorts communities by the start date of discussions, placing communities with the newest discussions at the top.'
  },
  comments_activity_desc: {
    label: 'Comment Activity',
    tooltip: `Sorts communities based on a combination of recent comment activity within the past ${env.recentTimeWindow} hours and the overall comment count.`
  },
  num_papers_desc: {
    label: 'Most Papers',
    tooltip:
      'Sorts communities by the number of papers, with the largest counts at the top.'
  },
  num_discussions_desc: {
    label: 'Most Discussions',
    tooltip:
      'Sorts communities by the number of discussions, with the largest counts at the top.'
  }
};

// 4. Comment Sort Method Display Names
export const COMMENT_SORT_METHOD_DISPLAY_NAMES: Record<
  CommentSortMethod,
  { label: string; tooltip: string }
> = {
  created_asc: {
    label: 'Oldest',
    tooltip: 'Sorts comments by the oldest first.'
  },
  created_desc: {
    label: 'Most Recent',
    tooltip: 'Sorts comments by the most recent first.'
  }
};

// 5. Paper Community Sort Method Display Names
export const PAPER_COMMUNITY_SORT_METHOD_DISPLAY_NAMES: Record<
  PaperCommunitySortMethod,
  { label: string; tooltip: string }
> = {
  community_papers_hot: {
    label: 'Hot',
    tooltip: ''
  },
  community_papers_latest: {
    label: 'Latest',
    tooltip: ''
  },
  community_papers_top: {
    label: 'Top',
    tooltip: ''
  }
};

// 6. Paper Topic Sort Method Display Names
export const PAPER_TOPIC_SORT_METHOD_DISPLAY_NAMES: Record<
  PaperTopicSortMethod,
  { label: string; tooltip: string }
> = {
  topic_papers_hot: {
    label: 'Hot',
    tooltip: ''
  },
  topic_papers_latest: {
    label: 'Latest',
    tooltip: ''
  },
  topic_papers_top: {
    label: 'Top',
    tooltip: ''
  }
};

// 7. User Posts Sort Method Display Names
export const USERPOSTS_SORT_METHOD_DISPLAY_NAMES: Record<
  UserPostsSortMethod,
  { label: string; tooltip: string }
> = {
  last_comment_desc: {
    label: 'Latest Comment',
    tooltip: ''
  },
  num_comments_desc: {
    label: 'Most Comments',
    tooltip: ''
  },
  num_recent_comments_desc: {
    label: 'Recent Comments',
    tooltip: ''
  }
};

export const TOGGLE_SORT_METHODS: Record<string, [string, string]> = {
  alphabetical: ['alphabetical_asc', 'alphabetical_desc']
};

export type AllSortMethod =
  | DiscussionSortMethod
  | TopicSortMethod
  | CommunitySortMethod
  | CommentSortMethod
  | PaperCommunitySortMethod
  | PaperTopicSortMethod
  | UserPostsSortMethod;

export const SORT_METHOD_DISPLAY_NAMES = {
  discussion: DISCUSSION_SORT_METHOD_DISPLAY_NAMES,
  topic: TOPIC_SORT_METHOD_DISPLAY_NAMES,
  community: COMMUNITY_SORT_METHOD_DISPLAY_NAMES,
  comment: COMMENT_SORT_METHOD_DISPLAY_NAMES,
  paperCommunity: PAPER_COMMUNITY_SORT_METHOD_DISPLAY_NAMES,
  paperTopic: PAPER_TOPIC_SORT_METHOD_DISPLAY_NAMES,
  userPosts: USERPOSTS_SORT_METHOD_DISPLAY_NAMES
} as const;
