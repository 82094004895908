import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ReferralApi } from '../../api';
import { Button, Field, Input, Label, Textarea } from '../../components/form';
import { analytics } from '../../analytics';

interface Props {}

const initialValues = {
  email: '',
  name: '',
  message: ''
};

type FormData = typeof initialValues;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Not a valid email').required('Required'),
  name: Yup.string().required('Required'),
  message: Yup.string()
});

export const InviteForm: React.FC<Props> = () => {
  const [success, setSuccess] = useState<string | null>(null);

  const formik = useFormik<FormData>({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema
  });

  async function handleSubmit(values: FormData) {
    await ReferralApi.send({
      email: values.email,
      name: values.name,
      message: values.message
    });
    formik.resetForm();
    analytics.events.sendReferral();
    setSuccess(values.email);
  }

  return (
    <form
      className="w-full max-w-md mx-auto mt-10"
      onSubmit={formik.handleSubmit}
    >
      <Field>
        <Label htmlFor="email">Their email</Label>
        <Input
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.errors.email && formik.touched.email && (
          <div>{formik.errors.email}</div>
        )}
      </Field>
      <Field>
        <Label htmlFor="name">Their name</Label>
        <Input
          id="name"
          name="name"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.name}
        />
        {formik.errors.name && formik.touched.name && (
          <div>{formik.errors.name}</div>
        )}
      </Field>
      <Field>
        <Label htmlFor="message">A message from you to them</Label>
        <Textarea
          id="message"
          name="message"
          onChange={formik.handleChange}
          value={formik.values.message}
          minRows={3}
        />
        {formik.errors.message && formik.touched.message && (
          <div>{formik.errors.message}</div>
        )}
      </Field>
      <div className="flex items-baseline">
        <Button type="submit">Invite</Button>
      </div>
      {success && (
        <div>
          <span className="text-green-900">{success} has been invited.</span>
        </div>
      )}
    </form>
  );
};
