import React, { useState, useRef, useEffect } from 'react';
import { Input, Paragraph, Flex } from '../../atoms';
import { texts } from '../../text';
import { InputDropDownList } from '../inputDropDownList';
import { Tag } from '../../types/topic.type';
import { Validation } from './validation';
import { sanitizeTextReturnWithoutTags } from '../../utils/helperFunctions';
import {
  selectCommunitiesCreationError,
  selectCommunitiesCreationSearchError
} from '../../state/selectors/communitiesSelector';
import { useAppSelector } from '../../state';

interface CommunityTagsInputProps {
  communityTags: Tag[];
  selectedCommunityTags: Tag[];
  onAddCommunity: (id: string) => void;
  onRemoveCommunity: (urlKey: string) => void;
  filterCommunities: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputCommunityTagsValue: string;
  isUserAdmin?: boolean;
  isUserCreator?: boolean;
  explanationText: string;
  maxLimit: number;
  error?: string;
  touched?: boolean;
  onBlur?: (e: any) => void;
  fetchMoreCommunities: () => void;
  hasMore: boolean;
}

export const CommunityTagsInput: React.FC<CommunityTagsInputProps> = ({
  communityTags,
  selectedCommunityTags,
  onAddCommunity,
  onRemoveCommunity,
  filterCommunities,
  inputCommunityTagsValue,
  isUserAdmin = false,
  isUserCreator = true,
  explanationText,
  maxLimit,
  error,
  touched,
  onBlur,
  fetchMoreCommunities,
  hasMore
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const limitReached = selectedCommunityTags.length >= maxLimit;

  const isSearching = inputCommunityTagsValue.length >= 1;
  const errorFetch = useAppSelector(
    isSearching
      ? selectCommunitiesCreationSearchError
      : selectCommunitiesCreationError
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wrapperRef]);

  const handleAddCommunity = (id: string) => {
    if (!limitReached) {
      onAddCommunity(id);
    }
  };

  const handleRemoveCommunity = (urlKey: string) => {
    onRemoveCommunity(urlKey);
  };

  return (
    <div className="w-full">
      <Paragraph
        size="xs"
        color="black"
        className={`mb-4 ${!isUserAdmin && !isUserCreator ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {`${explanationText} (max ${maxLimit})`}
      </Paragraph>
      <div ref={wrapperRef} className="w-full">
        <Input
          type="text"
          size="xs"
          placeholder="Community Tags"
          className={`h-8 w-full px-3 border ${touched && error ? 'border-error-500' : ''} ${touched && !error ? 'border-green-500' : ''} ${
            !touched ? 'border-gray-400' : ''
          }`}
          onChange={filterCommunities}
          value={inputCommunityTagsValue}
          onFocus={() => setIsDropdownVisible(true)}
          onBlur={onBlur}
          disabled={(!isUserAdmin && !isUserCreator) || limitReached}
        />

        {isDropdownVisible && (
          <InputDropDownList
            elements={communityTags}
            hidden={!isDropdownVisible || limitReached}
            selectElement={handleAddCommunity}
            onLoadMore={fetchMoreCommunities}
            hasMore={hasMore}
            errorFetch={errorFetch}
          />
        )}
        {touched && error && <Validation message={error} />}
        {limitReached && (
          <div
            className="text-xs text-error-600 mt-2"
            dangerouslySetInnerHTML={{ __html: texts.limitCommunity }}
          ></div>
        )}
        <Flex
          direction="flex-row flex-wrap mb-6"
          className={`justify-start ${!isUserAdmin && !isUserCreator ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {selectedCommunityTags.map((tag) => {
            const cleanName = sanitizeTextReturnWithoutTags(tag.name);
            return (
              <div key={tag.urlKey} className="mr-3 mt-3">
                <button
                  className="btnSmall text-small text-primary-400 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline px-2 leading-none py-2 font-medium transition-colors duration-200 bg-white border border-current rounded text-center"
                  onClick={() => handleRemoveCommunity(tag.urlKey)}
                  type="button"
                >
                  {cleanName}
                </button>
              </div>
            );
          })}
        </Flex>
      </div>
    </div>
  );
};
