import { FC } from 'react';
import { Loading } from '../../components/core';
import { UserPostContainer } from './user-post-container';
import { DiscussionContainer } from '../../components/discussion/discussion-container';
import { useGetUserPost } from './hooks/useGetPost';
import {
  selectUserPost,
  selectUserPostGetError,
  selectUserPostGetLoading
} from '../../state/selectors/userPostSelector';
import { useAppSelector } from '../../state';
import { useGetUserIsAdmin } from '../../components/user';
import { PostTopBar } from '../../components/core/topBars';
import { NotFound } from '../not-found';

interface Props {}

export const UserPostPage: FC<Props> = () => {
  useGetUserPost();
  useGetUserIsAdmin();
  const isLoading = useAppSelector(selectUserPostGetLoading);
  const userPost = useAppSelector(selectUserPost);
  const userPostGetError = useAppSelector(selectUserPostGetError);

  if (isLoading) {
    return <Loading className="imp-static" />;
  }

  if (!userPost && userPostGetError) {
    return <NotFound />;
  }

  return (
    <PostTopBar>
      <div className="w-full h-full">
        <div className="paperBorder"></div>
        <main className="page-content post-content pt-8 pb-6 tablet:px-12 px-6 ">
          <div className="">
            <UserPostContainer />
          </div>
        </main>
        <DiscussionContainer />
      </div>
    </PostTopBar>
  );
};
