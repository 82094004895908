import React, { useState, useRef, useEffect } from 'react';
import { Input, Paragraph, Flex } from '../../atoms';
import { InputDropDownList } from '../inputDropDownList';
import { texts } from '../../text';
import { Tag } from '../../types/topic.type';
import { Validation } from './validation';
import { sanitizeTextReturnWithoutTags } from '../../utils/helperFunctions';
import {
  selectTopicsCreationError,
  selectTopicsCreationSearchError
} from '../../state/selectors/topicsSelector';
import { useAppSelector } from '../../state';

interface TopicTagsInputProps {
  topicTags: Tag[];
  selectedTopicTags: Tag[];
  onAddTopic: (elem: string) => void;
  onRemoveTopic: (urlKey: string) => void;
  filterTopics: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputTopicsValue: string;
  isUserAdmin?: boolean;
  isUserCreator?: boolean;
  explanationText: string;
  maxLimit: number;
  error?: string;
  touched?: boolean;
  onBlur?: (e: any) => void;
  fetchMoreTopics?: () => void;
  hasMore: boolean;
}

export const TopicTagsInput: React.FC<TopicTagsInputProps> = ({
  topicTags,
  selectedTopicTags,
  onAddTopic,
  onRemoveTopic,
  filterTopics,
  inputTopicsValue,
  isUserAdmin = false,
  isUserCreator = true,
  explanationText,
  maxLimit,
  error,
  touched,
  onBlur,
  fetchMoreTopics,
  hasMore
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const limitReached = selectedTopicTags.length >= maxLimit;

  const isSearching = inputTopicsValue.length >= 1;
  const errorFetch = useAppSelector(
    isSearching ? selectTopicsCreationSearchError : selectTopicsCreationError
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wrapperRef]);

  const handleAddTopic = (elem: string) => {
    if (!limitReached) {
      onAddTopic(elem);
    }
  };

  const handleRemoveTopic = (urlKey: string) => {
    onRemoveTopic(urlKey);
  };

  return (
    <div className="w-full">
      <Paragraph
        size="xs"
        color="black"
        className={`mb-4 ${
          !isUserAdmin && !isUserCreator ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {`${explanationText} (max ${maxLimit})`}
      </Paragraph>
      <div ref={wrapperRef} className="w-full">
        <Input
          type="text"
          size="xs"
          placeholder="Topic Tags"
          className={`h-8 w-full px-3 border ${touched && error ? 'border-error-500' : ''} ${touched && !error ? 'border-green-500' : ''} ${
            !touched ? 'border-gray-400' : ''
          }`}
          onChange={filterTopics}
          value={inputTopicsValue}
          onFocus={() => setIsDropdownVisible(true)}
          onBlur={onBlur}
          disabled={(!isUserAdmin && !isUserCreator) || limitReached}
        />
        {isDropdownVisible && (
          <InputDropDownList
            elements={topicTags}
            hidden={!isDropdownVisible || limitReached}
            selectElement={handleAddTopic}
            onLoadMore={fetchMoreTopics}
            hasMore={hasMore}
            errorFetch={errorFetch}
          />
        )}
        {touched && error && <Validation message={error} />}
        {limitReached && (
          <div
            className="text-xs text-error-600 mt-2"
            dangerouslySetInnerHTML={{ __html: texts.limitTopics }}
          ></div>
        )}
        <Flex
          direction="flex-row flex-wrap mb-6"
          className={`justify-start ${
            !isUserAdmin && !isUserCreator
              ? 'opacity-50 cursor-not-allowed'
              : ''
          }`}
        >
          {selectedTopicTags.map((tag) => {
            const cleanName = sanitizeTextReturnWithoutTags(tag.name);
            return (
              <div key={tag.urlKey} className="mr-3 mt-3">
                <button
                  className="btnSmall text-small text-primary-400 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline px-2 leading-none py-2 font-medium transition-colors duration-200 bg-white border border-current rounded text-center"
                  onClick={() => handleRemoveTopic(tag.urlKey)}
                  type="button"
                >
                  {cleanName}
                </button>
              </div>
            );
          })}
        </Flex>
      </div>
    </div>
  );
};

export default TopicTagsInput;
