import classNames from 'classnames';

interface Props {
  className?: string;
}

export const MinusCircle: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block  h-full', className)}
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      width="17"
      height="17"
    >
      <path
        fillRule="evenodd"
        fill="#CBD5E0"
        d="m2.4 14.5c-3.1-3.3-3.1-8.7 0-12 3.2-3.3 8.3-3.3 11.5 0 3.1 3.3 3.1 8.7 0 12-3.2 3.3-8.3 3.3-11.5 0zm5.7-7.2l-2.2-2.4-1.2 1.2 2.3 2.4-2.3 2.4 1.2 1.2 2.2-2.4 2.3 2.4 1.2-1.2-2.3-2.4 2.3-2.4-1.2-1.2z"
      />
    </svg>
  );
};
