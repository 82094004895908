import { useCallback } from 'react';
import { useAppDispatch } from '../../../state';
import { deleteUserPost } from '../../../state/actions/userPostActions';

interface IUseDeletePostParams {
  postId: number;
}

export function useDeletePost(
  onSuccess?: () => void,
  onError?: (error: any) => void
) {
  const dispatch = useAppDispatch();

  return useCallback(
    ({ postId }: IUseDeletePostParams) => {
      dispatch(deleteUserPost({ postId }))
        .unwrap()
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((error) => {
          if (onError) onError(error);
        });
    },
    [dispatch, onSuccess, onError]
  );
}
