import { Link } from 'react-router-dom';
import { DatePost } from '../../svgs/DatePost';
import { ShareButton } from '../share';
import { ICommonHub } from '../../types/hub.type';
import { RenderHTML } from '../paper/render-html';

export const HubPreviewv2 = ({
  hub,
  url
}: {
  hub: ICommonHub;
  url: string;
}) => {
  const currentUrl = window.location.origin;
  const fullUrl = currentUrl + url;

  return (
    <div
      className="border-b-gray-400 last:border-b-0 hover:bg-primary-100 
      block w-full max-w-3xl mx-auto transition-colors duration-200 border-b"
    >
      <Link to={url} className="item w-full">
        <div className="itemTitle font-bold flex mb-3 cursor-pointer">
          <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight">
            <RenderHTML htmlContent={hub.name} />
          </div>
        </div>
        {hub.description && (
          <div className="itemContent color-itemContent text-itemContent mb-5">
            <RenderHTML htmlContent={hub.description} />
          </div>
        )}
        <hr />
        <div className="itemActions flex justify-between items-center text-black mt-4">
          {hub.createdUtc ? (
            <div className="itemActionDate">
              <div className="flex items-center">
                <DatePost />
                <span className="text-half ml-1">
                  {new Date(hub.createdUtc).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
                </span>
              </div>
            </div>
          ) : null}
          {/* <div className="itemActionComments">
            <Link to={url} className="flex items-center">
              <CommentPost />
              <span className="text-half ml-1"></span>
            </Link>
          </div> */}
          <div className="itemActionShare">
            <div className="flex items-center">
              <ShareButton url={fullUrl} />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
