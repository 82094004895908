import classNames from 'classnames';

interface Props {
  className?: string;
}

export const PlusCircle: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block  h-full', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.5 17C3.80545 17 0 13.1946 0 8.5C0 3.80545 3.80545 0 8.5 0C13.1946 0 17 3.80545 17 8.5C17 13.1946 13.1946 17 8.5 17ZM7.65 7.65H4.25V9.35H7.65V12.75H9.35V9.35H12.75V7.65H9.35V4.25H7.65V7.65Z"
        fill="white"
      />
    </svg>
  );
};
