import { useCallback, useState, useRef, useEffect, forwardRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Validation } from '../form';

interface RichTextEditorProps {
  value: string;
  setValue: (value: string) => void;
  maxLength: number;
  disabled?: boolean;
  error?: string;
  touched?: boolean;
  onBlur?: (e: any) => void;
  placeholder?: string;
  selection?: { start: number; end: number };
}

export const RichTextEditor = forwardRef<ReactQuill, RichTextEditorProps>(
  (
    {
      value,
      setValue,
      maxLength,
      disabled = false,
      error,
      touched,
      onBlur,
      placeholder
    },
    ref
  ) => {
    const [counter, setCounter] = useState(0);
    const quillRef = useRef<ReactQuill | null>(null);

    // Forward the ref to ReactQuill
    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(quillRef.current);
        } else if (ref) {
          ref.current = quillRef.current;
        }
      }
    }, [ref]);

    useEffect(() => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'text/html');
      const textContent = doc.body.textContent || '';
      setCounter(textContent.length);
    }, [value]);

    const handleChange = useCallback(
      (content: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const textContent = doc.body.textContent || '';
        const length = textContent.length;
        setCounter(length);

        if (length <= maxLength) {
          setValue(content);
        } else {
          const quillEditor = quillRef.current?.getEditor();
          if (quillEditor) {
            const truncatedContent = textContent.slice(0, maxLength);
            const delta = quillEditor.clipboard.convert(truncatedContent);
            quillEditor.setContents(delta);
            setValue(quillEditor.root.innerHTML);
            setCounter(maxLength);
          }
        }
      },
      [maxLength, setValue]
    );

    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: ['', 'right', 'center'] }],
        [
          { script: 'sub' },
          { script: 'super' },
          { list: 'ordered' },
          { list: 'bullet' },
          'link',
          'clean'
        ]
      ],
      clipboard: {
        matchVisual: false
      }
    };

    const formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'link',
      'image',
      'script',
      'align'
    ];

    return (
      <div className="relative">
        <div
          className={`border ${
            touched && error
              ? 'border-error-500'
              : touched
                ? 'border-green-500'
                : 'border-gray-400'
          }`}
        >
          <ReactQuill
            ref={quillRef}
            value={value}
            onChange={handleChange}
            readOnly={disabled}
            modules={modules}
            formats={formats}
            onBlur={onBlur}
            placeholder={placeholder}
          />
        </div>
        <div
          className={`absolute right-0 text-ssm ${
            counter >= maxLength ? 'text-error-600' : 'text-gray-600'
          }`}
        >
          {counter}/{maxLength}
        </div>
        {touched && error && <Validation message={error} />}
      </div>
    );
  }
);

RichTextEditor.displayName = 'RichTextEditor';
