import { env } from '../../env';
import { IErrorResponse } from '../../types/error.type';
import { sendProtectedRequest } from './sendProtectedRequest';

interface TopicPriorityResponse {
  position: number;
}

export const setAndGetTopicRaisePriority = async (
  topicKey: string
): Promise<TopicPriorityResponse> => {
  try {
    const url = `${env.apiUrl}/api/v1/topics/${topicKey}/priority-weight`;

    const response = await sendProtectedRequest(url, {
      method: 'PATCH'
    });

    if (!response.ok) {
      const errorData: IErrorResponse = await response.json();
      throw new Error(
        errorData.message ||
          `Error: ${response.status} - ${response.statusText}`
      );
    }

    const data: TopicPriorityResponse = await response.json();
    return data;
  } catch (error: any) {
    throw new Error(error.message || 'Failed to fetch data from the server.');
  }
};
