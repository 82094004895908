import { FC } from 'react';
import { Loading } from '../../components/core';
import { CommunityPage } from './communityPage';
import {
  selectCommunityData,
  selectCommunityGetError,
  selectCommunityLoading
} from '../../state/selectors/communitySelector';
import { useAppSelector } from '../../state';
import { useGetCommunity } from './hooks/useGetCommunity';
import { useGetUserIsAdmin } from '../../components/user';
import { CommunityTopBar } from '../../components/core/topBars';
import { NotFound } from '../not-found';

interface Props {}

export const CommunityContainer: FC<Props> = () => {
  useGetCommunity();
  useGetUserIsAdmin();
  const loading = useAppSelector(selectCommunityLoading);
  const community = useAppSelector(selectCommunityData);
  const communityGetError = useAppSelector(selectCommunityGetError);

  if (!loading) return <Loading className="imp-static" />;

  if (!community && communityGetError) {
    return <NotFound />;
  }

  return (
    <CommunityTopBar>
      <main className="page-content max-w-2/3">
        <CommunityPage />
      </main>
    </CommunityTopBar>
  );
};
