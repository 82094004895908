import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../state';
import { getLandingCommunitiesData } from '../../state/actions/communitiesActions';
import {
  selectLandingCommunities,
  selectLandingCommunitiesError,
  selectLandingCommunitiesLoading
} from '../../state/selectors/communitiesSelector';

export const useGetLandingCommunitiesData = ({
  page,
  pageSize,
  communityType,
  sortMethod
}: {
  page: number;
  pageSize: number;
  communityType: number;
  sortMethod: string;
}) => {
  const dispatch = useAppDispatch();
  const communities = useAppSelector(selectLandingCommunities);
  const loading = useAppSelector(selectLandingCommunitiesLoading);
  const error = useAppSelector(selectLandingCommunitiesError);

  useEffect(() => {
    const fetchCommunities = async () => {
      await dispatch(
        getLandingCommunitiesData({
          page,
          pageSize,
          communityType,
          sortMethod
        })
      );
    };

    fetchCommunities();
  }, [dispatch, page, pageSize, communityType, sortMethod]);

  return { communities, loading, error };
};
