import React from 'react';

interface ErrorComponentProps {
  error: string;
}

export const ErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => {
  return (
    <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
      <div className="flex w-full max-w-3xl">
        <p>{error}</p>
      </div>
    </div>
  );
};
