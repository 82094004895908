import { useEffect } from 'react';
import { getDeletedPostsByTopic } from '../../../state/actions/userPostsActions';
import { clearDeletedPosts } from '../../../state/slices/userPostsReducer';
import { useAppDispatch, useAppSelector } from '../../../state';
import { useParams } from 'react-router-dom';

export function useGetDeletedUserPostsByTopic() {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();
  const key = urlKey || '';
  const deletedUserPostsSortMethod = useAppSelector(
    (state) => state.sort.userPostsSortMethod
  );
  useEffect(() => {
    dispatch(
      getDeletedPostsByTopic({ key, order: deletedUserPostsSortMethod })
    );
    return () => {
      dispatch(clearDeletedPosts());
    };
  }, [dispatch, key, deletedUserPostsSortMethod]);
}
