import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { paperPublishedMonthYear } from '../../utils/month-formatter';
import { thousandSeparator } from '../../utils/numbers-formatter';
import { analytics } from '../../analytics';
import { ShareButton } from '../share';
import { CommentPost } from '../../svgs/CommentPost';
import { DatePost } from '../../svgs/DatePost';
import ArticleDiscussions from '../../svgs/ArticleDiscussions';
import { RenderHTML } from './render-html';
import { createLink } from '../../utils/helperFunctions';
import { IPaper } from '../../types/paper.type';
import { usePaperPreviewData } from './hooks/usePaperPreviewData';
import { findMatchesInAbstract } from './utils/findMatchesInAbstract';

export interface Props {
  paper: IPaper;
  trackingLabel?: 'search' | 'feed' | 'topic';
  isDiscussion?: boolean;
  showThreeItems?: boolean;
}

export const Previewv2: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { paper, trackingLabel } = props;

  const handleTopicTagClick = useCallback(
    (
      event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
      urlKey: string | null | undefined
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (urlKey) {
        navigate(createLink(`/topic/${urlKey}`));
      }
    },
    [navigate]
  );

  const {
    commentCount,
    renderedLinksAvailable,
    renderedTopicsKeywords,
    fullUrl,
    numOfTopicsKeywords,
    authorsDisplay
  } = usePaperPreviewData({ paper, handleTopicTagClick });

  const handleOpenPaper = useCallback(() => {
    if (trackingLabel) {
      analytics.events.openPaper(trackingLabel);
    }
  }, [trackingLabel]);

  let url = createLink('/paper');
  if (paper?.id) {
    url += `/${paper.id}`;
  }

  const AbstractFirstSentence = (props.paper.abstract?.match(/(.*?[.!?])\s/) ||
    [])[0];

  const matches = findMatchesInAbstract(paper.abstract!);
  return (
    <div className="border-b-gray-400 last:border-b-0 hover:bg-primary-100 block w-full max-w-3xl mx-auto transition-colors duration-200 border-b">
      <Link
        to={url}
        onClick={handleOpenPaper}
        className={`item w-full ${props.isDiscussion ? 'discussion-class' : ''}`}
      >
        {props.isDiscussion && (
          <div className="disc_item_icon">
            <ArticleDiscussions className="discussion_icon" />
          </div>
        )}

        <div className="itemTitle font-bold flex mb-3 cursor-pointer">
          <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight flex">
            <RenderHTML htmlContent={paper.title} />
            {renderedLinksAvailable}
          </div>
        </div>
        {paper?.authors?.length && paper.authors.length > 0 ? (
          <div className="authorDate color-itemContent font-roboto text-ssm mb-3">
            <span className="text-yellow opacity-1">
              <RenderHTML htmlContent={authorsDisplay} />
            </span>
          </div>
        ) : (
          ''
        )}
        {(matches !== '' && matches) ||
        (AbstractFirstSentence && AbstractFirstSentence !== '') ? (
          <div className="itemContent color-itemContent text-itemContent mb-5">
            <RenderHTML
              htmlContent={
                matches !== '' && matches ? matches : AbstractFirstSentence
              }
            />
          </div>
        ) : (
          ''
        )}
        <hr />
        <div
          className={`flex justify-between mt-4 ${props.showThreeItems ? 'flex-col-reverse' : ''}`}
        >
          <div className="itemActions flex justify-between items-center text-black">
            {paper.publishedYear ? (
              <div className="itemActionDate">
                <div className="flex items-center">
                  <DatePost />
                  <span className="text-half ml-1">
                    {paperPublishedMonthYear(
                      paper.publishedYear,
                      paper.publishedMonth
                    )}
                  </span>
                </div>
              </div>
            ) : null}
            <div className="itemActionComments">
              <div className="flex items-center">
                <CommentPost className="flex-shrink-0" />
                <span className="text-half w-full ml-1">{commentCount}</span>
              </div>
            </div>
            <div className="itemActionShare">
              <div className="flex items-center">
                <ShareButton url={fullUrl} />
              </div>
            </div>
          </div>
          <div
            className={`authorLink font-roboto text-half ${props.showThreeItems ? 'mb-4' : ''}`}
          >
            {renderedTopicsKeywords}
            {numOfTopicsKeywords > 2 && (
              <span className="text-gray-400">
                + {thousandSeparator(numOfTopicsKeywords - 2)} {'more'}
              </span>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};
