import { ApiTopicResponse } from '../oipub-api';

export interface ApiCommunity {
  id: number;
  name: string;
  urlKey: string;
  state: CommunityState;
}

export interface ApiCommunitySummary {
  id: number;
  name: string;
  urlKey: string;
  state: CommunityState;
  posts: number;
  papers: number;
}

export interface ApiCommunityResponse {
  createdByUserId: string;
  id?: string;
  name: string;
  urlKey: string;
  description: string;
  type: number;
  topicTags: ApiTopicResponse[];
  state: CommunityState;
}

export enum CommunityState {
  Inactive = 0,
  Active = 1,
  Deleted = 2,
  ResyncNecessary = 3,
  ResyncInProgress = 4,
  ResyncError = 5
}

export interface UpdateCommunityParams {
  urlKey: string;
  name: string;
  description: string;
  topicTags: string[];
}
