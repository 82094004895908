export function paperPublishedMonthYear(
  year: number,
  month: number | null | undefined
): string {
  if (month === null || month === 0 || month === undefined) {
    return year.toString();
  }

  const monthNames = [
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  if (month >= 1 && month <= 12) {
    return `${monthNames[month]} ${year}`;
  }

  return year.toString();
}
export function paperPublishedDateNumbers(
  year: number | undefined,
  month: number | null | undefined = null,
  day: number | null | undefined = null
): string {
  // Handle undefined values appropriately by converting them to null
  const dateComponents: (number | null)[] = [
    year ?? null,
    month ?? null,
    day ?? null
  ];

  while (dateComponents[dateComponents.length - 1] === null) {
    dateComponents.pop();
  }

  const dateStrings = dateComponents.map((component) =>
    component !== null ? String(component).padStart(2, '0') : null
  );

  return dateStrings.join('/');
}
