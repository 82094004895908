import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiCommunityResponse } from '../types/community.type';
import { env } from '../../env';
import { sendProtectedRequest } from './sendProtectedRequest';
import { ICommunity } from '../../types/community.type';

interface GetCommunityArgs {
  urlKey: string;
}

export interface UpdateCommunityAdminArgs {
  urlKey: string;
  data: {
    name: string;
    description: string;
    topicTags: string[];
  };
}

export interface UpdateCommunityCreatorArgs {
  urlKey: string;
  data: {
    description: string;
    topicTags: string[];
  };
}

interface DeleteCommunityArgs {
  urlKey: string;
}

interface CreateCommunityArgs {
  name?: string;
  description?: string;
  topicTags?: string[];
}

export const getCommunity = createAsyncThunk(
  'get/getCommunity',
  async ({ urlKey }: GetCommunityArgs, { signal }) => {
    const response = await fetch(`${env.apiUrl}/api/v1/communities/${urlKey}`, {
      method: 'GET',
      signal
    });

    if (!response.ok) {
      throw new Error('community.get.errors.other');
    }

    const res = await response.json();

    return res as IApiCommunityResponse;
  }
);

export const updateCommunityByAdmin = createAsyncThunk(
  'put/updateCommunityByAdmin',
  async ({ urlKey, data }: UpdateCommunityAdminArgs, {}) => {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/communities/${urlKey}/by-super-user`,
      {
        method: 'PUT',
        body: data
      }
    );

    if (!response.ok) {
      throw new Error('Something went wrong with updating community');
    }

    const res = await response.json();

    return res;
  }
);

export const updateCommunityByCreator = createAsyncThunk(
  'put/updateCommunityByCreator',
  async ({ urlKey, data }: UpdateCommunityCreatorArgs, {}) => {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/communities/${urlKey}/by-creator`,
      {
        method: 'PUT',
        body: data
      }
    );

    if (!response.ok) {
      throw new Error('Something went wrong with updating community');
    }

    const res = await response.json();
    return res;
  }
);

export const deleteCommunity = createAsyncThunk(
  'delete/deleteCommunity',
  async ({ urlKey }: DeleteCommunityArgs, {}) => {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/communities/${urlKey}/hard`,
      {
        method: 'DELETE'
      }
    );

    if (!response.ok) {
      throw new Error('Failed to delete the community');
    }

    return { urlKey };
  }
);

export const createCommunity = createAsyncThunk<
  ICommunity,
  CreateCommunityArgs,
  { rejectValue: string }
>(
  'community/createCommunity',
  async ({ name, description, topicTags }, { rejectWithValue }) => {
    try {
      const body = {
        name,
        description,
        topicTags
      };

      const response = await sendProtectedRequest(
        `${env.apiUrl}/api/v1/communities`,
        {
          method: 'POST',
          body: body
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'Failed to create community.';
        throw new Error(errorMessage);
      }

      const data: IApiCommunityResponse = await response.json();

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to create community.');
    }
  }
);
