import { FC } from 'react';
import { IDiscussionsPage } from '../../types/discussions.type';
import { DiscussionPaperPreview } from '../../components/paper/discussionPaperPreview';
import { DiscussionUserPostPreview } from '../../components/userPost/discussionUserPostPreview';

interface Props {
  discussion: IDiscussionsPage;
  showThreeItems?: boolean;
}

const DiscussionPaperSummary: FC<Props> = ({
  discussion,
  showThreeItems
}: Props) => {
  if (discussion.paper) {
    return (
      <DiscussionPaperPreview
        key={`DiscussionPaperPreview-${discussion.paper.id}`}
        discussion={discussion}
        paper={discussion.paper}
        isDiscussion={true}
        showThreeItems={showThreeItems}
      />
    );
  } else if (discussion.userPost) {
    return (
      <DiscussionUserPostPreview
        key={`DiscussionUserPostPreview-${discussion.userPost.id}`}
        userPost={discussion.userPost}
        discussion={discussion}
        isDiscussion={true}
        showThreeItems={showThreeItems}
      />
    );
  }
  return null;
};

export default DiscussionPaperSummary;
