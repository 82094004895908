import { WheelEventHandler } from 'react';
import classNames from 'classnames';

export interface Props {
  className?: string;
  direction?: string;
  onScroll?: WheelEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
  key?: string;
  children: React.ReactNode;
}

export const Flex: React.FC<Props> = ({
  className,
  direction = 'flex-col',
  onScroll,
  style = {},
  children,
  key
}: Props) => {
  const flexClass = classNames('relative', 'flex', `${direction}`, className);
  return (
    <div onScroll={onScroll} className={flexClass} key={key} style={style}>
      {children}
    </div>
  );
};
