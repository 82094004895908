import { AppState } from '../store';

export const selectPaper = (state: AppState) => state.paper.paper;
export const selectPaperLoading = (state: AppState) => state.paper.loading;
export const selectPaperError = (state: AppState) => state.paper.error;

export const selectPaperReferences = (state: AppState) =>
  state.paper.references;
export const selectPaperCitations = (state: AppState) => state.paper.citations;
export const selectPaperReferencesCitationsLoading = (state: AppState) =>
  state.paper.citationLoading;
export const selectPaperReferencesCitationsErrors = (state: AppState) =>
  state.paper.citationError;
