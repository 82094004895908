import React from 'react';
import { Modal } from './modal';
import { Button } from '../form';

interface ConfirmationModalWrapperProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmationModalWrapper: React.FC<
  ConfirmationModalWrapperProps
> = ({ isOpen, title, message, onConfirm, onClose }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      modalName="confirmationModal"
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className="confirmation-modal main-page right-0"
    >
      <div>{message}</div>
      <div className="modal-footer">
        <div className="mt-6 flex justify-between items-center">
          <Button
            outline={true}
            onClick={onClose}
            className="py-2 tablet:px-12 px-6"
          >
            Cancel
          </Button>
          <Button
            className="py-2 tablet:px-12 px-6 ml-2"
            type="submit"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
