/* 
    Example: pageSequence({ start: 3,  stop: 8 }) -> [3, 4, 5, 6, 7, 8]
*/
const pageSequence = ({
  stop,
  start = 1
}: {
  stop: number;
  start?: number;
}) => {
  return Array.from({ length: stop + 1 - start }, (_, i) => i + start);
};

/* 
    mobilePages: number of pages around the current page (including the current page)
    Example: getMobilePages(6, 10, 5) -> ['1',  '...', '4', '5', '*6*', '7', '8', '...', '10']
        - 5, 6 and 7 are mobilePages 
        - *6* is the currentPage
        - numOfPagesOnOneSideOfCurrent:  2
*/
export const getMobilePages = (
  currentPage: number,
  lastPage: number,
  numOfMobilePages: number
) => {
  // Basic validation
  if (currentPage < 1 || lastPage < 1 || currentPage > lastPage) return [];
  // If the total number of pages is less than the desired window, show all pages
  if (lastPage <= numOfMobilePages) return pageSequence({ stop: lastPage });
  // Calculate half window to determine the range of pages around the current page
  const halfWindow = Math.floor(numOfMobilePages / 2);
  let startPage = Math.max(currentPage - halfWindow, 1);
  const endPage = Math.min(startPage + numOfMobilePages - 1, lastPage);
  // Adjust start page if the window goes beyond the last page
  if (endPage === lastPage) {
    startPage = Math.max(lastPage - numOfMobilePages + 1, 1);
  }
  return pageSequence({ start: startPage, stop: endPage });
};

export const getPages = (
  currentPage: number,
  lastPage: number,
  numOfMobilePages: number
) => {
  const mobilePages = getMobilePages(currentPage, lastPage, numOfMobilePages);
  if (mobilePages.length === 0) return [];
  const pages: (number | string)[] = [];
  // Always include the first page if it's not already part of the mobile pages
  if (mobilePages[0] !== 1) {
    pages.push(1);
    // Add ellipsis if there's a gap between the first page and the first mobile page
    if (mobilePages[0] > 2) pages.push('...');
  }
  // Add the main range of pages
  pages.push(...mobilePages);
  const lastMobilePage = mobilePages[mobilePages.length - 1];
  // Add ellipsis and last page if there's a gap between the last mobile page and the last page
  if (lastMobilePage < lastPage - 1) pages.push('...');
  if (lastMobilePage !== lastPage) pages.push(lastPage);
  return pages;
};

export const increaseCurrentPage = (currentPage: number, lastPage: number) => {
  const newCurrentPage = currentPage + 1;
  if (newCurrentPage > lastPage) return;
  return newCurrentPage;
};

export const decreaseCurrentPage = (currentPage: number) => {
  const newCurrentPage = currentPage - 1;
  if (newCurrentPage < 1) return;
  return newCurrentPage;
};
