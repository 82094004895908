export const TOPICS_COMMUNITIES_RADIO_STATES = {
  TOPICS: 'topics',
  COMMUNITIES: 'communities'
};

export const PAPERS_DISCUSSIONS_RADIO_STATES = {
  PAPERS: 'papers',
  DISCUSSIONS: 'discussions'
};

export const VIEW_STATES = {
  SIMPLE: 'simple',
  DETAILED: 'detailed'
};

export const ORDER_DIRECTION = {
  ASCEND: 'ascending',
  DESCEND: 'descending'
};

export const CITES_CITEDBY_RADIO_STATES = {
  CITES: 'cites',
  CITEDBY: 'citedBy'
};
