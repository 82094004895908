import { env } from './env';

const GA_TRACKING_ID = 'G-5W0FSXGVDW';

type GTagEvent = {
  action: string;
  category: string;
  label?: string;
  value?: number;
};

const debugView = env.env === 'production' ? {} : { debug_mode: true };

function page(path: string) {
  window.gtag('config', GA_TRACKING_ID, {
    ...debugView,
    page_path: path
  });
}

function event({ action, category, label, value }: GTagEvent) {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  });
}

function set(data: Record<string, string | undefined>) {
  window.gtag('set', data);
}

export const analytics = {
  page,
  setUserId(userId: string | undefined) {
    set({ userId: userId });
  },
  events: {
    search(label: string) {
      event({ action: 'search', category: 'engagement', label });
    },
    nextPage() {
      event({ action: 'next_search_page', category: 'engagement' });
    },
    openPaper(label: 'search' | 'feed' | 'topic') {
      event({ action: 'open_paper', category: 'engagement', label });
    },
    login() {
      event({ action: 'login', category: 'registration' });
    },
    register() {
      event({ action: 'sign_up', category: 'registration' });
    },
    loginCta(label: 'join_discussion') {
      event({ action: 'login_cta', category: 'CTA', label });
    },
    expandAbstract() {
      event({ action: 'expand_abstract', category: 'engagement' });
    },
    addComment(label: 'paper' | 'topic' | 'post') {
      event({ action: 'comment', category: 'engagement', label });
    },
    clickPaperLink() {
      event({ action: 'paper_link', category: 'outbound_link' });
    },
    openReferral() {
      event({ action: 'open_referral', category: 'registration' });
    },
    sendReferral() {
      event({ action: 'send_referral', category: 'registration' });
    }
  }
};
