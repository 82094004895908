import { MouseEventHandler } from 'react';
import classNames from 'classnames';

export interface Props {
  className?: string;
  width?: string;
  onClick?: MouseEventHandler<HTMLImageElement>;
  height?: string;
  src?: string;
  title?: string;
  alt?: string;
  style?: React.CSSProperties;
}

export const Image: React.FC<Props> = (props: Props) => {
  const { className, width, onClick, height, src, title, alt, style } = props;
  const imgClass = classNames('object-cover', className);
  return (
    <img
      width={width}
      height={height}
      src={src}
      title={title}
      alt={alt}
      className={imgClass}
      onClick={onClick}
      style={style}
    />
  );
};
