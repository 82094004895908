import classNames from 'classnames';

interface Props {
  className?: string;
  color?: string;
}

export const ViewThreeInLine: React.FC<Props> = (props: Props) => {
  const { className, color = '#3D3D3D' } = props;
  return (
    <svg
      className={classNames('block w-full h-full', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM8 20H4V16H8V20ZM4 14H8V10H4V14ZM14 14H10V10H14V14ZM16 4V8H20V4H16ZM14 8H10V4H14V8ZM16 14H20V10H16V14ZM20 20H16V16H20V20Z"
        fill={color}
      />
    </svg>
  );
};
