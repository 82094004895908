import React, { useState } from 'react';

import { formatDate } from '../../utils/userPostHelper';
import { Link } from 'react-router-dom';
import { thousandSeparator } from '../../utils/numbers-formatter';
import pluralize from 'pluralize';
import {
  selectUserPost,
  selectUserPostGetLoading
} from '../../state/selectors/userPostSelector';
import { useAppSelector } from '../../state';
import { Loading } from '../../components/core';
import { createLink } from '../../utils/helperFunctions';

interface DetailProps {
  heading: string;
  className?: string;
  children: React.ReactNode;
}

const Detail: React.FC<DetailProps> = ({ heading, className, children }) => {
  return (
    <div className="tablet:w-full flex-shrink mb-6">
      <div
        className={`mb-0 text-xs font-bold tracking-wide uppercase ${className ?? ''}`}
      >
        {heading}
      </div>
      <h3 className="text-sm leading-snug text-gray-900">{children}</h3>
    </div>
  );
};

export const UserPostMetaData: React.FC = () => {
  const postData = useAppSelector(selectUserPost);
  const loading = useAppSelector(selectUserPostGetLoading);
  const showCount = 2;
  const renderedCommunities: JSX.Element[] = [];
  const renderedTopicsKeywords: JSX.Element[] = [];
  const [showCommunities, setShowCommunities] = useState(false);
  const [showTopicsKeywords, setShowTopicsKeywords] = useState(false);

  if (loading) return <Loading />;

  if (postData?.tags) {
    for (const tag of postData.tags.slice(0, showCount)) {
      if (renderedTopicsKeywords.length < showCount) {
        if (tag && tag.urlKey && tag.id) {
          renderedTopicsKeywords.push(
            <Link
              to={createLink(`/topic/${tag.urlKey}/discussions`)}
              className="btnSmall text-small mr-1 text-primary-500 hover:text-primary-400 
              active:text-primary-200 focus:outline-none focus:shadow-outline px-2 
              leading-none py-2 mb-2 font-medium transition-colors duration-200 bg-white 
              border border-current rounded text-center"
              key={tag.id}
            >
              {tag.name}
            </Link>
          );
        }
      }
    }
  }

  if (postData?.communities) {
    for (const tag of postData.communities.slice(0, showCount)) {
      if (renderedCommunities.length < showCount) {
        if (tag && tag.urlKey && tag.id) {
          renderedCommunities.push(
            <Link
              to={createLink(`/community/${tag.urlKey}`)}
              className="btnSmall text-small mr-1 text-primary-500 hover:text-primary-400 
              active:text-primary-200 focus:outline-none focus:shadow-outline px-2 
              leading-none py-2 mb-2 font-medium transition-colors duration-200 
              bg-white border border-current rounded text-center"
              key={tag.id}
            >
              {tag.name}
            </Link>
          );
        }
      }
    }
  }

  return (
    <aside className="tablet:mr-0 block">
      {postData?.createdByUser?.displayName && (
        <Detail heading="Post Creator" className="">
          {postData?.createdByUser?.displayName}
        </Detail>
      )}
      {postData?.createdUtc && (
        <Detail heading="Posted" className="">
          {formatDate(postData?.createdUtc)}
        </Detail>
      )}
      {postData?.tags && postData.tags.length !== 0 && (
        <Detail heading="Topics" className="mb-2">
          <div className=" flex flex-col max-w-2xl color-itemContent font-roboto text-ssm">
            <div className=" flex flex-wrap">{renderedTopicsKeywords}</div>
            {showTopicsKeywords && (
              <div className="flex flex-wrap max-w-2xl ">
                {postData.tags &&
                  postData.tags
                    .slice(showCount, postData.tags.length)
                    .map((tag) => {
                      if (tag && tag.urlKey && tag.id) {
                        return (
                          <Link
                            to={createLink(`/topic/${tag.urlKey}/discussions`)}
                            className="btnSmall text-small mr-1 text-primary-500 
                          hover:text-primary-400 active:text-primary-200 focus:outline-none 
                          focus:shadow-outline px-2 leading-none py-2 mb-2 font-medium 
                          transition-colors duration-200 bg-white border border-current rounded text-center"
                            key={tag.id}
                          >
                            {tag.name}
                          </Link>
                        );
                      }
                      return null;
                    })}
              </div>
            )}
            {postData.tags &&
              postData.tags.length > 2 &&
              !showTopicsKeywords && (
                <div className=" text-small text-gray-600 mb-1">
                  <div className="">
                    and {thousandSeparator(postData.tags.length - showCount)}{' '}
                    other {pluralize('topic', postData.tags.length - 2)}
                  </div>
                </div>
              )}
          </div>
          {postData.tags && postData.tags.length > 2 && (
            <div
              className="hover:text-gray-700 flex-shrink-0 w-full 
              text-xs text-gray-600 transition-colors duration-200 cursor-pointer mt-1"
              onClick={() => setShowTopicsKeywords(!showTopicsKeywords)}
            >
              <button
                className="btnSmall text-white bg-gradient-to-r text-small 
              from-primaryLight to-primaryDefault px-4 py-2  gap-1 rounded opacity-100 
              hover:opacity-50 font-medium focus:outline-none w-40 text-center uppercase"
              >
                {showTopicsKeywords ? 'Hide all topics' : 'Show all topics'}
              </button>
            </div>
          )}
        </Detail>
      )}

      {postData?.communities && postData.communities.length !== 0 && (
        <Detail heading="Communities" className="mb-2">
          <div className=" flex flex-col max-w-2xl color-itemContent font-roboto text-ssm">
            <div className=" flex flex-wrap">{renderedCommunities}</div>
            {showCommunities && (
              <div className="flex flex-wrap max-w-2xl ">
                {postData.communities &&
                  postData.communities
                    .slice(showCount, postData.communities.length)
                    .map((a) => (
                      <Link
                        to={createLink(`/community/${a.urlKey}/discussions`)}
                        className="btnSmall text-small mr-1 text-primary-500 
                    hover:text-primary-400 active:text-primary-200 focus:outline-none 
                    focus:shadow-outline px-2 leading-none py-2 mb-2 font-medium 
                    transition-colors duration-200 bg-white border border-current rounded text-center"
                        key={a.id}
                      >
                        {a.name}
                      </Link>
                    ))}
              </div>
            )}
            {postData.communities &&
              postData.communities.length > 2 &&
              !showCommunities && (
                <div className=" text-small text-gray-600 mb-1">
                  <div className="">
                    and{' '}
                    {thousandSeparator(postData.communities.length - showCount)}{' '}
                    other{' '}
                    {pluralize('community', postData.communities.length - 2)}
                  </div>
                </div>
              )}
          </div>
          {postData.communities && postData.communities.length > 2 && (
            <div
              className="hover:text-gray-700 flex-shrink-0 w-full 
              text-xs text-gray-600 transition-colors duration-200 cursor-pointer"
              onClick={() => setShowCommunities(!showCommunities)}
            >
              <button
                className="btnSmall text-white bg-gradient-to-r 
              text-small from-primaryLight to-primaryDefault px-4 py-2  
              gap-1 rounded opacity-100 hover:opacity-50 font-medium focus:outline-none w-40 text-center uppercase"
              >
                {showCommunities
                  ? 'Hide all communities'
                  : 'Show all communities'}{' '}
              </button>
            </div>
          )}
        </Detail>
      )}
    </aside>
  );
};
