import { useRef } from 'react';
import { Tag } from '../../types/topic.type';
import { RenderHTML } from '../paper/render-html';
import { Loading } from '../core';

interface Props {
  elements: Tag[];
  hidden: boolean;
  selectElement: (elem: string) => void;
  onLoadMore?: () => void;
  hasMore?: boolean;
  errorFetch?: string | null;
}
export const InputDropDownList = (props: Props) => {
  const { elements, hidden, selectElement, onLoadMore, hasMore, errorFetch } =
    props;
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const dropdown = dropdownRef.current;
    if (dropdown) {
      const { scrollTop, scrollHeight, clientHeight } = dropdown;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        onLoadMore && onLoadMore();
      }
    }
  };

  if (errorFetch) {
    return (
      <div
        ref={dropdownRef}
        className="bg-primaryFaded text-black pl-2 justify-start text-xs listTopicsCommunities"
      >
        <div className="py-2">{errorFetch}</div>
      </div>
    );
  }

  return (
    <div
      ref={dropdownRef}
      className={
        'bg-primaryFaded text-black pl-2 justify-start text-xs listTopicsCommunities'
      }
      hidden={hidden}
      onScroll={handleScroll}
    >
      {!hidden &&
        elements &&
        elements.map((element) => {
          return (
            <div key={element.urlKey}>
              <button
                type="button"
                key={element.urlKey}
                onClick={() => selectElement(element.urlKey)}
                className="hover:bg-primary-300 w-full text-left cursor-pointer"
              >
                <RenderHTML htmlContent={element.name} />
              </button>
            </div>
          );
        })}
      {!hidden && onLoadMore && elements.length > 0 && hasMore && (
        <div className="text-center pb-2">
          <Loading />
        </div>
      )}
    </div>
  );
};
