import { InviteForm } from './invite-form';

export function InvitePage() {
  return (
    <main className="w-full max-w-md mx-auto mt-10">
      <h1 className="mb-4 text-xl text-primary-500 font-bold">
        Invite a Friend to OiPub
      </h1>
      <InviteForm />
    </main>
  );
}
