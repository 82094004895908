import { useCallback } from 'react';
import { useAppDispatch } from '../../../state';
import {
  updateCommunityByAdmin,
  updateCommunityByCreator,
  getCommunity
} from '../../../state/actions/communityActions';

interface IUseUpdateCommunityParams {
  urlKey: string;
  communityName?: string;
  description: string;
  selectedTopicTags: string[];
  isUserAdmin: boolean;
  isUserCreator: boolean;
}

export function useUpdateCommunity(
  onSuccess?: (newUrlKey?: string) => void,
  onError?: (error: any) => void
) {
  const dispatch = useAppDispatch();

  return useCallback(
    ({
      urlKey,
      communityName,
      description,
      selectedTopicTags,
      isUserAdmin,
      isUserCreator
    }: IUseUpdateCommunityParams) => {
      if (isUserAdmin) {
        const adminData = {
          urlKey,
          data: {
            name: communityName ?? '',
            description,
            topicTags: selectedTopicTags
          }
        };

        dispatch(updateCommunityByAdmin(adminData))
          .unwrap()
          .then((response) => {
            const updatedUrlKey = response.urlKey;
            if (updatedUrlKey && updatedUrlKey !== urlKey) {
              onSuccess?.(updatedUrlKey);
            } else {
              dispatch(getCommunity({ urlKey }));
              onSuccess?.();
            }
          })
          .catch(onError);
      } else if (isUserCreator) {
        const creatorData = {
          urlKey,
          data: {
            description,
            topicTags: selectedTopicTags
          }
        };

        dispatch(updateCommunityByCreator(creatorData))
          .then(() => {
            dispatch(getCommunity({ urlKey }));
          })
          .then(() => {
            onSuccess?.(urlKey);
          })
          .catch(onError);
      }
    },
    [dispatch, onSuccess, onError]
  );
}
