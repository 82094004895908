import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { texts } from '../../../../text';

export const AuthButtons: React.FC = () => {
  const navigate = useNavigate();

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [navigate]
  );

  return (
    <>
      <button
        className="btnSmall text-white bg-gradient-to-r from-primaryLight to-primaryDefault px-4 py-2  
        gap-1 rounded opacity-100
        hover:opacity-50 font-medium focus:outline-none mr-2 w-full text-center mt-1"
        onClick={() => handleOpenModalHash('signup')}
      >
        {texts.signup}
      </button>
      <button
        onClick={() => handleOpenModalHash('login')}
        className="btnSmall text-primary-500 hover:text-primary-400 active:text-primary-200 
        focus:outline-none focus:shadow-outline px-4 py-2 text-sm font-medium transition-colors duration-200 
        bg-white border border-current rounded w-full text-center mt-1"
      >
        {texts.login}
      </button>
    </>
  );
};
