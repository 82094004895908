import { FC } from 'react';
import { PapersDiscussionsTabsCommunity } from '../../components/papersDiscussionRadio';

export const CommunityPage: FC = () => {
  return (
    <div className="w-full h-full">
      <div className="paperBorder"></div>
      <PapersDiscussionsTabsCommunity />
    </div>
  );
};
