import { analytics } from '../../analytics';
import { useAppDispatch, useAppSelector } from '..';
import {
  forgotPassword,
  loginUser,
  logoutUser,
  registerUser,
  resetPassword
} from '../actions/authActions';
import { IResetPasswordRequest } from '../../types/auth.type';
import { AuthServiceInstance } from '../../api/services/auth';
import { useEffect } from 'react';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const isAuthenticated = Boolean(user);

  useEffect(() => {
    const storedUser = AuthServiceInstance.getUser();
    if (!storedUser) {
      dispatch(logoutUser());
    }
  }, [dispatch]);

  // Login function
  const login = async (credentials: { email: string; password: string }) => {
    try {
      await dispatch(loginUser(credentials)).unwrap();
      analytics.events.login();
    } catch (error) {
      throw error;
    }
  };

  // Logout function
  const logout = () => {
    dispatch(logoutUser());
  };

  // Forgot password function
  const forgotPasswordHandler = async (data: {
    email: string;
    host: string;
  }) => {
    try {
      await dispatch(forgotPassword(data)).unwrap();
    } catch (error) {
      throw error;
    }
  };

  // Register function
  const register = async (registrationData: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  }) => {
    try {
      await dispatch(registerUser(registrationData)).unwrap();
      await dispatch(
        loginUser({
          email: registrationData.email,
          password: registrationData.password
        })
      ).unwrap();

      analytics.events.register();
    } catch (error) {
      throw error;
    }
  };

  // Reset password function
  const resetPasswordHandler = async (data: IResetPasswordRequest) => {
    try {
      await dispatch(resetPassword(data)).unwrap();
    } catch (error) {
      throw error;
    }
  };

  return {
    user,
    isAuthenticated,
    login,
    logout,
    forgotPassword: forgotPasswordHandler,
    register,
    resetPassword: resetPasswordHandler
  };
};
