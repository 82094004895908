import classNames from 'classnames';

interface Props {
  className?: string;
}

export const DeletePostIcon: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('block', className)}
    >
      <path
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
        fill="#EE9844"
      />
      <path
        d="M16.6815 16.6815C16.2569 17.1062 15.571 17.1062 15.1464 16.6815L12 13.5351L8.85355 16.6815C8.42895 17.1062 7.74304 17.1062 7.31844 16.6815C7.11457 16.4781 7 16.202 7 15.914C7 15.626 7.11457 15.3498 7.31844 15.1464L10.4649 12L7.31844 8.85357C7.11457 8.65016 7 8.374 7 8.08601C7 7.79802 7.11457 7.52186 7.31844 7.31845C7.74304 6.89385 8.42895 6.89385 8.85355 7.31845L12 10.4649L15.1464 7.31845C15.571 6.89385 16.2569 6.89385 16.6815 7.31845C17.1062 7.74306 17.1062 8.42896 16.6815 8.85357L13.5351 12L16.6815 15.1464C17.0953 15.5602 17.0953 16.2569 16.6815 16.6815Z"
        fill="white"
      />
    </svg>
  );
};
