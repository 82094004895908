import classNames from 'classnames';
import { MouseEventHandler, WheelEventHandler } from 'react';
import { twRounded } from './rounded';
import { textColors } from './textColors';

export interface Props {
  type?: 'submit' | 'reset' | 'button' | undefined;
  className?: string;
  direction?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onScroll?: WheelEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  size?: string;
  disabled?: boolean;
  textColor?: string;
  rounded?: string;
  children: React.ReactNode;
}

export const Button: React.FC<Props> = (props: Props) => {
  const {
    type = 'button',
    className = '',
    onClick = () => {},
    children,
    size = 'md',
    disabled = false,
    textColor = 'white',
    rounded = '2xl'
  } = props;

  const styleSizes = {
    md: ['py-4', 'px-5'],
    imd: ['py-2', 'px-5'],
    sm: ['py-1', 'px-5'],
    xsm: ['py-1', 'px-3'],
    xs: ['py-1', 'px-2'],
    capital: ['py-2', 'px-1'],
    base: ['py-3'] //These buttons have fix 45% width. No px needed.
  };

  const btnClass = classNames(
    'inline-block',
    'flex-shrink-0',
    'text-center',
    'align-middle',
    'select-none',
    `${twRounded[rounded as keyof typeof twRounded]}`,
    `${textColors[textColor as keyof typeof textColors]}`,
    { 'bg-grey': disabled },
    ...styleSizes[size as keyof typeof styleSizes],
    className
  );
  return (
    <button type={type} className={btnClass} onClick={onClick}>
      {children}
    </button>
  );
};
