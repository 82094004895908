import classNames from 'classnames';

interface Props {
  className?: string;
}

export const Pencil: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block w-full h-full', className)}
      viewBox="0 0 16 17"
      fill="currentColor"
    >
      <path d="M8.95805 5.91751L11.0825 8.04245L6.12445 13H4V10.8751L8.95805 5.917V5.91751ZM9.6662 5.20936L10.7284 4.14663C10.8223 4.05274 10.9497 4 11.0825 4C11.2153 4 11.3427 4.05274 11.4366 4.14663L12.8534 5.56343C12.9473 5.65735 13 5.78471 13 5.91751C13 6.0503 12.9473 6.17766 12.8534 6.27158L11.7906 7.3338L9.6662 5.20936Z" />
    </svg>
  );
};
