import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommunitySummaryPaged } from '../../types/communities.type';
import {
  getCommunitiesData,
  getLandingCommunitiesData,
  searchCommunities
} from '../actions/communitiesActions';
import { ICommonHubPaged } from '../../types/hubs.type';

interface CommunitiesState {
  communities: ICommunitySummaryPaged | null;
  loading: boolean;
  error: string | null;
  landingCommunities: ICommunitySummaryPaged | null;
  landingLoading: boolean;
  landingError: string | null;
  searchResults: ICommonHubPaged | null;
  searchLoading: boolean;
  searchError: string | null;
  requestAbortedForNew: boolean;
  creationDefaultCommunities: ICommunitySummaryPaged | null;
  creationDefaultLoading: boolean;
  creationDefaultError: string | null;
  creationSearchCommunities: ICommunitySummaryPaged | null;
  creationSearchLoading: boolean;
  creationSearchError: string | null;
}

const initialState: CommunitiesState = {
  communities: null,
  loading: false,
  error: null,
  landingCommunities: null,
  landingLoading: false,
  landingError: null,
  searchResults: null,
  searchLoading: false,
  searchError: null,
  requestAbortedForNew: false,
  creationDefaultCommunities: null,
  creationDefaultLoading: false,
  creationDefaultError: null,
  creationSearchCommunities: null,
  creationSearchLoading: false,
  creationSearchError: null
};

const communitiesSlice = createSlice({
  name: 'communities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommunitiesData.pending, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationDefaultCommunities = null;
          state.creationDefaultLoading = true;
          state.creationDefaultError = null;
        } else {
          state.loading = true;
          state.error = null;
        }
      })
      .addCase(getCommunitiesData.fulfilled, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationDefaultCommunities = action.payload;
          state.creationDefaultLoading = false;
        } else {
          state.communities = action.payload;
          state.loading = false;
        }
      })
      .addCase(getCommunitiesData.rejected, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationDefaultError =
            action.error.message || 'Failed to fetch communities';
          state.creationDefaultLoading = false;
        } else {
          state.error = action.error.message || 'Failed to fetch communities';
          state.loading = false;
        }
      })
      .addCase(searchCommunities.pending, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationSearchCommunities = null;
          state.creationSearchLoading = true;
          // state.creationSearchError = null;
        } else {
          state.searchLoading = true;
          // state.searchError = null;
        }
      })
      .addCase(searchCommunities.fulfilled, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationSearchCommunities = action.payload;
          state.creationSearchLoading = false;
          state.creationSearchError = null;
        } else {
          state.searchResults = action.payload;
          state.searchLoading = false;
          state.searchError = null;
        }
      })
      .addCase(searchCommunities.rejected, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationSearchError =
            action.payload || 'Failed to search communities';
          state.creationSearchLoading = false;
        } else {
          state.searchError = action.payload || 'Failed to search communities';
          state.searchLoading = false;
        }
      })

      .addCase(getLandingCommunitiesData.pending, (state) => {
        state.landingLoading = true;
        state.landingError = null;
      })
      .addCase(
        getLandingCommunitiesData.fulfilled,
        (state, action: PayloadAction<ICommunitySummaryPaged>) => {
          state.landingCommunities = action.payload;
          state.landingLoading = false;
        }
      )
      .addCase(getLandingCommunitiesData.rejected, (state, action) => {
        state.landingError =
          action.error.message || 'Failed to fetch communities';
        state.landingLoading = false;
      });
  }
});

export const communitiesReducer = communitiesSlice.reducer;
