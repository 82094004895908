import {
  getCommunitiesData,
  searchCommunities
} from '../../../../state/actions/communitiesActions';
import {
  selectCommunitiesCreation,
  selectCommunitiesCreationLoading,
  selectCommunitiesCreationSearchLoading,
  selectCommunitiesCreationSearchResults
} from '../../../../state/selectors/communitiesSelector';
import { useTopicsCommunitiesFormData } from './useTopicsCommunitiesFormData';

export const useCommunitiesFormData = () => {
  return useTopicsCommunitiesFormData({
    selectInitialData: selectCommunitiesCreation,
    selectSearchResults: selectCommunitiesCreationSearchResults,
    selectLoading: selectCommunitiesCreationLoading,
    selectSearchLoading: selectCommunitiesCreationSearchLoading,
    fetchDataAction: getCommunitiesData,
    searchDataAction: searchCommunities,
    target: 'creation',
    entityType: 'community'
  });
};
