import React from 'react';
import { Textarea } from '../../atoms/textarea';
import { Validation } from './validation';

interface TextareaLetterCountProps {
  value: string;
  setValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
  maxLength: number;
  placeholder: string;
  disabled?: boolean;
  error?: string | false;
  touched?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  className?: string;
}

export const TextareaLetterCount: React.FC<TextareaLetterCountProps> = ({
  value = '',
  setValue,
  name,
  maxLength,
  placeholder,
  disabled = false,
  error,
  touched,
  onBlur,
  className = ''
}) => {
  const limitReached = value.length >= maxLength;

  return (
    <div className="relative w-full">
      <Textarea
        size="xs"
        placeholder={placeholder}
        className={`h-20 border px-3 py-2 outline-none w-full flex ${
          disabled ? 'opacity-50 cursor-not-allowed' : ''
        } ${touched && error ? 'border-error-500' : ''} ${touched && !error ? 'border-green-500' : ''} ${
          !touched ? 'border-gray-400' : ''
        } ${className}`}
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        name={name}
        maxLength={maxLength}
        disabled={disabled}
      />
      <div
        className={`absolute right-0 text-ssm ${
          limitReached ? 'text-error-600' : 'text-gray-500'
        }`}
      >
        {`${value.length}/${maxLength}`}
      </div>
      {touched && error && <Validation message={error} />}
    </div>
  );
};

export default TextareaLetterCount;
