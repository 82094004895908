import { AppState } from '../store';

export const selectCommunityState = (state: AppState) => state.community;

export const selectCommunityData = (state: AppState) =>
  selectCommunityState(state).data;

export const selectCommunityDataState = (state: AppState) =>
  selectCommunityState(state).data?.state;

export const selectCommunityLoading = (state: AppState) =>
  selectCommunityState(state).loading;

export const selectCommunityGetLoading = (state: AppState) =>
  selectCommunityState(state).loading.get;

export const selectCommunityUpdateLoading = (state: AppState) =>
  selectCommunityState(state).loading.update;

export const selectCommunityDeleteLoading = (state: AppState) =>
  selectCommunityState(state).loading.delete;

export const selectCommunityErrors = (state: AppState) =>
  selectCommunityState(state).errors;

export const selectCommunityGetError = (state: AppState) =>
  selectCommunityState(state).errors.get;

export const selectCommunityUpdateError = (state: AppState) =>
  selectCommunityState(state).errors.update;

export const selectCommunityDeleteError = (state: AppState) =>
  selectCommunityState(state).errors.delete;
