import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getTopic } from '../../../state/actions/topicActions';
import {
  selectTopic,
  selectTopicError,
  selectTopicLoading
} from '../../../state/selectors/topicSelector';
import { clearTopic } from '../../../state/slices/topicReducer';

interface UseGetTopicsData {
  urlKey: string;
}

export const useGetTopicData = ({ urlKey }: UseGetTopicsData) => {
  const dispatch = useAppDispatch();
  const topic = useAppSelector(selectTopic);
  const loading = useAppSelector(selectTopicLoading);
  const error = useAppSelector(selectTopicError);

  useEffect(() => {
    dispatch(clearTopic());
    const fetchTopic = async () => {
      await dispatch(getTopic({ urlKey }));
    };

    fetchTopic();
  }, [dispatch, urlKey]);

  return { topic, loading, error };
};
