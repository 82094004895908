import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectPapers,
  selectPapersLoading,
  selectPapersError
} from '../../../state/selectors/papersSelector';
import { getPapersByCommunityUrlKey } from '../../../state/actions/papersActions';

interface UseGetPapersByCommunityUrlKeyData {
  page: number;
  pageSize: number;
  urlKey: string;
  sortMethod?: string;
}

export const useGetPapersByCommunityUrlKeyData = ({
  page,
  pageSize,
  urlKey,
  sortMethod
}: UseGetPapersByCommunityUrlKeyData) => {
  const dispatch = useAppDispatch();
  const papers = useAppSelector(selectPapers);
  const loading = useAppSelector(selectPapersLoading);
  const error = useAppSelector(selectPapersError);

  useEffect(() => {
    const fetchPapers = async () => {
      await dispatch(
        getPapersByCommunityUrlKey({ page, pageSize, urlKey, sortMethod })
      );
    };

    fetchPapers();
  }, [dispatch, page, pageSize, urlKey, sortMethod]);

  return { papers, loading, error };
};
// TODO find a way to add the comments count to each paper without many requests
