import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createCommunity,
  deleteCommunity,
  getCommunity,
  updateCommunityByAdmin,
  updateCommunityByCreator
} from '../actions';
import { IApiCommunityResponse } from '../types/community.type';
import { ICommunity } from '../../types/community.type';

export interface CommunityErrors {
  get: string | null;
  update: string | null;
  delete: string | null;
  create: string | null;
}

export interface CommunityLoading {
  get: boolean;
  update: boolean;
  delete: boolean;
  create: boolean;
}

export interface CommunityState {
  data: ICommunity | null;
  loading: CommunityLoading;
  errors: CommunityErrors;
}

export const initialState: CommunityState = {
  data: null,
  loading: {
    get: false,
    update: false,
    delete: false,
    create: false
  },
  errors: {
    get: null,
    update: null,
    delete: null,
    create: null
  }
};

const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    // GET
    builder.addCase(getCommunity.pending, (state) => {
      state.loading.get = true;
      state.errors.get = null;
    });
    builder.addCase(
      getCommunity.fulfilled,
      (state, { payload }: PayloadAction<IApiCommunityResponse>) => {
        state.data = payload;
        state.loading.get = false;
      }
    );
    builder.addCase(getCommunity.rejected, (state, { error }) => {
      state.errors.get = error?.message || null;
      state.loading.get = false;
    });
    // UPDATE By admin
    builder.addCase(updateCommunityByAdmin.pending, (state) => {
      state.loading.update = true;
      state.errors.update = null;
    });
    builder.addCase(
      updateCommunityByAdmin.fulfilled,
      (state, { payload }: PayloadAction<IApiCommunityResponse>) => {
        state.data = payload;
        state.loading.update = false;
      }
    );
    builder.addCase(updateCommunityByAdmin.rejected, (state, { error }) => {
      state.errors.update = error?.message || null;
      state.loading.update = false;
    });
    // UPDATE By creator
    builder.addCase(updateCommunityByCreator.pending, (state) => {
      state.loading.update = true;
      state.errors.update = null;
    });
    builder.addCase(
      updateCommunityByCreator.fulfilled,
      (state, { payload }: PayloadAction<IApiCommunityResponse>) => {
        state.data = payload;
        state.loading.update = false;
      }
    );
    builder.addCase(updateCommunityByCreator.rejected, (state, { error }) => {
      state.errors.update = error?.message || null;
      state.loading.update = false;
    });
    // Delete By Admin
    builder
      .addCase(deleteCommunity.pending, (state) => {
        state.loading.delete = true;
        state.errors.delete = null;
      })
      .addCase(deleteCommunity.fulfilled, (state, action) => {
        if (state.data?.urlKey === action.payload.urlKey) {
          state.data = null;
        }
        state.loading.delete = false;
      })
      .addCase(deleteCommunity.rejected, (state, { error }) => {
        state.errors.delete = error?.message || null;
        state.loading.delete = false;
      });

    // CREATE Community
    builder.addCase(createCommunity.pending, (state) => {
      state.loading.create = true;
      state.errors.create = null;
    });
    builder.addCase(
      createCommunity.fulfilled,
      (state, { payload }: PayloadAction<ICommunity>) => {
        state.loading.create = false;
        state.data = payload;
      }
    );
    builder.addCase(createCommunity.rejected, (state, {}) => {
      state.loading.create = false;
      state.errors.create = 'Failed to create community.';
    });
  }
});

export const { setData } = communitySlice.actions;
export default communitySlice.reducer;
