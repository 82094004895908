import { FocusEventHandler, MouseEventHandler } from 'react';
import classNames from 'classnames';

export interface Props {
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  outline?: boolean;
  type?: 'button' | 'submit' | 'reset';
  circle?: boolean;
  restyled?: boolean;
  children: React.ReactNode;
}

export const Button: React.FC<Props> = ({
  className,
  disabled = false,
  onClick,
  onBlur,
  outline = false,
  type = 'button',
  children,
  circle,
  restyled
}: Props) => {
  return (
    <button
      className={classNames(
        {
          ' px-4 py-1 border border-current': !restyled
        },
        'transition-colors duration-200 font-medium text-sm  focus:outline-none focus:shadow-outline whitespace-no-wrap',
        circle ? 'rounded-full' : 'rounded',
        {
          'text-primary-500 hover:text-primary-400 active:text-primary-200':
            !disabled
        },
        { 'text-gray-500 pointer-events-none': disabled },
        { 'bg-white': outline },
        { 'bg-current': !outline },
        className
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
      onBlur={onBlur}
    >
      <div
        className={classNames('flex items-center justify-center', {
          'text-white': !outline
        })}
      >
        {children}
      </div>
    </button>
  );
};
