import classNames from 'classnames';
import { textColors } from './textColors';
import { textSizes } from './textSizes';

export interface Props {
  className?: string;
  muted?: boolean;
  color?: string;
  size?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Paragraph: React.FC<Props> = ({
  children,
  className,
  muted,
  color = 'white',
  size = 'base',
  style = {}
}: Props) => {
  const paragraphClass = classNames(
    `${textColors[color as keyof typeof textColors]}`,
    { 'text-muted': muted },
    `${textSizes[size as keyof typeof textSizes]}`,
    'leading-tight',
    className
  );

  return (
    <p className={paragraphClass} style={style}>
      {children}
    </p>
  );
};
