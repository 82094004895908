import { ISelectedEntity } from '../../types/topic.type';
import {
  CLOSE_MODAL,
  ModalActionTypes,
  OPEN_MODAL,
  TOGGLE_ADMIN_SIDEBAR
} from '../actions/modalsActions';

interface IndividualModalState {
  isOpen: boolean;
  url?: string | null;
  selectedCommunityOrTopic?: ISelectedEntity | null;
}

interface ModalState {
  [key: string]: IndividualModalState;
}

const initialState: ModalState = {
  signup: { isOpen: false, url: null },
  login: { isOpen: false, url: null },
  resetPassword: { isOpen: false },
  createPost: { isOpen: false, url: null, selectedCommunityOrTopic: null },
  updatePost: { isOpen: false, url: null },
  createCommunity: { isOpen: false, url: null },
  updateCommunity: { isOpen: false, url: null },
  share: { isOpen: false, url: null },
  adminSidebar: { isOpen: false }
};
const modalReducer = (state = initialState, action: ModalActionTypes) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        [action.payload.modalName]: {
          isOpen: true,
          url: action.payload.url,
          selectedCommunityOrTopic: action.payload.selectedCommunityOrTopic
        }
      };
    case CLOSE_MODAL:
      return {
        ...state,
        [action.payload]: {
          isOpen: false,
          url: null,
          selectedCommunityOrTopic: null
        }
      };
    case TOGGLE_ADMIN_SIDEBAR:
      return {
        ...state,
        adminSidebar: { isOpen: !state.adminSidebar.isOpen }
      };
    default:
      return state;
  }
};

export default modalReducer;
