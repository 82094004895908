import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon
} from 'react-share';

interface ShareButtonsProps {
  shareUrl: string;
}

export const ShareIcons: React.FC<ShareButtonsProps> = ({
  shareUrl
}: ShareButtonsProps) => {
  return (
    <div className="share-popup mt-6 flex flex-wrap justify-center">
      <FacebookShareButton url={shareUrl} className="mr-1 ml-1 mb-2">
        <FacebookIcon size={24} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} className="mr-1 ml-1 mb-2">
        <TwitterIcon size={24} round />
      </TwitterShareButton>
      <WhatsappShareButton url={shareUrl} className="mr-1 ml-1 mb-2">
        <WhatsappIcon size={24} round />
      </WhatsappShareButton>
      <TelegramShareButton url={shareUrl} className="mr-1 ml-1 mb-2">
        <TelegramIcon size={24} round />
      </TelegramShareButton>
      <EmailShareButton
        url={shareUrl}
        subject="Check out this link"
        className="mr-1 ml-1 mb-2"
      >
        <EmailIcon size={24} round />
      </EmailShareButton>
    </div>
  );
};
