import React, { useMemo } from 'react';
import { closeModal } from '../../state/actions/modalsActions';
import { useAppDispatch, useAppSelector } from '../../state';

interface ModalProps {
  modalName?: string;
  isOpen?: boolean;
  onClose?: (e?: any) => void;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  closeOnOutsideClick?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  modalName,
  isOpen: controlledIsOpen,
  onClose: controlledOnClose,
  closeOnOutsideClick = false,
  title,
  children,
  className
}) => {
  const reduxIsOpen = useAppSelector((state) =>
    modalName ? state.modal[modalName]?.isOpen || false : false
  );

  const dispatch = useAppDispatch();

  const isOpen =
    controlledIsOpen !== undefined ? controlledIsOpen : reduxIsOpen;
  const onClose = useMemo(() => {
    return (
      controlledOnClose ||
      (() => {
        if (modalName) {
          dispatch(closeModal(modalName));
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledOnClose, modalName, dispatch]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={`modal-overlay ${className} h-full`}
      onClick={closeOnOutsideClick ? onClose : undefined}
    >
      <div className="p-1 absolute">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <div
          className="modal-content"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="modal-header">
            <h2 className="font-bold">{title}</h2>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};
