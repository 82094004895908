import { Tooltip } from '../../components/core/tooltip';
import { RenderHTML } from '../../components/paper/render-html';
import { LinkGo } from '../../svgs/LinkGo';

export interface Props {
  title: string;
  doiLink?: string;
  id: string | null | undefined;
}

export const Furniturev2: React.FC<Props> = (props: Props) => {
  const { title, doiLink } = props;

  const handleGoToLink = () => {
    if (doiLink) {
      window.open(doiLink, '_blank');
    }
  };

  return (
    <div className="mb-3">
      <div className="items-baseline">
        <div className="flex mb-3 justify-between items-center">
          <h1 className=" text-xl font-bold leading-snug">
            <RenderHTML htmlContent={title} />
          </h1>
          <Tooltip
            className="paperTitleTooltip px-2"
            text={`Go to the source at ${doiLink}`}
          >
            <button onClick={handleGoToLink}>
              <LinkGo />
            </button>
          </Tooltip>
        </div>

        <div className="text-primary-500 text-sm flex">#Pa[{props.id}]</div>
      </div>
    </div>
  );
};
