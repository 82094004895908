import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getPapersByTopicUrlKey,
  getPapersByCommunityUrlKey,
  searchPapers
} from '../actions/papersActions';
import { IPaperPaged } from '../../types/papers.type';

interface PapersState {
  papers: IPaperPaged;
  searchPapers: IPaperPaged;
  loading: boolean;
  error: string | null;
  searchLoading: boolean;
  searchError: string | null;
  requestAbortedForNew: boolean;
}

const initialState: PapersState = {
  papers: {
    count: 0,
    pageSize: 0,
    numPages: 0,
    pageNum: 0,
    page: []
  },
  searchPapers: {
    count: 0,
    pageSize: 0,
    numPages: 0,
    pageNum: 0,
    page: []
  },
  loading: false,
  error: null,
  searchLoading: false,
  searchError: null,

  requestAbortedForNew: false
};

const papersSlice = createSlice({
  name: 'papers',
  initialState,
  reducers: {
    resetPapersCount(state) {
      state.papers.count = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPapersByTopicUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPapersByTopicUrlKey.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.papers = action.payload;
          state.loading = false;
        }
      )
      .addCase(getPapersByTopicUrlKey.rejected, (state, action) => {
        state.error =
          action.error.message || 'Failed to fetch papers by topic URL key.';
        state.loading = false;
      })
      .addCase(getPapersByCommunityUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPapersByCommunityUrlKey.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.papers = action.payload;
          state.loading = false;
        }
      )
      .addCase(getPapersByCommunityUrlKey.rejected, (state, action) => {
        state.error =
          action.error.message ||
          'Failed to fetch papers by community URL key.';
        state.loading = false;
      });
    builder
      .addCase(searchPapers.pending, (state) => {
        state.searchLoading = true;
        state.searchError = null;
      })
      .addCase(
        searchPapers.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.searchPapers = action.payload;
          state.searchLoading = false;
        }
      )
      .addCase(searchPapers.rejected, (state, action) => {
        state.searchLoading = false;
        if (action.error.name !== 'AbortError' && !state.requestAbortedForNew) {
          state.searchError =
            (action.payload as string) || 'Failed to fetch topics/communities';
        }
      });
  }
});

export const { resetPapersCount } = papersSlice.actions;
export const papersReducer = papersSlice.reducer;
