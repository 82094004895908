import { useMemo } from 'react';
import { IPaperPreview } from '../../../types/paper.type';
import { createLink } from '../../../utils/helperFunctions';

interface UsePaperDataProps {
  paper: IPaperPreview;
  handleTopicTagClick: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    urlKey: string
  ) => void;
}

export const usePaperDiscussionPreviewData = ({
  paper,
  handleTopicTagClick
}: UsePaperDataProps) => {
  const renderedTopicsKeywords = useMemo(() => {
    const keywords: JSX.Element[] = [];
    const showTopicsKeywordsCount = 2;
    let i = 0;

    if (paper.topics) {
      for (const tag of paper.topics.slice(0, showTopicsKeywordsCount)) {
        if (keywords.length < showTopicsKeywordsCount) {
          if (tag && tag.urlKey && tag.id) {
            keywords.push(
              <span
                role="button"
                onClick={(e) => handleTopicTagClick(e, tag.urlKey!)}
                className="text-ssm mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline leading-none mr-1 mb-2 transition-colors duration-200 text-center"
                key={tag.id}
                style={{ cursor: 'pointer' }}
              >
                {tag.name}
                {i < paper.topics.length - 1 ? ', ' : ' '}
              </span>
            );
            i++;
          }
        }
      }
    }

    return keywords;
  }, [paper.topics, handleTopicTagClick]);

  const fullUrl = useMemo(() => {
    let url = createLink('/paper');
    if (paper?.id) {
      url += `/${paper.id}`;
    }
    const currentUrl = window.location.origin;
    return currentUrl + url;
  }, [paper?.id]);

  const numOfTopicsKeywords = paper.topics ? paper.topics.length : 0;

  return {
    renderedTopicsKeywords,
    fullUrl,
    numOfTopicsKeywords
  };
};
