import classNames from 'classnames';

export interface Props {
  className?: string;
}

export const Logo: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block w-32', className)}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 109.5 25"
    >
      <path
        className="cls-2"
        d="M21.1,12.7c0,2.4-.4,4.5-1.3,6.2-.9,1.7-2.1,3.1-3.6,4-1.5.9-3.3,1.4-5.2,1.4s-3.7-.5-5.2-1.4c-1.5-.9-2.7-2.3-3.6-4-.9-1.7-1.3-3.8-1.3-6.2s.4-4.5,1.3-6.2c.9-1.7,2.1-3.1,3.6-4,1.5-.9,3.3-1.4,5.2-1.4s3.7.5,5.2,1.4c1.5.9,2.7,2.3,3.6,4,.9,1.7,1.3,3.8,1.3,6.2ZM17.7,12.7c0-1.8-.3-3.4-.9-4.6-.6-1.3-1.4-2.2-2.4-2.9-1-.7-2.2-1-3.5-1s-2.5.3-3.5,1c-1,.6-1.8,1.6-2.4,2.9-.6,1.3-.9,2.8-.9,4.6s.3,3.4.9,4.6c.6,1.3,1.4,2.2,2.4,2.9,1,.6,2.2,1,3.5,1s2.4-.3,3.5-1c1-.7,1.8-1.6,2.4-2.9.6-1.3.9-2.8.9-4.6ZM25,24V7.1h3.3v16.9h-3.3ZM26.7,4.5c-.6,0-1.1-.2-1.5-.6-.4-.4-.6-.9-.6-1.4s.2-1,.6-1.4c.4-.4.9-.6,1.5-.6s1.1.2,1.5.6c.4.4.6.8.6,1.4s-.2,1-.6,1.4c-.4.4-.9.6-1.5.6ZM33,24V1.5h8c1.8,0,3.2.3,4.4,1,1.2.6,2,1.5,2.6,2.6.6,1.1.9,2.3.9,3.7s-.3,2.6-.9,3.7c-.6,1.1-1.4,2-2.6,2.6-1.2.6-2.6,1-4.3,1h-5.5v-2.9h5.2c1.1,0,2-.2,2.7-.6.7-.4,1.2-.9,1.5-1.6.3-.7.5-1.4.5-2.3s-.2-1.6-.5-2.3c-.3-.7-.8-1.2-1.5-1.6-.7-.4-1.6-.6-2.7-.6h-4.3v19.6h-3.4ZM63.3,17V7.1h3.3v16.9h-3.2v-2.9h-.2c-.4.9-1,1.7-1.9,2.3-.9.6-1.9.9-3.2.9s-2-.2-2.9-.7c-.8-.5-1.5-1.2-2-2.1-.5-.9-.7-2.1-.7-3.5V7.1h3.3v10.4c0,1.2.3,2.1,1,2.8.6.7,1.5,1,2.5,1s1.2-.2,1.8-.5c.6-.3,1.1-.8,1.5-1.4.4-.6.6-1.4.6-2.4ZM71.3,24V1.5h3.3v8.4h.2c.2-.4.5-.8.8-1.2.4-.5.9-.9,1.5-1.2.6-.4,1.5-.5,2.5-.5s2.6.3,3.7,1c1.1.7,1.9,1.7,2.5,3,.6,1.3.9,2.9.9,4.7s-.3,3.4-.9,4.7c-.6,1.3-1.4,2.3-2.5,3-1.1.7-2.3,1-3.6,1s-1.9-.2-2.5-.5c-.6-.3-1.2-.7-1.5-1.2-.4-.5-.6-.9-.8-1.2h-.3v2.6h-3.2ZM74.5,15.5c0,1.2.2,2.2.5,3.1.3.9.8,1.6,1.5,2.1.7.5,1.5.7,2.4.7s1.8-.3,2.5-.8c.7-.5,1.2-1.2,1.5-2.1.3-.9.5-1.9.5-3s-.2-2.1-.5-3c-.3-.9-.8-1.6-1.5-2.1-.7-.5-1.5-.8-2.5-.8s-1.8.2-2.4.7c-.7.5-1.1,1.2-1.5,2.1-.3.9-.5,1.9-.5,3.1Z"
      />
      <g>
        <path
          className="cls-1"
          d="M90.4,4.5c0,.7-.6,1.1-1.6,1.1h-2.1V1.4h2c1,0,1.5.4,1.5,1.1s-.2.7-.5.9c.5.1.8.5.8,1ZM87.5,2v1.2h1.1c.5,0,.8-.2.8-.6s-.3-.6-.8-.6h-1.1ZM89.7,4.4c0-.4-.3-.6-.9-.6h-1.3v1.2h1.3c.6,0,.9-.2.9-.6Z"
        />
        <path
          className="cls-1"
          d="M94.7,4.9v.7h-3.1V1.4h3.1v.7h-2.3v1.1h2v.6h-2v1.2h2.4Z"
        />
        <path
          className="cls-1"
          d="M96.7,2.1h-1.4v-.7h3.6v.7h-1.4v3.5h-.8v-3.5Z"
        />
        <path
          className="cls-1"
          d="M102.3,4.6h-2.1l-.4,1h-.8l1.9-4.2h.8l1.9,4.2h-.8l-.4-1ZM102.1,4l-.8-1.8-.8,1.8h1.6Z"
        />
      </g>
    </svg>
  );
};
