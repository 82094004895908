export interface TwSizes {
  xs: string;
  sm: string;
  base: string;
  lg: string;
  xl: string;
}

export const textSizes: TwSizes = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl'
};
