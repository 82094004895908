import { TopBar } from '../../components/core/topBars';
import { ErrorPageImage } from '../../svgs/ErrorPageImage';

interface Props {}

export const NotFound: React.FC<Props> = () => {
  return (
    <TopBar>
      <main className="page-content max-w-2/3">
        <div className="w-full h-full">
          <div className="paperBorder"></div>
        </div>
        <div className="flex flex-col justify-center items-center mt-16 px-4 text-center">
          <div className="font-light errorTitle">404</div>
          <div className="font-black text-yellow errorText">Page not found</div>
          <div className="font-medium errorExplain mb-6">
            We’re working on it!
          </div>
          <ErrorPageImage className="errorImage" />
        </div>
      </main>
    </TopBar>
  );
};
