import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import classNames from 'classnames';
import { textSizes } from './textSizes';
import { twRounded } from './rounded';

interface Props {
  id?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  name?: string;
  label?: string;
  value?: string;
  defaultChecked?: boolean;
  type: string | undefined;
  rounded?: string;
  placeholder?: string | undefined;
  maxLength?: number | undefined;
  min?: string | undefined;
  max?: string | undefined;
  size?: string;
  defaultValue?: string | undefined;
  required?: boolean;
  disabled?: boolean;
}

// eslint-disable-next-line react/display-name
export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      onBlur,
      onChange,
      onFocus,
      type = 'text',
      rounded,
      placeholder,
      maxLength,
      min,
      max,
      className = '',
      value,
      size = 'base',
      defaultValue,
      required,
      disabled = false
    },
    ref
  ) => {
    const inputClass = classNames(
      'tw-px-4',
      'tw-py-4',
      `${twRounded[rounded as keyof typeof twRounded]}`,
      `${textSizes[size as keyof typeof textSizes]}`,
      'focus:tw-outline-none',
      className,
      { 'opacity-50 cursor-not-allowed': disabled }
    );

    return (
      <input
        ref={ref}
        name={name}
        type={type}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        min={min}
        max={max}
        className={inputClass}
        value={value}
        defaultValue={defaultValue}
        required={required}
        disabled={disabled}
      />
    );
  }
);
