import { useEffect } from 'react';
import {
  deleteComment,
  editCommentUserPost,
  getComments,
  postCommentUserPost
} from '../../../state/actions/commentsActions';
import { useAppDispatch, useAppSelector } from '../../../state';
import { clearComments } from '../../../state/slices/commentsReducer';
import { selectUserPost } from '../../../state/selectors/userPostSelector';

export function useCommentsUserPost() {
  const dispatch = useAppDispatch();
  const postData = useAppSelector(selectUserPost);
  const commentsSortMethod = useAppSelector(
    (state) => state.sort.commentSortMethod
  );

  const discussionId = postData?.discussionId;

  useEffect(() => {
    if (postData && discussionId) {
      dispatch(
        getComments({
          discussionId: discussionId,
          order: commentsSortMethod
        })
      );
    }
    // Clear comments when the component unmounts or the discussionId changes
    return () => {
      dispatch(clearComments());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, postData?.discussionId, commentsSortMethod]);

  const handleReply = async (text: string, parentId: number | null) => {
    if (postData && discussionId) {
      await dispatch(
        postCommentUserPost({
          userPostId: Number(postData.id),
          body: text,
          parentCommentId: parentId
        })
      );

      // Reload comments after posting a reply
      dispatch(
        getComments({
          discussionId: discussionId,
          order: commentsSortMethod
        })
      );
    }
  };

  const handleEdit = async (
    postId: number,
    commentId: number,
    body: string
  ) => {
    if (postData && discussionId) {
      await dispatch(editCommentUserPost({ postId, commentId, body }));

      dispatch(
        getComments({
          discussionId: discussionId,
          order: commentsSortMethod
        })
      );
    }
  };

  const handleDelete = async (commentId: number) => {
    if (postData && discussionId) {
      await dispatch(deleteComment({ commentId }));
      dispatch(
        getComments({
          discussionId: discussionId,
          order: commentsSortMethod
        })
      );
    }
  };

  return { handleReply, handleEdit, handleDelete };
}
