import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectDiscussions,
  selectDiscussionsLoading,
  selectDiscussionsError
} from '../../../state/selectors/discussionsSelector';
import { getDiscussionsByCommunityUrlKey } from '../../../state/actions/discussionsActions';

interface UseGetDiscussionsByCommunityUrlKeyData {
  page: number;
  pageSize: number;
  urlKey: string;
  sortMethod?: string;
}

export const useGetDiscussionsByCommunityUrlKeyData = ({
  page,
  pageSize,
  urlKey,
  sortMethod
}: UseGetDiscussionsByCommunityUrlKeyData) => {
  const dispatch = useAppDispatch();
  const discussions = useAppSelector(selectDiscussions);
  const loading = useAppSelector(selectDiscussionsLoading);
  const error = useAppSelector(selectDiscussionsError);

  useEffect(() => {
    const fetchDiscussions = async () => {
      await dispatch(
        getDiscussionsByCommunityUrlKey({ page, pageSize, urlKey, sortMethod })
      );
    };

    fetchDiscussions();
  }, [dispatch, page, pageSize, urlKey, sortMethod]);

  return { discussions, loading, error };
};
// TODO find a way to add the comments count to each discussions without many requests
