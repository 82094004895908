import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ShareButton } from '../../components/share';
import { PapersCount } from '../../svgs/PapersCount';
import { DiscussionIcon } from '../../svgs/DiscussionIcon';
import { ICommunitySummary } from '../../types/community.type';
import { createLink } from '../../utils/helperFunctions';
import { Tooltip } from '../../components/core/tooltip';
interface Props {
  community: ICommunitySummary;
}

export const CommunitySummaryV2: FC<Props> = ({ community }) => {
  const communityLink = useMemo(() => {
    return createLink(`/community/${community.urlKey}/papers`);
  }, [community]);

  const shareUrl = useMemo(() => {
    const currentUrl = window.location.protocol + '//' + window.location.host;
    return currentUrl + communityLink;
  }, [communityLink]);

  const formatNumber = (number: number) =>
    new Intl.NumberFormat().format(number);

  return (
    <Link to={communityLink} className="item cursor-pointer w-full">
      <div className="itemTitle font-bold flex mb-3 cursor-pointer">
        <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight">
          {community.name}
        </div>
      </div>
      <div className="itemContent color-itemContent text-itemContent mb-5">
        {community.description}
      </div>
      <hr />
      <div className="itemActions flex justify-between items-center text-black mt-4">
        <div className="itemActionPaperCount">
          <div className="flex items-center">
            <div className="flex items-center">
              <PapersCount className="flex-shrink-0" />
              <span className="text-half ml-1">
                {formatNumber(community.numPapers || 0)}
              </span>
            </div>
          </div>
        </div>
        <div className="itemActionComments">
          <div className="flex items-center">
            <Tooltip
              className="tooltipDisc"
              text={'The total number of active discussions.'}
            >
              <DiscussionIcon className="flex-shrink-0" />
              <span className="text-half w-full ml-1">
                {formatNumber(community.numDiscussions || 0)}
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="itemActionShare">
          <div className="flex items-center">
            <ShareButton url={shareUrl} />
          </div>
        </div>
      </div>
    </Link>
  );
};
