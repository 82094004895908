import { Previewv2 } from '../../components/paper';
import { ApiPaper } from '../../api/oipub-api';

export interface Props {
  papers: ApiPaper[];
}

export const FeedResults: React.FC<Props> = ({ papers }: Props) => {
  const items: JSX.Element[] = [];

  for (const paper of papers) {
    let key: string | undefined = undefined;
    let inner: JSX.Element | null = null;

    if (!!paper) {
      key = `paper-${paper.id}`;
      inner = <Previewv2 paper={paper} trackingLabel="feed" />;
    }

    if (inner != null) {
      items.push(
        <div key={key} className="list-group mb-1">
          {inner}
        </div>
      );
    }
  }

  return (
    <>
      {items.length > 0 ? (
        <div className="mt-3">{items}</div>
      ) : (
        <p className="empty">
          No recent activity. Find a paper and get the discussion going!
        </p>
      )}
    </>
  );
};
