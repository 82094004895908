import { createAsyncThunk } from '@reduxjs/toolkit';
import { env } from '../../env';
import { ITopicResponse } from '../../types/topic.type';

interface GetTopicsArgs {
  urlKey: string;
}

export const getTopic = createAsyncThunk(
  'get/getTopic',
  async ({ urlKey }: GetTopicsArgs, { rejectWithValue }) => {
    try {
      const response = await fetch(`${env.apiUrl}/api/v1/topics/${urlKey}`, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch topic');
      }
      const res = await response.json();
      return res as ITopicResponse;
    } catch (error) {
      return rejectWithValue('Failed to fetch topic');
    }
  }
);
