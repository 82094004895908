import classNames from 'classnames';

interface Props {
  className?: string;
}

export const ArrowLeft: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block ', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M3.75 7.5L10 1.25L10.875 2.125L5.5 7.5L10.875 12.875L10 13.75L3.75 7.5Z"
        fill="#1A202C"
      />
    </svg>
  );
};
