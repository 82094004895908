import { useSearchParams } from 'react-router-dom';

export const useIsSearchParamsEmpty = () => {
  const [searchParams] = useSearchParams();

  return (
    !searchParams.get('title') &&
    !searchParams.get('publishedDateFrom') &&
    !searchParams.get('publishedDateTo') &&
    !searchParams.get('author') &&
    !searchParams.get('journal')
  );
};
