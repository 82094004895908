import classNames from 'classnames';

interface Props {
  className?: string;
}

export const Search: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block w-full h-full', className)}
      viewBox="0 0 16 17"
      fill="currentColor"
    >
      <path d="M12.0207 11.578L14.876 14.4326L13.9327 15.376L11.078 12.5206C10.0158 13.3721 8.69466 13.8352 7.33333 13.8333C4.02133 13.8333 1.33333 11.1453 1.33333 7.83331C1.33333 4.52131 4.02133 1.83331 7.33333 1.83331C10.6453 1.83331 13.3333 4.52131 13.3333 7.83331C13.3353 9.19465 12.8721 10.5158 12.0207 11.578ZM10.6833 11.0833C11.5294 10.2132 12.0019 9.04693 12 7.83331C12 5.25465 9.91133 3.16665 7.33333 3.16665C4.75466 3.16665 2.66666 5.25465 2.66666 7.83331C2.66666 10.4113 4.75466 12.5 7.33333 12.5C8.54695 12.5019 9.71325 12.0294 10.5833 11.1833L10.6833 11.0833Z" />
    </svg>
  );
};
