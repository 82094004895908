import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import {
  selectDeletedUserPosts,
  selectDeletedUserPostsLoading
} from '../../state/selectors/userPostsSelector';
import { Loading } from '../core';
import {
  setUserPostsSortMethod,
  useAppDispatch,
  useAppSelector
} from '../../state';
import { UserPostSummary } from './userPostSummary';
import { SearchPagination } from '../../routes/search/search-pagination';
import { ViewModeToggle } from '../viewModeToggle';
import {
  USERPOSTS_SORT_METHOD_DISPLAY_NAMES,
  UserPostsSortMethod
} from '../../utils/sortingConst';
import { SortingButtonsContainer } from '../sortingButtons';

interface DeletedUserPostsListProps {
  currentPage: number;
  updatePage: (newPage: number) => void;
}

export const DeletedUserPostsList: React.FC<DeletedUserPostsListProps> = ({
  currentPage,
  updatePage
}) => {
  const dispatch = useAppDispatch();
  const loadingDeleted = useAppSelector(selectDeletedUserPostsLoading);
  const viewMode = useAppSelector((state) => state.viewmode.mode);
  const isTripleView = viewMode === 'triple';
  const deletedPosts = useAppSelector(selectDeletedUserPosts);

  const deletedUserPostsSortMethod = useAppSelector(
    (state) => state.sort.userPostsSortMethod
  );

  const handleSortingUserPostsChange = (method: UserPostsSortMethod) => {
    dispatch(setUserPostsSortMethod(method));
  };

  return (
    <div className="w-full h-full">
      <SortingButtonsContainer
        sortMethods={USERPOSTS_SORT_METHOD_DISPLAY_NAMES}
        currentSortMethod={deletedUserPostsSortMethod}
        onSortMethodChange={handleSortingUserPostsChange}
      />
      <ViewModeToggle />
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4 relative">
        {loadingDeleted ? (
          <Loading />
        ) : deletedPosts.page && deletedPosts.page.length > 0 ? (
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              350: 1,
              600: isTripleView ? 2 : 1,
              900: isTripleView ? 3 : 1
            }}
            className="w-full h-full max-w-3xl"
          >
            <Masonry gutter="14px">
              {deletedPosts.page.map((userPost) => (
                <UserPostSummary
                  key={`deletedUserPost-${userPost.id}`}
                  userPost={userPost}
                  showThreeItems={isTripleView}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        ) : (
          <div className="w-full h-full max-w-3xl">
            <p>No deleted posts found.</p>
          </div>
        )}
        {!loadingDeleted &&
        deletedPosts.numPages &&
        deletedPosts.numPages > 0 ? (
          <SearchPagination
            currentPage={currentPage}
            lastPage={deletedPosts.numPages}
            numOfMobilPages={5}
            onPageChange={updatePage}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
