import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { analytics } from '../../analytics';
import { ArrowLeft, ArrowRight } from '../../svgs';
import {
  decreaseCurrentPage,
  getPages,
  increaseCurrentPage
} from './search-utils';

export interface Props {
  currentPage: number;
  lastPage: number;
  onPageChange?: (_page: number) => void;
  numOfMobilPages?: number;
}

export const SearchPagination: React.FC<Props> = ({
  currentPage: initialCurrentPage,
  lastPage,
  onPageChange = () => {},
  numOfMobilPages = 5
}) => {
  const [pages, setPages] = useState<(string | number)[]>(
    getPages(initialCurrentPage, lastPage, numOfMobilPages)
  );
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);

  useEffect(() => {
    setCurrentPage(initialCurrentPage);
    setPages(getPages(initialCurrentPage, lastPage, numOfMobilPages));
  }, [initialCurrentPage, lastPage, numOfMobilPages]);

  const update = (page: number, lastPage: number, numOfMobilPages: number) => {
    setCurrentPage(page);
    setPages(getPages(page, lastPage, numOfMobilPages));
    onPageChange(page);
    analytics.events.nextPage();
  };

  const handleLeftClick = () => {
    const newCurrentPage = decreaseCurrentPage(currentPage);
    if (newCurrentPage) update(newCurrentPage, lastPage, numOfMobilPages);
  };

  const handleRightClick = () => {
    const newCurrentPage = increaseCurrentPage(currentPage, lastPage);
    if (newCurrentPage) update(newCurrentPage, lastPage, numOfMobilPages);
  };

  const handlePageClick = (page: number) =>
    update(page, lastPage, numOfMobilPages);

  if (pages.length < 2) return <></>;

  return (
    <>
      <div className="flex items-center justify-center mb-6 text-itemContent">
        <button onClick={handleLeftClick} className="h-8 focus:outline-none">
          <ArrowLeft
            className={classNames({ 'opacity-50': currentPage === 1 })}
          />
        </button>

        <div>
          {pages.map((page, index) => {
            let onClick = () => {};
            if (typeof page === 'number') {
              onClick = () => handlePageClick(page);
            }
            return (
              <button
                key={`page-${index}`}
                className={classNames('focus:outline-none p-1 px-3 ', {
                  'text-primaryDefault': page === currentPage
                })}
                onClick={onClick}
                // disabled={}
              >
                {page}
              </button>
            );
          })}
        </div>

        <button onClick={handleRightClick} className="h-8 focus:outline-none">
          <ArrowRight
            className={classNames({ 'opacity-50': currentPage === lastPage })}
          />
        </button>
      </div>
    </>
  );
};
