import classNames from 'classnames';

export interface Props {
  className?: string;
  htmlFor?: string;
  children: React.ReactNode;
}

export const Label: React.FC<Props> = (props: Props) => {
  const { className, htmlFor, children } = props;
  return (
    <label
      className={classNames('font-bold text-sm mb-1 block', className)}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};
