import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Image, Paragraph } from '../../atoms';
import { HelpMenuLink } from '../../svgs/HelpMenuLink';
import { AboutMenuLink } from '../../svgs/AboutMenuLink';
import { ArrowRight } from '../../svgs/ArrowRight';
import { TermsMenuLink } from '../../svgs/TermsMenuLink';
import { createLink } from '../../utils/helperFunctions';

export interface Props {
  className?: string;
}

export const Footer: FC<Props> = ({ className }) => {
  function renderLinks() {
    return (
      <>
        <Link
          className="hover:text-primary-600 py-4 px-4 transition-colors duration-200 flex items-center justify-between roboto "
          to={createLink('/about')}
        >
          <div className="flex items-center">
            <AboutMenuLink className="opacity-50 mr-6" />
            <span className="font-medium font-roboto">About</span>
          </div>
          <ArrowRight className="opacity-50" />
        </Link>
        <Link
          className="hover:text-primary-600 py-4 px-4 transition-colors duration-200 flex items-center justify-between roboto top-bar-border-top mt-1"
          to={createLink('/contact')}
        >
          <div className="flex items-center">
            <HelpMenuLink className="opacity-50 mr-6" />
            <span className="font-medium font-roboto">Contact & FAQ</span>
          </div>
          <ArrowRight className="opacity-50" />
        </Link>
        <Link
          className="hover:text-primary-600 py-4 px-4 transition-colors duration-200 flex items-center justify-between roboto top-bar-border-top mt-1"
          to={createLink('/terms-and-conditions')}
        >
          <div className="flex items-center">
            <TermsMenuLink className=" mr-6" />
            <span className="font-medium font-roboto">Terms & Conditions</span>
          </div>
          <ArrowRight className="opacity-50" />
        </Link>
      </>
    );
  }

  return (
    <footer
      className={classNames(
        'flex items-baseline flex-wrap tablet:pt-5 pt-5 text-sm',
        className
      )}
    >
      <Flex className="max-w-full justify-items-center">
        <div className="flex flex-col">
          {/* <span className="mr-6 text-gray-600">&copy; OiPub 2017 - {new Date().getFullYear()}</span> */}
          {renderLinks()}
        </div>
        <div className="justify-items-center flex flex-row top-bar-border-top pt-4 pb-4 px-4">
          <Image
            title={'Europ Flag'}
            alt={'Europ Flag'}
            src={'/oipub-app-images/EULogo.png'}
            className={'w-20 pr-4'}
          />
          <Paragraph className={'text-footer mb-0'}>
            This work was supported by the EOSC Future project under the
            European Union Horizon Programme call INFRAEOSC-03-2020 - Project ID
            101017536
          </Paragraph>
        </div>
      </Flex>
    </footer>
  );
};
