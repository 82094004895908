import { analytics } from '../../analytics';
import { ILoginResponse, ILoginUser } from '../../types/auth.type';

class AuthService {
  private authKey = 'auth';

  /**
   * @returns The access token or null if not set.
   */
  getAccessToken(): string | null {
    return localStorage.getItem(this.authKey);
  }

  /**
   * @param token - The access token to store.
   */
  setAccessToken(token: string): void {
    localStorage.setItem(this.authKey, token);
  }

  /**
   * Removes the access token from localStorage.
   */
  removeAccessToken(): void {
    localStorage.removeItem(this.authKey);
  }

  /**
   * @returns The expiry time or null if not set.
   */
  getExpiry(): string | null {
    return localStorage.getItem('expiry');
  }

  /**
   * @param expiry - The expiry time to store.
   */
  setExpiry(expiry: string): void {
    if (expiry) {
      localStorage.setItem('expiry', expiry);
    }
  }

  /**
   * Sets authentication data without refresh tokens.
   *
   * @param authData - The authentication response from the API.
   */
  setAuth(authData: ILoginResponse): void {
    // Store access token
    if (authData.jwt) {
      this.setAccessToken(authData.jwt);
    }

    // Store user and expiry in localStorage
    localStorage.setItem('user', JSON.stringify(authData.user));
    if (authData.expiry) {
      this.setExpiry(authData.expiry);
    }

    // Set user ID for analytics if valid
    if (
      authData.user &&
      authData.expiry &&
      new Date(authData.expiry) > new Date()
    ) {
      const id = authData.user.id ? String(authData.user.id) : undefined;
      analytics.setUserId(id);
    }
  }

  /**
   * @returns The authenticated user or null.
   */
  getUser(): ILoginUser | null {
    const userJson = localStorage.getItem('user');
    if (!userJson) return null;
    try {
      return JSON.parse(userJson) as ILoginUser;
    } catch (e) {
      return null;
    }
  }

  /**
   * Clears authentication data from localStorage and analytics.
   */
  clearAuth(): void {
    this.removeAccessToken();
    localStorage.removeItem('user');
    localStorage.removeItem('expiry');
    analytics.setUserId(undefined);
  }
}

export const AuthServiceInstance = new AuthService();
