import {
  getTopicsData,
  searchTopics
} from '../../../../state/actions/topicsActions';
import {
  selectTopicsCreation,
  selectTopicsCreationSearchResults,
  selectTopicsLoading,
  selectTopicsSearchLoading
} from '../../../../state/selectors/topicsSelector';
import { useTopicsCommunitiesFormData } from './useTopicsCommunitiesFormData';

export const useTopicsFormData = () => {
  return useTopicsCommunitiesFormData({
    selectInitialData: selectTopicsCreation,
    selectSearchResults: selectTopicsCreationSearchResults,
    selectLoading: selectTopicsLoading,
    selectSearchLoading: selectTopicsSearchLoading,
    fetchDataAction: getTopicsData,
    searchDataAction: searchTopics,
    target: 'creation',
    entityType: 'topic'
  });
};
