import classNames from 'classnames';

export interface Props {
  className?: string;
  autoComplete?: 'on' | 'off';
  disabled?: boolean;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: 'text' | 'email' | 'password';
  value?: string;
  name?: string;
  style?: React.CSSProperties;
  required?: boolean;
}

export const Input: React.FC<Props> = ({
  className,
  autoComplete,
  disabled = false,
  id,
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
  placeholder,
  type = 'text',
  value,
  name,
  style,
  required
}: Props) => {
  return (
    <input
      className={classNames(
        'pl-3 w-full h-12 rounded border border-gray-500 px-3 placeholder-primaryDark duration-200 focus:placeholder-gray-300 transition-colors outline-none focus:border-gray-700',
        className
      )}
      autoComplete={autoComplete}
      disabled={disabled}
      id={id}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      type={type}
      value={value}
      name={name}
      style={style}
      {...(required ? { required: true } : {})}
    />
  );
};
