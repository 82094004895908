/* eslint-disable max-len */
import { Provider } from 'react-redux';
import { AppRoutes } from './routes';
import { store } from './state';

function App() {
  return (
    <>
      <Provider store={store}>
        <div className="flex flex-col w-full min-h-screen mx-auto text-gray-900">
          <AppRoutes />
        </div>
      </Provider>
    </>
  );
}

export default App;
