import { useEffect, useState } from 'react';
import qs from 'qs';
import { analytics } from '../../analytics';
import { Loading } from '../../components/core';
import { useAppDispatch } from '../../state';
import { ReferralApi } from '../../api';
import { Link, useLocation } from 'react-router-dom';
import { ApiReferral } from '../../api/oipub-api';

export function ReferralPageContainer() {
  const dispatch = useAppDispatch();
  const [referral, setReferral] = useState<ApiReferral | null>(null);
  const location = useLocation();

  const queryString = qs.parse(location.search.substring(1));
  const referralCode = (queryString['code'] || null) as string | null;

  useEffect(() => {
    (async function () {
      if (!!referralCode) {
        dispatch({ type: 'LOGIN_SET_REFERRAL_CODE', code: referralCode });
        localStorage.setItem('referral_code', referralCode);
        analytics.events.openReferral();
        const apiReferral = await ReferralApi.get(referralCode);
        setReferral(apiReferral);
      }
    })();
  }, [referralCode, dispatch]);

  return referral === null ? <Loading /> : <ReferralPage referral={referral} />;
}

interface Props {
  referral: ApiReferral;
}
export function ReferralPage(props: Props) {
  const { referral } = props;
  return (
    <main className="page m-16 text-center">
      <h1 className="text-2xl">
        {referral.referrer?.displayName} recommends you give OiPub a try!
      </h1>
      <p>
        To complete the referral,{' '}
        <Link
          to="/register"
          className="hover:text-primary-500 text-gray-700 underline transition-colors duration-200"
        >
          register a new account
        </Link>
        .
      </p>
      <p>
        You can also{' '}
        <Link
          to="/"
          className="hover:text-primary-500 text-gray-700 underline transition-colors duration-200"
        >
          search for a paper
        </Link>{' '}
        and register later.
      </p>
    </main>
  );
}
