export interface Props {
  message?: string;
  children?: React.ReactNode;
}

export const Validation: React.FC<Props> = ({ message, children }: Props) => {
  return (
    <div className="flex items-center font-medium tracking-wide text-error-500 text-xs mt-1">
      {message}
      {children}
    </div>
  );
};
