import axios from 'axios';
import { env } from '../../env';

import { ApiTopicSummaryPaged } from '../oipub-api';
import { ApiPaged, ApiTopicSummary } from '..';
import oipubApi from '../api-instance';

const baseUrl = `${env.apiUrl}/api/v1/topics`;

export const TopicsApi = {
  async getAllTopics(
    page: number,
    pageSize: number,
    order?: string
  ): Promise<ApiTopicSummaryPaged> {
    const response = await oipubApi.v1TopicsList({ page, pageSize, order });
    return response.data;
  },

  async getFeaturedTopics(
    includeDeleted: boolean
  ): Promise<ApiPaged<ApiTopicSummary>> {
    const url = `${baseUrl}/v1/topics/featured`;
    const response = await axios.get<ApiPaged<ApiTopicSummary>>(url, {
      params: {
        includeDeleted
      }
    });
    return response.data;
  },

  async getSystemTopics(
    includeDeleted: boolean
  ): Promise<ApiPaged<ApiTopicSummary>> {
    const url = `${baseUrl}/v1/topics/system`;
    const response = await axios.get<ApiPaged<ApiTopicSummary>>(url, {
      params: {
        includeDeleted
      }
    });
    return response.data;
  },

  async getKeywordTopics(
    includeDeleted: boolean
  ): Promise<ApiPaged<ApiTopicSummary>> {
    const url = `${baseUrl}/v1/topics/keyword`;
    const response = await axios.get<ApiPaged<ApiTopicSummary>>(url, {
      params: {
        pageSize: 12,
        includeDeleted
      }
    });
    return response.data;
  },

  async searchTopics(
    page: number,
    pageSize: number,
    query: string
  ): Promise<ApiTopicSummaryPaged> {
    const queryParams = {
      q: query,
      p: page,
      s: pageSize
    };
    const response = await oipubApi.v1SearchTopics(queryParams);
    return response.data;
  }
};
