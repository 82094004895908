const errorTexts = Object.freeze({
  topicNameIsMandatory: 'Topic name is mandatory!',
  atLeastOneTagIsRequired:
    'At least one topic or community tag must be selected!'
});

export interface IErrorMessages {
  topicNameIsMandatory: string;
  atLeastOneTagIsRequired: string;
}

export const errorMessages: Record<string, string> = Object.freeze({
  topicNameIsMandatory: errorTexts.topicNameIsMandatory,
  atLeastOneTagIsRequired: errorTexts.atLeastOneTagIsRequired
});

export const errorList: Record<string, string> = Object.freeze({
  TOPIC_NAME_IS_MANDATORY: 'topicNameIsMandatory',
  ATLEAST_ONE_TAG_IS_REQUIRED: 'atLeastOneTagIsRequired'
});
