import oipubApi from '../api-instance';
import { ApiReferral, ApiSendReferralRequest } from '../oipub-api';

export const ReferralApi = {
  async send(request: ApiSendReferralRequest): Promise<void> {
    const response = await oipubApi.v1ReferralCreate(request);
    return response.data;
  },
  async get(code: string): Promise<ApiReferral> {
    const response = await oipubApi.v1ReferralDetail(code);
    return response.data;
  }
};
