interface Props {}

export const TermsAndConditionsPageContainer: React.FC<Props> = () => {
  return (
    <main className="page-content tablet:mt-20 tablet:px-4 w-full max-w-3xl px-2 pb-32 mx-auto mt-10">
      <h1>Terms &amp; Conditions of Service</h1>
      <p>
        Thank you for choosing oipub.com. By using any of the services on offer
        through this website (www.oipub.com), and clicking to accept this
        Agreement, you agree to all the terms and conditions of this TERMS of
        SERVICE AGREEMENT.
      </p>
      <p>
        Throughout this Agreement, the terms “Us” or “Service Provider” refers
        to <strong>Omni Iota Science Limited</strong>, a limited liability
        company with company registration number C 96803, of 49, Villa Fort,
        Annibale Preca Street, Lija LJA 1914 – MALTA.
      </p>
      <p>
        The term “Client” shall refer to you personally. However, if you are
        using our services on behalf of a company or other entity, then “Client”
        means that entity and, therefore, you should have the power or the
        delegated authority to enter into this Agreement on behalf of that
        company or entity.
      </p>
      <p>
        The Service Provider and Client shall each individually be referred to
        herein as the <strong>“Party”</strong> and together and jointly referred
        to as the <strong>“Parties”</strong> throughout this Agreement.
      </p>
      <h3>1. Definitions</h3>
      <p>
        1.1. “Agreement” shall mean and refer to this Agreement between the
        Parties;
      </p>
      <p>
        1.2. “Confidential Information” shall, further to the provisions of
        article 3.1, mean and refer to all information (including all oral and
        visual information, and all information recorded in writing or
        electronically, or in any other medium or by any other method) currently
        known by the Parties respectively and includes the literary works
        subject to this Agreement while the services are being carried out;
      </p>
      <p>
        1.3. “Effective Date” shall mean the date and time when the Client has
        confirmed acceptance of this Agreement;
      </p>
      <p>
        1.4. “Data Protection Act” shall mean Chapter 586 of the Laws of Malta;
      </p>
      <p>
        1.5. “Force Majeure” shall refer to and include any circumstance,
        fortuitous event or irresistible force beyond the reasonable control of
        the Parties, including, without limitation, any strike, lock-out or
        other form of industrial action but excluding lack of available funds;
      </p>
      <p>
        1.6. “GDPR” shall mean the General Data Protection Regulation, EU
        Regulation 2016/679 titled ‘Regulation on the protection of natural
        persons with regard to the processing of personal data and on the free
        movement of such data, and repealing Directive 95/46/EC (Data Protection
        Directive)’.
      </p>
      <p>
        1.7. “Materials” shall mean any and all materials, reference items,
        literary works, documents, drafts, original versions, and preparatory
        materials uploaded, shared electronically or made available by the
        Service Provider to Client and vice versa;
      </p>
      <p>
        1.8. “Service(s)” shall mean and refer to the Service(s) provided by the
        Service Provider to Client in relation to the discovery, discussion,
        sharing and publishing tools provided by the Service Provider in
        accordance with this Agreement;
      </p>
      <p>
        1.9. “Works” or “Documents” shall mean the academic papers, including
        any graphical element, chart, spreadsheet, database, or other collated
        form of information, created or made available by the Service Provider
        in providing the Services to the Client.
      </p>
      <h3>2. Services</h3>
      <p>
        2.1. The Service Provider hereby agrees to make available an electronic
        platform on and through which the Client may upload, download or
        otherwise transfer Documents according to the terms of this Agreement.
        The Client enjoys unlimited right to upload and download any Works for
        academic use and the furtherance of scientific research, subject to
        reasonable use. Should the Service Provider determine that the Works or
        any part of them are not being used within the parameters set out in
        this Article 2.1 or are otherwise being used in bad faith it may, in its
        absolute discretion, either terminate this Agreement with the Client or
        otherwise restrict the Client’s access to the Services in whole or in
        part.
      </p>
      <p>
        2.2. The Service Provider may, should the need arise, contact the Client
        prior to or in the course of providing the Services should it require
        further information or communication with Client with regard to the
        Services to be provided.
      </p>
      <p>
        2.3. The Client hereby understands and agrees that the Service Provider
        may outsource some of the Services to one or more sub-contracted third
        parties, including but not limited to the hosting of data on cloud-based
        servers. The Client hereby gives their consent to the Service Provider
        to engage any such a third party sub-contractor. The Service Provider
        shall endeavour to find the most suitable third party sub-contractor to
        provide their services. The choice of the third party sub-contractor
        lies solely in the Service Provider’s discretion.
      </p>
      <p>
        2.4. The Service Provider shall be permitted to communicate with the
        Client by means of email communications which shall include newsletters,
        blogposts and other communications of an informative nature. Any other
        communications of a marketing nature shall require the Client’s express
        opt-in consent. The Client shall be permitted to opt out or to choose
        not to receive any or all communications from the Service Provider
        except those that are required as a necessary function of the Services
        themselves.
      </p>
      <p>
        2.5. The Client shall be permitted to post comments to the Works and
        discussion groups made available as part of the Services, provided
        however that:
      </p>
      <p>
        (i) The Client shall not post content that is illegal in any way, or is
        manifestly offensive. This shall include content that is libellous or
        defamatory, obscene or pornographic, or which violates the intellectual
        property rights of any third party.
      </p>
      <p>
        (ii) The Service Provider retains the right to moderate, edit, modify,
        or delete Client-generated content which is held to be illegal, which
        violates these terms and conditions, or which is otherwise offensive
        and/or inappropriate. In this regard, the Service Provider shall enjoy
        sole and exclusive discretion in making any such editorial decision.
      </p>
      <p>
        (iii) The Client shall, at all times, be held to be fully responsible
        for all content s/he may generate, and understands that their identity
        may be disclosed to law enforcement agencies and/or courts should a
        request to this effect be legitimately received by the Service Provider.
      </p>
      <h3>3. Confidentiality</h3>
      <p>3.1. Subject to the provisions of clause 3.2, any of the following:</p>
      <p>
        (i) confidential or proprietary information of the Parties which is
        marked as such or which by its nature is clearly confidential or
        proprietary;
      </p>
      <p>
        (ii) details and information related to the Services and the Works
        before publication/release;
      </p>
      <p>
        (iii) oral, confidential or proprietary information of the Parties which
        is designated as such at the time it is received by, provided to or made
        available to the other Party; and
      </p>
      <p>
        (iv) information subject to any confidentiality or non-disclosure
        agreement to which or by which either Party is a party or is bound,
      </p>
      <p>
        (whether such information is oral, written or contained on any storage
        media and whether such information is received by, provided to or made
        available to the other Party on, before or after the coming into force
        of this Agreement) shall constitute Confidential Information for the
        purposes of this Agreement;
      </p>

      <p>3.2. The Parties shall undertake to:</p>
      <p>
        (i) keep private and confidential any and all Confidential Information
        of the other Party by employing the same degree of care as they take to
        preserve and protect their own Confidential Information of a similar
        nature;
      </p>
      <p>
        (ii) not, without the other Party’s prior written consent or as
        otherwise required by law, to disclose any Confidential Information of
        the other Party in whole or in part to any other person save on a need
        to know basis to third parties but only after first obtaining the
        written consent of the other Party; (and shall make each of the third
        parties to whom any Confidential Information is disclosed under this
        clause 4 aware of the confidential nature of the Confidential
        Information and shall procure that each such third party shall abide by
        the terms of this clause).
      </p>
      <p>
        (iii) to use the Confidential Information of the other Party solely in
        connection with the performance of the Services under this Agreement and
        not for its own benefit or the benefit of any third party.
      </p>

      <p>
        3.3. The Parties accept that they are also bound by fiduciary
        obligations in relation to the information they are receiving from each
        other as regulated by the Civil Code, Chapter 16 of the Laws of Malta.
      </p>

      <h3>4. Intellectual Property Rights</h3>
      <p>
        4.1. The Service Provider hereby agrees that all and any intellectual
        property rights to the Works provided to Client under this Agreement,
        which include the literary works (as defined by the Copyright Act,
        Chapter 415 of the Laws of Malta), shall be the property of the author
        of the Works in question (including the Client, where applicable), under
        the conditions and remuneration stipulated in this Agreement, and that
        all title, interest and intellectual property rights in said Works shall
        vest in the author of those Works. These rights shall include the right
        to apply for the registration or enforcement of same.
      </p>
      <p>
        4.2. The Client warrants that they own the intellectual property rights
        to any Document given to or uploaded to the the Service Provider’s
        infrastucture as the basis upon which to carry out the Service, and that
        there has been no plagiarism on the Client’s part in producing said
        Document. The Client agrees to hold harmless and completely indemnify
        (including the reimbursement of all damages, losses, expenses, court
        expenses, filing costs, legal and lawyer’s fees) the Service Provider
        and furthermore the Client hereby agrees to subrogate or be subrogated
        by the Service Provider in any claim filed by a third party against the
        Service Provider and/or against any third party sub-contracted by the
        Service Provider.
      </p>
      <p>
        4.3. It is agreed that while the Service Provider recognises the
        intellectual property rights of each author to the Works, the Service
        Provider shall remain the proprietor of all database rights including
        the ownership of all copyright and other intellectual property rights
        thereto. The Service Provider declares that it has been licensed to use
        the intellectual property of third parties that features as part of the
        Works.
      </p>
      <p>
        4.4. The Service Provider agrees to not bring any claim against the
        Client based on its moral rights as granted to it by copyright law,
        insofar as the Client respects its obligations further to this
        Agreement.
      </p>
      <h3>5. Data Protection &amp; Privacy</h3>
      <p>
        5.1 Beyond the provisions of Article 3 of this Agreement, and without
        prejudice thereto, the Parties acknowledge and agree that due to the
        nature of the Services, the Service Provider shall come into contact
        with and will be required to process personal data as defined in the
        GDPR pertaining to the Client and/or any third parties that may be
        mentioned in Works. Accordingly, the Service Provider binds itself as
        follows:
      </p>
      <p>
        5.1.1 All personal data shall be processed in strict accordance with the
        GDPR and the Data Protection Act using all reasonable means available to
        the Service Provider;
      </p>
      <p>
        5.1.2 All personal data shall only be processed for the purposes of
        providing the Services, and/or all other services that may be connected,
        directly or indirectly, to the provision of those Services, including
        the maintaining of any Client account for administration purposes;
      </p>
      <p>
        5.1.3 All personal data shall be processed solely and exclusively by the
        Service Provider and its appointed personnel (including any
        subcontractors) who shall be obliged to take on the same obligations set
        out in this Article 5 of this Agreement. Personal data shall only be
        disclosed to a third party where the Client has consented to such
        disclosure, or where the Service Provider is constrained or obliged by
        any law or legal authority to effect the disclosure in question;
      </p>
      <p>
        5.1.4 Any personal data processed by the Service Provider in connection
        with the Services may be held for a maximum of ten (10) years from the
        date when it was last actively used; although the Service Provider shall
        actively seek to destroy or minimise data that has manifestly become
        inactive or outdated;
      </p>
      <p>
        5.1.5 All or any queries regarding any data protection or privacy
        matter, including any request for any amendments or updates, or any
        request for access to personal data, may be directed to the Service
        Provider on support@oipub.com. In some cases, the Client or any third
        party making such a query may be requested to provide proof of identity
        before the query may be considered and addressed.
      </p>
      <p>
        5.1.6 The Client and/or any aggrieved third party retains the right at
        all times to communicate with the Office of the Information and Data
        Protection Commissioner [www.idpc.org.mt], or any other competent Data
        Protection Authority that may exist within the Client’s respective
        jurisdiction.
      </p>
      <h3>6. Viruses, Malware &amp; Other Malicious Software</h3>

      <p>
        6.1 Electronic documents and electronic communications may from time to
        time be affected by malicious software such as viruses, trojans and
        worms (collectively ‘Malicious Software’). It shall be the Client’s
        responsibility to ensure that all Works that are communicated or
        transmitted to the Service Provider shall be clean of all Malicious
        Software. Similarly, the Service Provider shall ensure, to the best of
        its technical ability, that all communications sent from it, including
        any Works are free from Malicious Software, provided however that:
      </p>
      <p>
        6.1.1 The Service Provider reserves the right to refuse any electronic
        file that results to be contaminated or infected with any form of
        Malicious Software, and to inform the Client accordingly. The
        obligations set out in this Agreement shall be suspended until such
        moment that the Client provides a copy of the Works that are free from
        Malicious Software;
      </p>
      <p>
        6.1.2 The Service Provider shall not be held liable for any Malicious
        Software, or for any direct or indirect losses arising therefrom, that
        may be transmitted to the Client or to any third party, and the Client
        shall take all reasonable technical measures to ensure that all
        electronic communications are screened for the presence of Malicious
        Software.
      </p>
      <h3>7. Term and Termination</h3>
      <p>
        This Agreement shall commence and shall be enforceable and binding upon
        and between the Parties on the Effective Date.
      </p>
      <p>
        The clauses relating to Confidentiality, Intellectual Property Rights,
        Warranties, Disputes and Jurisdiction shall remain in force between the
        Parties even after termination of this Agreement.
      </p>
      <p>
        The Service Provider retains the right to unilaterally terminate this
        Agreement upon the breach by the Client of any of the terms and
        conditions set out herein, or should the Services not be used for an
        uninterrupted period of ten (10) years.
      </p>

      <h3>8. Amendments</h3>
      <p>
        No amendments, changes or waivers to this Agreement shall be binding
        upon the Parties unless made in writing and duly agreed to by both
        Parties.
      </p>
      <p>
        To this effect, the Service Provider reserves the right to update these
        terms and conditions as may be necessary from time to time, and any
        continued use of the Services by the Client shall be deemed to amount to
        a tacit acceptance of any updated terms and conditions.
      </p>
      <p>
        Provided that the Service Provider shall be obliged to bring significant
        changes to these terms and conditions to the attention of the Client,
        when such changes significantly affect the rights of either party to
        this Agreement.
      </p>
      <h3>9. General Provisions</h3>
      <p>
        Neither Party may assign this Agreement or any of its rights or
        obligations hereunder without the prior written consent of the other
        Party.
      </p>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of the Republic of Malta and all or any disputes arising from or
        connected to this Agreement shall be referred and decided upon by the
        Courts of Malta.
      </p>
      <p>
        No waiver of any provision of this Agreement, or of any rights or
        obligations of either Party hereunder shall be effective unless in
        writing and signed by both Parties.
      </p>
      <p>
        This Agreement sets forth the entire understanding of and between the
        Parties as to the subject matter therein.
      </p>
    </main>
  );
};
