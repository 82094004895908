export interface TwColours {
  black: string;
  white: string;
  primary: string;
  green: string;
  gray: string;
  error: string;
}

export const textColors: TwColours = {
  black: 'text-black',
  white: 'text-white',
  primary: 'text-primary',
  green: 'text-green',
  gray: 'text-gray',
  error: 'text-error'
};
