import classNames from 'classnames';

interface Props {
  className?: string;
  color?: string;
}

export const VisibilityHidden: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block w-4 h-4', className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="visibility_off_24px">
        <path
          id="icon/action/visibility_off_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.70996 4.54002C2.31995 4.15001 2.31995 3.52 2.70996 3.12999C3.09998 2.74004 3.73999 2.74004 4.13 3.12999L20.4399 19.46C20.83 19.85 20.83 20.48 20.4399 20.87C20.05 21.26 19.42 21.26 19.03 20.87L16.3101 18.15C14.97 18.67 13.52 18.97 12 18.97C7 18.97 2.72998 15.86 1 11.47C1.77002 9.49998 3.06006 7.80003 4.68005 6.50999L2.70996 4.54002ZM17 11.47C17 8.71001 14.76 6.47002 12 6.47002C11.49 6.47002 11 6.56999 10.53 6.71001L8.35999 4.54002C9.51001 4.17003 10.73 3.97002 12 3.97002C17 3.97002 21.27 7.08 23 11.46C22.3099 13.22 21.21 14.76 19.8199 15.99L16.76 12.93C16.9 12.47 17 11.98 17 11.47ZM12 16.47C9.23999 16.47 7 14.23 7 11.47C7 10.7 7.18005 9.97002 7.48999 9.33L9.06006 10.9C9.03003 11.08 9 11.27 9 11.47C9 13.13 10.34 14.47 12 14.47C12.2 14.47 12.38 14.44 12.5699 14.4L14.14 15.97C13.49 16.29 12.77 16.47 12 16.47ZM14.97 11.14C14.8199 9.73997 13.72 8.65001 12.33 8.49998L14.97 11.14Z"
          fill="black"
          fillOpacity="0.54"
        />
      </g>
    </svg>
  );
};
