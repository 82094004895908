import React from 'react';
import { Modal } from '.';

interface NotificationModalWrapperProps {
  isOpen: boolean;
  title: string;
  message: React.ReactNode;
  onClose: () => void;
}

export const NotificationModalWrapper: React.FC<
  NotificationModalWrapperProps
> = ({ isOpen, title, message, onClose }) => {
  return (
    <Modal
      modalName="notificationModal"
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className="notification-modal main-page right-0"
    >
      <div>{message}</div>
    </Modal>
  );
};
