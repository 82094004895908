import { ISelectedEntity } from '../../types/topic.type';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const TOGGLE_ADMIN_SIDEBAR = 'TOGGLE_ADMIN_SIDEBAR';

export interface OpenModalAction {
  type: typeof OPEN_MODAL;
  payload: {
    modalName: string;
    url?: string;
    selectedCommunityOrTopic?: ISelectedEntity;
  };
}

export interface CloseModalAction {
  type: typeof CLOSE_MODAL;
  payload: string;
}

export interface ToggleAdminSidebarAction {
  type: typeof TOGGLE_ADMIN_SIDEBAR;
}

export const openModal = (
  modalName: string,
  url?: string,
  selectedCommunityOrTopic?: ISelectedEntity
): OpenModalAction => ({
  type: OPEN_MODAL,
  payload: { modalName, url, selectedCommunityOrTopic }
});

export const closeModal = function (modalName: string): CloseModalAction {
  return {
    type: CLOSE_MODAL,
    payload: modalName
  };
};

export const toggleAdminSidebar = (): ToggleAdminSidebarAction => ({
  type: TOGGLE_ADMIN_SIDEBAR
});

export type ModalActionTypes =
  | OpenModalAction
  | CloseModalAction
  | ToggleAdminSidebarAction;
