import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Span } from '../../atoms';
import { Validation } from '../form';
import { Tooltip } from '../core/tooltip';
import { QuestionMark } from '../../svgs';
import { serializeFilters } from './utils/serializeFilters';
import {
  resetCommunityPaperFilterData,
  resetTopicPaperFilterData
} from '../../state/slices/filterPapersReducer';
import { useAppDispatch } from '../../state';

interface PapersMainFilterProps {
  urlKey: string;
  setFilterData: (filterData: any) => void;
  fetchData: (filterData: any) => void;
  resetFilterData: () => void;
}

export const PapersMainFilter: React.FC<PapersMainFilterProps> = ({
  urlKey,
  setFilterData,
  fetchData,
  resetFilterData
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const currentYear = new Date().getFullYear();

  const PaperFilterSchema = Yup.object().shape({
    title: Yup.string()
      .nullable()
      .min(2, 'Title must be at least 2 characters')
      .max(64, 'Title must be at most 64 characters'),
    author: Yup.string()
      .nullable()
      .min(2, 'Author must be at least 2 characters')
      .max(64, 'Author must be at most 64 characters'),
    journal: Yup.string()
      .nullable()
      .min(2, 'Journal must be at least 2 characters')
      .max(64, 'Journal must be at most 64 characters'),
    publishedDateFrom: Yup.string()
      .nullable()
      .matches(/^[12]\d{3}$/, `Enter a valid year (1000 – ${currentYear})`)
      .test(
        'year-range',
        `Year must be less than or equal to ${currentYear}`,
        function (value) {
          if (!value) return true;
          const year = parseInt(value, 10);
          return year <= currentYear;
        }
      ),
    publishedDateTo: Yup.string()
      .nullable()
      .matches(/^[12]\d{3}$/, `Enter a valid year (1000 – ${currentYear})`)
      .test(
        'year-range',
        `Year must be less than or equal to ${currentYear}`,
        function (value) {
          if (!value) return true;
          const year = parseInt(value, 10);
          return year <= currentYear;
        }
      )
  });

  const urlInitialValues = useMemo(
    () => ({
      title: searchParams.get('title') || '',
      publishedDateFrom: searchParams.get('publishedDateFrom') || '',
      publishedDateTo: searchParams.get('publishedDateTo') || '',
      author: searchParams.get('author') || '',
      journal: searchParams.get('journal') || ''
    }),
    [searchParams]
  );

  const urlInitialValuesForInputs = useMemo(
    () => ({
      ...urlInitialValues,
      publishedDateFrom: urlInitialValues.publishedDateFrom.split('-')[0],
      publishedDateTo: urlInitialValues.publishedDateTo.split('-')[0]
    }),
    [urlInitialValues]
  );

  const initialSerializedFilters = useMemo(
    () => serializeFilters(urlInitialValuesForInputs),
    [urlInitialValuesForInputs]
  );
  const [lastAppliedFilters, setLastAppliedFilters] = useState<string>(
    initialSerializedFilters
  );

  const isSearchParamsEmpty = useCallback((): boolean => {
    return (
      !searchParams.get('title') &&
      !searchParams.get('publishedDateFrom') &&
      !searchParams.get('publishedDateTo') &&
      !searchParams.get('author') &&
      !searchParams.get('journal')
    );
  }, [searchParams]);

  const isAllFieldsEmpty = useCallback(
    (values: typeof urlInitialValuesForInputs): boolean => {
      return (
        !values.title &&
        !values.publishedDateFrom &&
        !values.publishedDateTo &&
        !values.author &&
        !values.journal
      );
    },
    []
  );

  const isSameAsLast = useCallback(
    (currentFilters: string): boolean => {
      return currentFilters === lastAppliedFilters;
    },
    [lastAppliedFilters]
  );

  const updateURLWithFilters = useCallback(
    (values: typeof urlInitialValues, pageNum: number, pageSize: number) => {
      const formattedValues = { ...values };

      if (values.publishedDateFrom && !values.publishedDateTo) {
        formattedValues.publishedDateFrom = `${values.publishedDateFrom}-01-01`;
      } else if (values.publishedDateFrom && values.publishedDateTo) {
        formattedValues.publishedDateFrom = `${values.publishedDateFrom}-01-01`;
        formattedValues.publishedDateTo = `${values.publishedDateTo}-12-31`;
      } else {
        formattedValues.publishedDateFrom = '';
        formattedValues.publishedDateTo = '';
      }

      const filteredValues = Object.fromEntries(
        Object.entries(formattedValues).filter(([, value]) => value)
      );

      const params = new URLSearchParams({
        ...filteredValues,
        pageNum: pageNum.toString(),
        pageSize: pageSize.toString()
      });

      setSearchParams(params);
    },
    [setSearchParams]
  );

  const handleApply = useCallback(
    (values: any, pageNum = 1, pageSize = 10) => {
      updateURLWithFilters(values, pageNum, pageSize);
      const filterWithPagination = { ...values, pageNum, pageSize };

      if (values.publishedDateFrom && !values.publishedDateTo) {
        filterWithPagination.publishedDateFrom = `${values.publishedDateFrom}-01-01`;
        filterWithPagination.publishedDateTo = `${values.publishedDateFrom}-12-31`;
      } else if (values.publishedDateFrom && values.publishedDateTo) {
        filterWithPagination.publishedDateFrom = `${values.publishedDateFrom}-01-01`;
        filterWithPagination.publishedDateTo = `${values.publishedDateTo}-12-31`;
      } else {
        filterWithPagination.publishedDateFrom = '';
        filterWithPagination.publishedDateTo = '';
      }

      setFilterData(filterWithPagination);
      fetchData({
        filterData: filterWithPagination,
        urlKey
      });
    },
    [updateURLWithFilters, setFilterData, fetchData, urlKey]
  );

  const handleReset = useCallback(
    (
      resetForm: () => void,
      setFieldValue: (field: string, value: any) => void,
      values: any
    ) => {
      const isAlreadySearchParamsEmpty = isSearchParamsEmpty();
      const isAlreadyEmptyValues = isAllFieldsEmpty(values);
      if (isAlreadySearchParamsEmpty && isAlreadyEmptyValues) {
        return;
      }

      resetForm();
      setFieldValue('publishedDateFrom', '');
      setFieldValue('publishedDateTo', '');

      const resetValues = {
        title: '',
        publishedDateFrom: '',
        publishedDateTo: '',
        author: '',
        journal: ''
      };

      const filteredValues = Object.fromEntries(
        Object.entries(resetValues).filter(([, value]) => value)
      );

      const params = new URLSearchParams(filteredValues);
      setSearchParams(params);
      setLastAppliedFilters('');
      resetFilterData();
      dispatch(resetTopicPaperFilterData());
      dispatch(resetCommunityPaperFilterData());
    },
    [
      isSearchParamsEmpty,
      isAllFieldsEmpty,
      setSearchParams,
      resetFilterData,
      dispatch
    ]
  );

  const handleSubmit = (values: typeof urlInitialValues) => {
    const serializedCurrentFilters = serializeFilters(values);
    if (isSameAsLast(serializedCurrentFilters)) {
      return;
    }
    setLastAppliedFilters(serializedCurrentFilters);
    handleApply(values);
  };

  // On component mount, update form values if present in the URL
  useEffect(() => {
    const pageNum = searchParams.get('pageNum')
      ? parseInt(searchParams.get('pageNum')!)
      : 1;
    const pageSize = searchParams.get('pageSize')
      ? parseInt(searchParams.get('pageSize')!)
      : 10;

    const filterWithPagination = { ...urlInitialValues, pageNum, pageSize };

    if (
      urlInitialValues.title ||
      urlInitialValues.publishedDateFrom ||
      urlInitialValues.publishedDateTo ||
      urlInitialValues.author ||
      urlInitialValues.journal
    ) {
      setFilterData(filterWithPagination);
      fetchData({
        filterData: filterWithPagination,
        urlKey
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run only at start

  return (
    <div className="filter-container">
      <Span
        color="black"
        size="sm"
        className="block mb-4 uppercase font-medium"
      >
        Filter
      </Span>
      <Formik
        initialValues={urlInitialValuesForInputs}
        validationSchema={PaperFilterSchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({ values, resetForm, setFieldValue, errors, touched }) => {
          const allFieldsEmpty = isAllFieldsEmpty(values);
          const serializedCurrentFilters = serializeFilters(values);
          const isSame = isSameAsLast(serializedCurrentFilters);

          return (
            <Form className="w-full">
              <div className="flex flex-col filters">
                <div className="mb-4">
                  <Span color="black" size="sm" className="font-medium">
                    Title
                  </Span>
                  <Field
                    id="title"
                    name="title"
                    type="text"
                    placeholder="Title"
                    className={`h-8 border w-full px-3 ${
                      touched.title && errors.title ? 'border-error-500' : ''
                    } ${
                      values.title && !errors.title ? 'border-green-500' : ''
                    } ${!values.title ? 'border-gray-400' : ''}`}
                    value={values.title}
                  />
                  {errors.title && touched.title && (
                    <Validation message={errors.title} />
                  )}
                </div>
                <div className="mb-4 relative">
                  <div className="flex justify-between">
                    <Span color="black" size="sm" className="font-medium">
                      Publication Year
                    </Span>
                    <Tooltip
                      className="tooltipDate"
                      text={`Enter a valid year (1000 – ${currentYear}) in the first field. Type it only in the first field to filter by a specific year. To filter a range of years, fill both fields. The second field will be enabled once the first one is filled.`}
                    >
                      <QuestionMark />
                    </Tooltip>
                  </div>
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center">
                      <Field
                        id="publishedDateFrom"
                        name="publishedDateFrom"
                        type="text"
                        placeholder="YYYY"
                        className={`h-8 border w-full px-3 ${
                          touched.publishedDateFrom && errors.publishedDateFrom
                            ? 'border-error-500'
                            : ''
                        } ${
                          values.publishedDateFrom && !errors.publishedDateFrom
                            ? 'border-green-500'
                            : ''
                        } ${!values.publishedDateFrom ? 'border-gray-400' : ''}`}
                        value={values.publishedDateFrom}
                      />

                      <div className="mr-2 ml-2">{' — '}</div>
                      <Field
                        id="publishedDateTo"
                        name="publishedDateTo"
                        type="text"
                        placeholder="YYYY"
                        className={`h-8 border w-full px-3 ${
                          touched.publishedDateTo && errors.publishedDateTo
                            ? 'border-error-500'
                            : ''
                        } ${
                          values.publishedDateTo && !errors.publishedDateTo
                            ? 'border-green-500'
                            : ''
                        } ${!values.publishedDateTo ? 'border-gray-400' : ''}`}
                        disabled={!values.publishedDateFrom}
                        value={values.publishedDateTo}
                      />
                    </div>
                  </div>
                  {errors.publishedDateFrom && touched.publishedDateFrom && (
                    <Validation message={errors.publishedDateFrom} />
                  )}
                  {errors.publishedDateTo && touched.publishedDateTo && (
                    <Validation message={errors.publishedDateTo} />
                  )}
                </div>
                <div className="mb-4">
                  <Span color="black" size="sm" className="font-medium">
                    Author
                  </Span>
                  <Field
                    id="author"
                    name="author"
                    type="text"
                    placeholder="Author"
                    className={`h-8 border w-full px-3 ${
                      touched.author && errors.author ? 'border-error-500' : ''
                    } ${
                      values.author && !errors.author ? 'border-green-500' : ''
                    } ${!values.author ? 'border-gray-400' : ''}`}
                    value={values.author}
                  />
                  {errors.author && touched.author && (
                    <Validation message={errors.author} />
                  )}
                </div>
                <div className="mb-4">
                  <Span color="black" size="sm" className="font-medium">
                    Journal
                  </Span>
                  <Field
                    id="journal"
                    name="journal"
                    type="text"
                    placeholder="Journal"
                    className={`h-8 border w-full px-3 ${
                      touched.journal && errors.journal
                        ? 'border-error-500'
                        : ''
                    } ${
                      values.journal && !errors.journal
                        ? 'border-green-500'
                        : ''
                    } ${!values.journal ? 'border-gray-400' : ''}`}
                    value={values.journal}
                  />
                  {errors.journal && touched.journal && (
                    <Validation message={errors.journal} />
                  )}
                </div>
                <div className="flex mb-1">
                  <button
                    type="submit"
                    className={`btnSmall text-white bg-gradient-to-r from-primaryLight to-primaryDefault px-4 py-2 gap-1 rounded font-medium focus:outline-none mr-2 w-full text-center ${
                      allFieldsEmpty || isSame
                        ? 'opacity-50 '
                        : 'opacity-100 hover:opacity-75'
                    }`}
                    disabled={allFieldsEmpty || isSame}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleReset(resetForm, setFieldValue, values);
                    }}
                    className="btnSmall text-primary-500 hover:text-primary-400 active:text-primary-200 px-4 py-2 text-sm font-medium transition-colors duration-200 bg-white border border-current rounded w-full text-center"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
