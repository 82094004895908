import classNames from 'classnames';

interface Props {
  className?: string;
}

export const DatePost: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        d="M8.33333 0.74999V1.33332H3.66667V0.74999C3.66667 0.429157 3.40417 0.166656 3.08333 0.166656C2.7625 0.166656 2.5 0.429157 2.5 0.74999V1.33332H1.91667C1.26917 1.33332 0.755833 1.85832 0.755833 2.49999L0.75 10.6667C0.75 11.3083 1.26917 11.8333 1.91667 11.8333H10.0833C10.725 11.8333 11.25 11.3083 11.25 10.6667V2.49999C11.25 1.85832 10.725 1.33332 10.0833 1.33332H9.5V0.74999C9.5 0.429157 9.2375 0.166656 8.91667 0.166656C8.59583 0.166656 8.33333 0.429157 8.33333 0.74999ZM8.33333 6.58332H6.58333C6.2625 6.58332 6 6.84582 6 7.16666V8.91666C6 9.23749 6.2625 9.49999 6.58333 9.49999H8.33333C8.65417 9.49999 8.91667 9.23749 8.91667 8.91666V7.16666C8.91667 6.84582 8.65417 6.58332 8.33333 6.58332ZM2.5 10.6667H9.5C9.82083 10.6667 10.0833 10.4042 10.0833 10.0833V4.24999H1.91667V10.0833C1.91667 10.4042 2.17917 10.6667 2.5 10.6667Z"
        fill="#EE9844"
      />
    </svg>
  );
};
