import { AppState } from '../store';

export const selectUserPosts = (state: AppState) => state.userPosts.posts;
export const selectUserPostsLoading = (state: AppState) =>
  state.userPosts.loading;
export const selectUserPostsError = (state: AppState) => state.userPosts.error;
export const selectDeletedUserPosts = (state: AppState) =>
  state.userPosts.deletedPosts;
export const selectDeletedUserPostsLoading = (state: AppState) =>
  state.userPosts.loadingDeleted;
export const selectDeletedUserPostsError = (state: AppState) =>
  state.userPosts.errorDeleted;
