import classNames from 'classnames';
import { textColors } from './textColors';
import { textSizes } from './textSizes';

export interface Props {
  className?: string;
  muted?: boolean;
  color?: string;
  size?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Span: React.FC<Props> = ({
  size = 'base',
  className = '',
  muted,
  color = 'white',
  children
}: Props) => {
  const paragraphClass = classNames(
    `${textColors[color as keyof typeof textColors]}`,
    { 'tw-text-muted': muted },
    `${textSizes[size as keyof typeof textSizes]}`,
    'tw-leading-tight',
    className
  );

  return <span className={paragraphClass}>{children}</span>;
};
