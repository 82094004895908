import classNames from 'classnames';

interface Props {
  className?: string;
}

export const SharePost: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M9.75 8.73168C9.33833 8.73168 8.97 8.89418 8.68833 9.14876L4.82625 6.90084C4.85333 6.77626 4.875 6.65168 4.875 6.52168C4.875 6.39168 4.85333 6.26709 4.82625 6.14251L8.645 3.91626C8.9375 4.18709 9.32208 4.35501 9.75 4.35501C10.6492 4.35501 11.375 3.62918 11.375 2.73001C11.375 1.83084 10.6492 1.10501 9.75 1.10501C8.85083 1.10501 8.125 1.83084 8.125 2.73001C8.125 2.86001 8.14667 2.98459 8.17375 3.10918L4.355 5.33543C4.0625 5.06459 3.67792 4.89668 3.25 4.89668C2.35083 4.89668 1.625 5.62251 1.625 6.52168C1.625 7.42084 2.35083 8.14668 3.25 8.14668C3.67792 8.14668 4.0625 7.97876 4.355 7.70793L8.21167 9.96126C8.18458 10.075 8.16833 10.1942 8.16833 10.3133C8.16833 11.1854 8.87792 11.895 9.75 11.895C10.6221 11.895 11.3317 11.1854 11.3317 10.3133C11.3317 9.44126 10.6221 8.73168 9.75 8.73168Z"
        fill="#EE9844"
      />
    </svg>
  );
};
