import classNames from 'classnames';

interface Props {
  className?: string;
}

export const QuestionMark: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.5C0 3.36 3.36 0 7.5 0C11.64 0 15 3.36 15 7.5C15 11.64 11.64 15 7.5 15C3.36 15 0 11.64 0 7.5ZM1.5 7.5C1.5 10.815 4.185 13.5 7.5 13.5C10.815 13.5 13.5 10.815 13.5 7.5C13.5 4.185 10.815 1.5 7.5 1.5C4.185 1.5 1.5 4.185 1.5 7.5Z"
        fill="black"
        fillOpacity="0.54"
      />
      <path d="M6.75 9.75H8.25V11.25H6.75V9.75Z" fill="#757575" />
      <path
        d="M5.82 6.05116H5.90524C6.12154 6.05116 6.29195 5.89875 6.36405 5.69332C6.52136 5.23609 6.9605 4.8915 7.45208 4.8915C8.06167 4.8915 8.59913 5.43488 8.59913 6.05116C8.59913 6.92679 7.62248 7.07828 7.13747 7.91986H7.13091C7.07876 8.01262 7.03916 8.112 7.00133 8.21788C6.99478 8.23137 6.98823 8.23813 6.98823 8.25163C6.98168 8.26512 6.98168 8.27861 6.98168 8.28537C6.91462 8.48978 6.87529 8.72166 6.87529 9H8.0289C8.0289 8.83434 8.05507 8.68854 8.10722 8.55601C8.4615 7.5819 9.92308 7.32347 9.73305 5.78609C9.60844 4.75899 8.79578 3.90414 7.77982 3.77161C6.61967 3.61919 5.6234 4.28848 5.28257 5.29573C5.15148 5.66681 5.43332 6.05116 5.82 6.05116Z"
        fill="#757575"
      />
    </svg>
  );
};
