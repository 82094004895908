import { FC } from 'react';
import { IPaperCitations } from '../../../types/paper.type';
import { CitesCitedItem } from './citesCitedByItem';

interface CitedByListProps {
  citations?: IPaperCitations[];
}

export const CitedByList: FC<CitedByListProps> = ({ citations }) => {
  return <CitesCitedItem items={citations || []} />;
};
