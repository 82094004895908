import axios from 'axios';
import { ApiSearchResults, ApiSearchType } from '..';
import { env } from '../../env';
import oipubApi from '../api-instance';
import { ApiLibraryVersion } from '../models/api-library-version';
import {
  ApiCommonHubPaged,
  ApiDatabaseCounts,
  ApiPaperPaged,
  SearchTypes
} from '../oipub-api';

const baseUrl = `${env.apiUrl}/api`;

export const LibraryApi = {
  async getCounts(): Promise<ApiDatabaseCounts> {
    const response = await oipubApi.v1LibraryStatsList();
    return response.data;
  },

  async search(
    term: string,
    page: number | null,
    type: string | null
  ): Promise<ApiSearchResults> {
    const url = `${baseUrl}/v1/library/search`;

    const response = await axios.get<ApiSearchResults>(url, {
      params: {
        q: term,
        page: page || undefined,
        type: type || undefined
      }
    });

    return response.data;
  },

  async searchPapers(term: string, page = 1): Promise<ApiPaperPaged> {
    const response = await oipubApi.v1FeedSearchList({
      q: term,
      p: page
    });
    return response.data;
  },

  async searchTopicsCommunities(
    term: string,
    page = 1,
    type: ApiSearchType
  ): Promise<ApiCommonHubPaged> {
    let apiType;
    switch (type) {
      case ApiSearchType.Topics:
        apiType = SearchTypes.Value1;
        break;
      case ApiSearchType.Communities:
        apiType = SearchTypes.Value2;
    }

    const response = await oipubApi.v1HubsSearchList({
      q: term,
      p: page,
      t: apiType
    });

    return response.data;
  },

  async version(): Promise<ApiLibraryVersion[]> {
    const url = `${baseUrl}/v1/library/version`;
    const response = await axios.get<ApiLibraryVersion[]>(url);
    return response.data;
  }
};
