import React, { useEffect } from 'react';
import { HomepageContainer } from './homepage-container';
import { analytics } from '../../analytics';
import { useLocation } from 'react-router-dom';
import { useGetUserIsAdmin } from '../../components/user';
import { HomeTopBar } from '../../components/core/topBars';

export interface Props {}

export const Homepage: React.FC<Props> = () => {
  useGetUserIsAdmin();
  const location = useLocation();

  useEffect(() => {
    const loc = location.pathname + location.search;
    analytics.page(loc);
  }, [location.pathname, location.search]);

  return (
    <HomeTopBar>
      <HomepageContainer />
    </HomeTopBar>
  );
};
