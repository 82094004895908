import {
  SET_CITES_CITEDBY_VIEW_MODE,
  ViewModeCitesCitedBy,
  ViewModeCitesCitedByAction
} from '../actions/viewModeCitesCitedByActions';

export interface ViewCitesCitedByState {
  mode: ViewModeCitesCitedBy;
}

const initialState: ViewCitesCitedByState = {
  mode: 'single'
};

const viewModeCitesCitedByReducer = (
  state = initialState,
  action: ViewModeCitesCitedByAction
): ViewCitesCitedByState => {
  switch (action.type) {
    case SET_CITES_CITEDBY_VIEW_MODE:
      return { ...state, mode: action.payload };
    default:
      return state;
  }
};

export default viewModeCitesCitedByReducer;
