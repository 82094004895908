import { useAppSelector } from '../../state';
import { FC } from 'react';
import { Loading } from '../core';
import { ErrorComponent } from '../core/error';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Previewv2 } from '../paper/paper-preview';
import { SearchPagination } from '../../routes/search/search-pagination';
import { IPaper } from '../../types/paper.type';
import {
  selectCommunityError,
  selectCommunityLoading,
  selectCommunityPaperFilterData,
  selectCommunityPapersData,
  selectTopicError,
  selectTopicLoading,
  selectTopicPaperFilterData,
  selectTopicPapersData
} from '../../state/selectors/filterSelector';

interface PapersFilterListProps {
  updatePage: (newPage: number) => void;
  isCommunity?: boolean;
  isTripleView: boolean;
}

export const PapersFilterList: FC<PapersFilterListProps> = ({
  updatePage,
  isCommunity,
  isTripleView
}) => {
  const paperFilterData = useAppSelector((state) =>
    isCommunity
      ? selectCommunityPapersData(state)
      : selectTopicPapersData(state)
  );
  const loading = useAppSelector((state) =>
    isCommunity ? selectCommunityLoading(state) : selectTopicLoading(state)
  );
  const error = useAppSelector((state) =>
    isCommunity ? selectCommunityError(state) : selectTopicError(state)
  );

  const pageNum = useAppSelector((state) =>
    isCommunity
      ? selectCommunityPaperFilterData(state).pageNum
      : selectTopicPaperFilterData(state).pageNum
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorComponent error={error} />;
  }

  if (paperFilterData && paperFilterData.page?.length === 0) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <p>No papers found for the applied filters.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
      <div className="flex w-full flex max-w-3xl">
        <div className="flex flex-wrap w-full">
          {paperFilterData &&
            paperFilterData.page &&
            paperFilterData.page.length > 0 && (
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  350: 1,
                  600: isTripleView ? 2 : 1,
                  900: isTripleView ? 3 : 1
                }}
                className="w-full"
              >
                <Masonry gutter="14px">
                  {paperFilterData.page?.map((paper: IPaper, idx: number) => (
                    <Previewv2
                      key={`Previewv2${idx}`}
                      paper={paper}
                      showThreeItems={isTripleView}
                    />
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            )}
        </div>
      </div>
      {paperFilterData.numPages && pageNum && (
        <div className="max-w-3xl mx-auto mt-8 w-full">
          <SearchPagination
            currentPage={pageNum}
            lastPage={paperFilterData.numPages}
            numOfMobilPages={5}
            onPageChange={updatePage}
          />
        </div>
      )}
    </div>
  );
};
