import React, { FC, FormEvent, useCallback, useEffect, useMemo } from 'react';
import { Flex, HeadingOne, HeadingTwo, Button } from '../../../atoms';
import ErrorInfo from '../../../atoms/errorInfo';
import { texts } from '../../../text';
import {
  CommunityTagsInput,
  InputLetterCount,
  RequiredFieldLabel,
  TopicTagsInput
} from '../../../components/form';
import { RichTextEditor } from '../../../components/richTextEditor';
import { Tag } from '../../../types/topic.type';
import * as Yup from 'yup';
import { useFormik } from 'formik';

interface Props {
  initialPostName: string;
  initialDescription: string;
  topicTags: Tag[];
  communityTags: Tag[];
  selectedTopicTags: Tag[];
  selectedCommunityTags: Tag[];
  removeTopic: (topicUrlKey: string) => void;
  filterTopics: (event: React.FormEvent<HTMLInputElement>) => void;
  addTopic: (elem: string) => void;
  addCommunity: (elem: string) => void;
  removeCommunity: (communityUrlKey: string) => void;
  filterCommunities: (event: React.FormEvent<HTMLInputElement>) => void;
  setPostName: (name: string) => void;
  setMarkdown: (value: string) => void;
  handleUpdate: () => void;
  inputTopicsValue: string;
  inputCommunityTags: string;
  errors: Record<string, string>;
  isUserAdmin: boolean;
  isUserCreator: boolean;
  fetchMoreTopics: () => void;
  hasMoreTopicPage: boolean;
  fetchMoreCommunities: () => void;
  hasMoreCommunityPage: boolean;
}

const UpdateUserPostSchema = Yup.object().shape({
  postName: Yup.string()
    .required('Required')
    .min(3, 'Title must be at least 3 characters long'),
  markdown: Yup.string()
    .required('Required')
    .min(3, 'Text must be at least 3 characters long'),
  selectedTopicTags: Yup.array().test(
    'topics-or-communities',
    'Topics or Communities must be filled',
    function (value = []) {
      const { selectedCommunityTags = [] } = this.parent;
      return selectedCommunityTags.length > 0 || value.length > 0;
    }
  ),
  selectedCommunityTags: Yup.array().test(
    'topics-or-communities',
    'Topics or Communities must be filled',
    function (value = []) {
      const { selectedTopicTags = [] } = this.parent;
      return selectedTopicTags.length > 0 || value.length > 0;
    }
  )
});

export const UpdatePostPage: FC<Props> = ({
  topicTags,
  communityTags,
  initialPostName,
  initialDescription,
  selectedTopicTags,
  selectedCommunityTags,
  removeTopic,
  filterTopics,
  addTopic,
  addCommunity,
  removeCommunity,
  filterCommunities,
  setPostName,
  setMarkdown,
  handleUpdate,
  inputTopicsValue,
  inputCommunityTags,
  errors,
  isUserAdmin,
  isUserCreator,
  fetchMoreTopics,
  hasMoreTopicPage,
  fetchMoreCommunities,
  hasMoreCommunityPage
}) => {
  const formik = useFormik({
    initialValues: {
      postName: initialPostName,
      markdown: initialDescription,
      selectedTopicTags: selectedTopicTags,
      selectedCommunityTags: selectedCommunityTags
    },
    validationSchema: UpdateUserPostSchema,
    onSubmit: () => {
      handleUpdate();
    },
    validateOnChange: true,
    validateOnBlur: true
  });

  useEffect(() => {
    formik.setFieldValue('selectedTopicTags', selectedTopicTags);
    formik.setFieldValue('selectedCommunityTags', selectedCommunityTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopicTags, selectedCommunityTags]);

  const handleNameChange = useMemo(() => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
      setPostName(e.target.value);
    };
  }, [formik, setPostName]);

  const handleContentChange = useCallback(
    (value: string) => {
      formik.setFieldValue(
        'markdown',
        new DOMParser()
          .parseFromString(value, 'text/html')
          ?.body.textContent?.trim() ?? ''
      );
      setMarkdown(value);
    },
    [formik, setMarkdown]
  );

  const handleContentBlur = useCallback(
    (e: any) => {
      formik.handleBlur(e);
      formik.setFieldTouched('markdown', true);
    },
    [formik]
  );

  const handleTopicsBlur = useCallback(
    (e: any) => {
      formik.handleBlur(e);
      formik.setFieldTouched('selectedTopicTags', true);
    },
    [formik]
  );

  const handleCommunitiesBlur = useCallback(
    (e: any) => {
      formik.handleBlur(e);
      formik.setFieldTouched('selectedCommunityTags', true);
    },
    [formik]
  );

  const handleAddTopic = (elem: string) => {
    addTopic(elem);
    formik.setFieldValue('selectedTopicTags', [
      ...formik.values.selectedTopicTags,
      { name: elem, urlKey: elem }
    ]);
  };

  const handleRemoveTopic = (urlKey: string) => {
    removeTopic(urlKey);
    formik.setFieldValue(
      'selectedTopicTags',
      formik.values.selectedTopicTags.filter((tag) => tag.urlKey !== urlKey)
    );
  };

  const handleAddCommunity = (elem: string) => {
    addCommunity(elem);
    formik.setFieldValue('selectedCommunityTags', [
      ...formik.values.selectedCommunityTags,
      { name: elem, urlKey: elem }
    ]);
  };

  const handleRemoveCommunity = (urlKey: string) => {
    removeCommunity(urlKey);
    formik.setFieldValue(
      'selectedCommunityTags',
      formik.values.selectedCommunityTags.filter((tag) => tag.urlKey !== urlKey)
    );
  };

  return (
    <form
      className="w-full mx-auto filters"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
    >
      <main className="page-content max-w-2/3">
        <Flex direction="flex-row w-full">
          <Flex className="m-auto">
            <HeadingOne color="black">{texts.editPost}</HeadingOne>
            <div
              className="mb-0 text-xs text-black"
              dangerouslySetInnerHTML={{ __html: texts.editCommunityText }}
            ></div>
            {/* Title */}
            <HeadingTwo
              className={`font-black ${!isUserAdmin ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <RequiredFieldLabel label={texts.title} />
            </HeadingTwo>
            <InputLetterCount
              value={formik.values.postName}
              setValue={handleNameChange}
              onBlur={formik.handleBlur}
              maxLength={300}
              name="postName"
              placeholder={texts.postName}
              disabled={!isUserAdmin}
              error={
                formik.touched.postName && formik.errors.postName
                  ? formik.errors.postName
                  : undefined
              }
              touched={formik.touched.postName}
            />
            {/* Description */}
            <HeadingTwo
              className={`font-black ${!isUserAdmin && !isUserCreator ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <RequiredFieldLabel label={texts.text} />
            </HeadingTwo>
            <div onBlur={handleContentBlur}>
              <RichTextEditor
                value={initialDescription}
                setValue={handleContentChange}
                maxLength={40000}
                disabled={!isUserAdmin && !isUserCreator}
                error={
                  formik.touched.markdown && formik.errors.markdown
                    ? formik.errors.markdown
                    : undefined
                }
                touched={formik.touched.markdown}
              />
            </div>
            {/* Topics */}
            <HeadingTwo
              className={`font-black ${!isUserAdmin && !isUserCreator ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {texts.topicTags}
            </HeadingTwo>
            <TopicTagsInput
              topicTags={topicTags}
              selectedTopicTags={selectedTopicTags}
              onAddTopic={handleAddTopic}
              filterTopics={filterTopics}
              inputTopicsValue={inputTopicsValue}
              onRemoveTopic={handleRemoveTopic}
              explanationText={texts.postPagetopicTagsExplain}
              isUserAdmin={isUserAdmin}
              isUserCreator={isUserCreator}
              maxLimit={15}
              error={
                formik.touched.selectedTopicTags &&
                formik.errors.selectedTopicTags
                  ? (formik.errors.selectedTopicTags as string)
                  : undefined
              }
              touched={formik.touched.selectedTopicTags ? true : false}
              onBlur={handleTopicsBlur}
              fetchMoreTopics={fetchMoreTopics}
              hasMore={hasMoreTopicPage}
            />
            <HeadingTwo
              className={`font-black ${!isUserAdmin && !isUserCreator ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {texts.communityTags}
            </HeadingTwo>
            <CommunityTagsInput
              communityTags={communityTags}
              selectedCommunityTags={selectedCommunityTags}
              onAddCommunity={handleAddCommunity}
              onRemoveCommunity={handleRemoveCommunity}
              filterCommunities={filterCommunities}
              inputCommunityTagsValue={inputCommunityTags}
              explanationText={texts.postPageCommunityTagsExplain}
              isUserAdmin={isUserAdmin}
              isUserCreator={isUserCreator}
              maxLimit={10}
              error={
                formik.touched.selectedCommunityTags &&
                formik.errors.selectedCommunityTags
                  ? (formik.errors.selectedCommunityTags as string)
                  : undefined
              }
              touched={formik.touched.selectedCommunityTags ? true : false}
              onBlur={handleCommunitiesBlur}
              fetchMoreCommunities={fetchMoreCommunities}
              hasMore={hasMoreCommunityPage}
            />
            <Flex>
              {Object.keys(errors).map((error: string) => (
                <ErrorInfo key={error}>{error}</ErrorInfo> //errorMessages
              ))}
            </Flex>
            <div className="flex justify-end">
              <Button
                className="mt-4 bg-gradient-to-r from-primaryLight to-primaryDefault inline fitContent desktop:px-20 laptop:px-20 px-10 text-small uppercase py-1 font-medium"
                rounded="md"
                size="imd"
                type="submit"
                disabled={!isUserAdmin && !isUserCreator}
              >
                {texts.updatePostButton}
              </Button>
            </div>
          </Flex>
        </Flex>
      </main>
    </form>
  );
};
