import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { DeletedUserPostsList, useGetDeletedUserPostsByTopic } from '.';

export const DeletedUserPostsListTopic = () => {
  useGetDeletedUserPostsByTopic();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentDeletedUserPostsPage, setCurrentDeletedUserPostsPage] =
    useState(1);

  const updatePage = (newPage: number) => {
    setCurrentDeletedUserPostsPage(newPage);
    const newSearch = qs.stringify({ p: newPage }, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  return (
    <DeletedUserPostsList
      currentPage={currentDeletedUserPostsPage}
      updatePage={updatePage}
    />
  );
};
