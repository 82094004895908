import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ILoginResponse,
  ILoginUser,
  IRegisterRequest,
  IResetPasswordRequest,
  IForgotPasswordRequest
} from '../../types/auth.type';
import { AuthServiceInstance } from '../../api/services/auth';
import { env } from '../../env';

export const loginUser = createAsyncThunk<
  ILoginUser | null,
  { email: string; password: string },
  { rejectValue: string }
>('post/loginUser', async (credentials, { rejectWithValue }) => {
  try {
    const response = await fetch(`${env.apiUrl}/api/v1/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    });

    if (!response.ok) {
      let errorMessage = 'An error occurred during login.';
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (parseError) {}
      throw new Error(errorMessage);
    }

    const data: ILoginResponse = await response.json();

    AuthServiceInstance.setAuth(data);

    return data.user ?? null;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to login');
  }
});

export const registerUser = createAsyncThunk<
  void,
  IRegisterRequest,
  { rejectValue: string }
>('post/registerUser', async (registrationData, { rejectWithValue }) => {
  try {
    const response = await fetch(`${env.apiUrl}/api/v1/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(registrationData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage =
        errorData.message || 'An error occurred during registration.';
      throw new Error(errorMessage);
    }
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to register');
  }
});

export const forgotPassword = createAsyncThunk<
  void,
  IForgotPasswordRequest,
  { rejectValue: string }
>('post/forgotPassword', async (forgotData, { rejectWithValue }) => {
  try {
    const response = await fetch(`${env.apiUrl}/api/v1/auth/forgot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(forgotData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage =
        errorData.message || 'An error occurred during forgot password.';
      throw new Error(errorMessage);
    }
  } catch (error: any) {
    return rejectWithValue(
      error.message || 'Failed to initiate forgot password'
    );
  }
});

export const resetPassword = createAsyncThunk<
  void,
  IResetPasswordRequest,
  { rejectValue: string }
>('post/resetPassword', async (resetData, { rejectWithValue }) => {
  try {
    const response = await fetch(`${env.apiUrl}/api/v1/auth/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(resetData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage =
        errorData.message || 'An error occurred during reset password.';
      throw new Error(errorMessage);
    }
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to reset password');
  }
});

export const logoutUser = createAsyncThunk<void, void, { rejectValue: string }>(
  'auth/logoutUser',
  async (_, { rejectWithValue }) => {
    try {
      AuthServiceInstance.clearAuth();
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to logout');
    }
  }
);
