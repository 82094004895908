import { TopBar } from '../../components/core/topBars';
import { TermsAndConditionsPageContainer } from './terms-and-conditions-page-container';

interface Props {}

export const TermsAndConditionsPage: React.FC<Props> = () => {
  return (
    <TopBar>
      <TermsAndConditionsPageContainer></TermsAndConditionsPageContainer>
    </TopBar>
  );
};
