import { useState } from 'react';
import Ticker from 'react-ticker';
import { texts } from '../../text';

export const NewsTicker = () => {
  const [move, setMove] = useState(true);
  const newsItems = texts.newsTickerContent;

  const handleMouseEnter = () => {
    setMove(false);
  };

  const handleMouseLeave = () => {
    setMove(true);
  };

  return (
    <div
      className="tickerWrapp"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Ticker move={move} mode="chain">
        {() => (
          <div className="flex">
            <div
              className="flex"
              dangerouslySetInnerHTML={{ __html: newsItems }}
            />
          </div>
        )}
      </Ticker>
    </div>
  );
};
