import classNames from 'classnames';

interface Props {
  className?: string;
}

export const ArrowDown: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('block', className)}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path d="M7 10.2334H17L12 15.2334L7 10.2334Z" fill="black" />
    </svg>
  );
};
