import React, { useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from '../../state';
import { Loading } from '../../components/core';
import { FeedApi } from '../../api';
import { FeedResults } from './feed-results';
import { useMountEffect } from '../../hooks';
import { ApiPaperPreview } from '../../api/oipub-api';
import { clearSearch } from '../../state/slices/searchReducer';

export interface Props {}

export const Feed: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const [papers, setPapers] = useState<ApiPaperPreview[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useMountEffect(() => {
    dispatch(clearSearch());
  });

  const fetchRecentActivity = useCallback(async () => {
    try {
      const papers = await FeedApi.getRecentActivity();
      setPapers(papers);
    } catch (err) {
      setError('Failed to fetch recent activity.');
    }
  }, []);

  useEffect(() => {
    fetchRecentActivity();
  }, [fetchRecentActivity]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (papers === null) {
    return <Loading />;
  }

  return (
    <>
      <main className="page">
        <h1>Recent Activity</h1>
        <FeedResults papers={papers} />
      </main>
    </>
  );
};
