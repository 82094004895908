import classNames from 'classnames';

interface Props {
  className?: string;
}

export const ToggleUnChecked: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      width="28"
      height="14"
      viewBox="0 0 28 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33341 0.333496H20.6667C24.3467 0.333496 27.3334 3.32016 27.3334 7.00016C27.3334 10.6802 24.3467 13.6668 20.6667 13.6668H7.33341C3.65341 13.6668 0.666748 10.6802 0.666748 7.00016C0.666748 3.32016 3.65341 0.333496 7.33341 0.333496ZM3.33341 7.00016C3.33341 9.2135 5.12008 11.0002 7.33341 11.0002C9.54675 11.0002 11.3334 9.2135 11.3334 7.00016C11.3334 4.78683 9.54675 3.00016 7.33341 3.00016C5.12008 3.00016 3.33341 4.78683 3.33341 7.00016Z"
        fill="#EE9844"
      />
    </svg>
  );
};
