import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserPostPaged } from '../../types/userPosts.type';
import { env } from '../../env';
import { sendProtectedRequest } from './sendProtectedRequest';

// Fetch deleted posts for a specific community
export const getDeletedPostsByCommunity = createAsyncThunk<
  IUserPostPaged,
  { key: string; order?: string }
>(
  'userPosts/getDeletedPostsByCommunity',
  async ({ key, order }, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/communities/${key}/posts-deleted${order ?? 'order=' + order};`;

    try {
      const response = await sendProtectedRequest(url, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch deleted posts for the community.');
      }

      const data: IUserPostPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch deleted posts for the community.'
      );
    }
  }
);

// Fetch deleted posts for a specific topic
export const getDeletedPostsByTopic = createAsyncThunk<
  IUserPostPaged,
  { key: string; order?: string }
>(
  'userPosts/getDeletedPostsByTopic',
  async ({ key, order }, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/topics/${key}/posts-deleted${order ?? 'order=' + order};`;

    try {
      const response = await sendProtectedRequest(url, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch deleted posts for the topic.');
      }

      const data: IUserPostPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch deleted posts for the topic.');
    }
  }
);

export const getUserPostsByTopicUrlKey = createAsyncThunk<
  IUserPostPaged,
  { page: number; pageSize: number; urlKey: string }
>(
  'get/getUserPostsByTopicUrlKey',
  async ({ page, pageSize, urlKey }, { rejectWithValue, signal }) => {
    const query = `p=${page}&s=${pageSize}`;
    const url = `${env.apiUrl}/api/v1/topics/${urlKey}/posts?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user posts for the topic URL key.');
      }

      const data: IUserPostPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch user posts for the topic URL key.'
      );
    }
  }
);

export const getUserPostsByCommunityUrlKey = createAsyncThunk<
  IUserPostPaged,
  { page: number; pageSize: number; urlKey: string }
>(
  'get/getUserPostsByCommunityUrlKey',
  async ({ page, pageSize, urlKey }, { rejectWithValue, signal }) => {
    const query = `p=${page}&s=${pageSize}`;
    const url = `${env.apiUrl}/api/v1/communities/${urlKey}/posts?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        throw new Error(
          'Failed to fetch user posts for the community URL key.'
        );
      }

      const data: IUserPostPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch user posts for the community URL key.'
      );
    }
  }
);
