import { useEffect } from 'react';
import {
  deleteComment,
  editCommentPapers,
  getComments,
  postCommentPaper
} from '../../../state/actions/commentsActions';
import { useAppDispatch, useAppSelector } from '../../../state';
import { useParams } from 'react-router-dom';
import { clearComments } from '../../../state/slices/commentsReducer';

export function useCommentsPaper() {
  const dispatch = useAppDispatch();
  const { id: paperId } = useParams<{ id: string }>();
  const paper = useAppSelector((state) => state.paper.paper);
  const commentsSortMethod = useAppSelector(
    (state) => state.sort.commentSortMethod
  );

  const discussionId = Number(paper?.discussionId);

  useEffect(() => {
    if (discussionId) {
      dispatch(
        getComments({
          discussionId: discussionId!,
          order: commentsSortMethod
        })
      );
    }
    return () => {
      dispatch(clearComments());
    };
  }, [dispatch, commentsSortMethod, discussionId]);

  const handleReply = async (text: string, parentId: number | null) => {
    if (paper && discussionId) {
      await dispatch(
        postCommentPaper({
          papersId: Number(paperId),
          body: text,
          parentCommentId: parentId
        })
      );
      dispatch(
        getComments({
          discussionId: discussionId!,
          order: commentsSortMethod
        })
      );
    } else if (paperId) {
      await dispatch(
        postCommentPaper({
          papersId: Number(paperId),
          body: text,
          parentCommentId: parentId
        })
      );
    }
  };

  const handleEdit = async (
    paperId: number,
    commentId: number,
    text: string
  ) => {
    await dispatch(editCommentPapers({ paperId, commentId, body: text }));
    // refresh comments list after editing
    discussionId &&
      dispatch(
        getComments({
          discussionId: discussionId,
          order: commentsSortMethod
        })
      );
  };

  const handleDelete = async (commentId: number) => {
    await dispatch(deleteComment({ commentId }));
    // refresh comments list after deletion
    discussionId &&
      dispatch(
        getComments({
          discussionId: discussionId,
          order: commentsSortMethod
        })
      );
  };

  return { handleReply, handleEdit, handleDelete };
}
