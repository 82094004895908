import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import { NavigateFunction } from 'react-router-dom';
import { ISearchType } from '../../types/search.type';
import { AppState } from '../store';
import { createLink } from '../../utils/helperFunctions';
import { searchTopics } from './topicsActions';
import { searchCommunities } from './communitiesActions';
import { searchPapers } from './papersActions';
import { abortRequestForNew, setQuery } from '../slices/searchReducer';

export const getSearchUrl = (term: string, type: ISearchType, page: number) => {
  const params = qs.stringify({
    q: term,
    p: !page || page <= 1 ? undefined : page
  });
  return createLink(type + `/search?${params}`);
};

let currentAbortController: AbortController | null = null;

export const search = createAsyncThunk<
  void,
  {
    term: string;
    pageNum: number;
    pageSize?: number;
    searchType: ISearchType;
  },
  { rejectValue: string; state: AppState }
>(
  'get/fetchSearchResults',
  async ({ term, pageNum, pageSize, searchType }, { dispatch }) => {
    // Abort the previous request if a new one starts
    if (currentAbortController) {
      dispatch(abortRequestForNew());
      currentAbortController.abort();
    }

    // Create a new AbortController for the new request
    currentAbortController = new AbortController();

    // Set query and clear previous results
    dispatch(setQuery({ term, pageNum, searchType }));

    try {
      if (searchType === ISearchType.Topics) {
        await dispatch(
          searchTopics({
            term,
            page: pageNum,
            pageSize: pageSize!,
            abortController: currentAbortController
          })
        ).unwrap();
      } else if (searchType === ISearchType.Communities) {
        await dispatch(
          searchCommunities({
            term,
            page: pageNum,
            pageSize: pageSize!,
            abortController: currentAbortController
          })
        ).unwrap();
      } else if (searchType === ISearchType.Papers) {
        await dispatch(
          searchPapers({
            term,
            page: pageNum,
            abortController: currentAbortController
          })
        ).unwrap();
      } else {
        throw new Error('Invalid search type');
      }
    } catch (error) {
      throw error;
    } finally {
      // Clear the current AbortController after the request completes or is aborted
      currentAbortController = null;
    }
  }
);

export const changePage =
  (
    query: string,
    newPage: number,
    pathname: string,
    navigate: NavigateFunction
  ) =>
  async () => {
    try {
      const queryString = qs.stringify({
        q: query,
        p: !newPage || newPage <= 1 ? undefined : newPage
      });

      navigate({
        pathname: pathname,
        search: `?${queryString}`
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error changing page:', error);
    }
  };
