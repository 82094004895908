import { FC } from 'react';
import { IPaperReference } from '../../../types/paper.type';
import { CitesCitedItem } from './citesCitedByItem';

interface CitesListProps {
  references?: IPaperReference[];
}

export const CitesList: FC<CitesListProps> = ({ references }) => {
  return <CitesCitedItem items={references || []} />;
};
