import { ApiLibraryVersion } from '../../api';
import { ApiDatabaseCounts } from '../../api/oipub-api';
import { LibraryActions } from '../actions/libraryActions';

export const initialState: {
  counts: ApiDatabaseCounts | null;
  versionHistory: ApiLibraryVersion[] | null;
} = {
  counts: null,
  versionHistory: null
};

export type LibraryState = typeof initialState;

export function libraryReducer(
  state: LibraryState = initialState,
  action: LibraryActions
): LibraryState {
  switch (action.type) {
    case 'LIB_SET_COUNTS':
      return { ...state, counts: action.counts };
    case 'LIB_SET_VERSION_HISTORY':
      return { ...state, versionHistory: action.history };
    default:
      return state;
  }
}
