import { Tooltip } from '../core/tooltip';
import { Button } from '../form';

interface SortingButtonProps<T> {
  methodValue: T;
  currentSortMethod: T;
  onSortMethodChange: (method: T) => void;
  displayNames: Record<string, { label: string; tooltip?: string }>;
  tooltip?: string;
  toggleValues?: [T, T];
}

export const SortingButton = <T extends string>({
  methodValue,
  currentSortMethod,
  onSortMethodChange,
  displayNames,
  tooltip,
  toggleValues
}: SortingButtonProps<T>) => {
  const handleClick = () => {
    if (toggleValues) {
      const nextMethod =
        currentSortMethod === toggleValues[0]
          ? toggleValues[1]
          : toggleValues[0];
      onSortMethodChange(nextMethod);
    } else {
      onSortMethodChange(methodValue);
    }
  };

  const isActive =
    toggleValues &&
    (methodValue === toggleValues[0] || methodValue === toggleValues[1])
      ? currentSortMethod === toggleValues[0] ||
        currentSortMethod === toggleValues[1]
      : currentSortMethod === methodValue;

  const label =
    toggleValues && currentSortMethod === toggleValues[0]
      ? displayNames[toggleValues[0]].label
      : toggleValues && currentSortMethod === toggleValues[1]
        ? displayNames[toggleValues[1]].label
        : displayNames[methodValue]?.label || methodValue;

  const activeTooltip =
    toggleValues && currentSortMethod === toggleValues[0]
      ? displayNames[toggleValues[0]].tooltip
      : toggleValues && currentSortMethod === toggleValues[1]
        ? displayNames[toggleValues[1]].tooltip
        : tooltip;

  return activeTooltip ? (
    <Tooltip text={activeTooltip} className="tooltipSortingBtn">
      <Button
        onClick={handleClick}
        outline={!isActive}
        className="py-2 mr-1 ml-1 mb-2 sortingBtn text-small"
        disabled={methodValue.endsWith('_disabled')}
      >
        {label}
      </Button>
    </Tooltip>
  ) : (
    <Button
      onClick={handleClick}
      outline={!isActive}
      className="py-2 mr-1 ml-1 mb-2 sortingBtn text-small"
      disabled={methodValue.endsWith('_disabled')}
    >
      {label}
    </Button>
  );
};
