import axios from 'axios';
import { env } from '../../env';
import { ApiPaperPreview } from '../oipub-api';

const baseUrl = `${env.apiUrl}/api`;

export const FeedApi = {
  async getRecentActivity(): Promise<ApiPaperPreview[]> {
    const url = `${baseUrl}/v1/feed/recent-activity`;
    const response = await axios.get<ApiPaperPreview[]>(url);
    return response.data;
  }
};
