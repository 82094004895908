import React from 'react';

interface StaticTimeAgoProps {
  date: string | Date;
}

export const StaticTimeAgo: React.FC<StaticTimeAgoProps> = ({ date }) => {
  const getTimeDifference = (date: Date) => {
    const now = new Date();
    const createdDate = new Date(date);
    const deltaSeconds = Math.floor(
      (now.getTime() - createdDate.getTime()) / 1000
    );
    const deltaMinutes = Math.floor(deltaSeconds / 60);
    const deltaHours = Math.floor(deltaMinutes / 60);
    const deltaDays = Math.floor(deltaHours / 24);
    const deltaMonths =
      now.getMonth() -
      createdDate.getMonth() +
      12 * (now.getFullYear() - createdDate.getFullYear());
    const deltaYears = now.getFullYear() - createdDate.getFullYear();

    return {
      deltaSeconds,
      deltaMinutes,
      deltaHours,
      deltaDays,
      deltaMonths,
      deltaYears
    };
  };

  const getTimeAgoMessage = (date: Date) => {
    const {
      deltaSeconds,
      deltaMinutes,
      deltaHours,
      deltaDays,
      deltaMonths,
      deltaYears
    } = getTimeDifference(date);

    if (deltaSeconds < 60) {
      return 'just now';
    } else if (deltaMinutes < 60) {
      return `${deltaMinutes}m ago`;
    } else if (deltaHours < 24) {
      return `${deltaHours}h ago`;
    } else if (deltaDays === 1) {
      return 'yesterday';
    } else if (deltaDays < 30) {
      return `${deltaDays} days ago`;
    } else if (deltaMonths < 12) {
      return `${deltaMonths} months ago`;
    } else {
      return `${deltaYears} years ago`;
    }
  };

  return <span className="">{getTimeAgoMessage(new Date(date))}</span>;
};
