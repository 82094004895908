import classNames from 'classnames';
import { textSizes } from './textSizes';

interface Props {
  className?: string;
  error?: string;
  size?: string;
  children: React.ReactNode;
}

const ErrorInfo: React.FC<Props> = ({
  error = '',
  className = '',
  size = 'base'
}: Props) => {
  const errorClass = classNames(
    'tw-text-white',
    `${textSizes[size as keyof typeof textSizes]}`,
    'tw-bg-error',
    'tw-p-2',
    'tw-my-2',
    'tw-leading-tight',
    'tw-rounded-2xl',
    className
  );

  return <p className={errorClass}>{error}</p>;
};

export default ErrorInfo;
