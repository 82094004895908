import React from 'react';
import { Link } from 'react-router-dom';
import { LandingTopic, staticTopics } from './';
import { useAppSelector } from '../state';
import { selectLandingTopics } from '../state/selectors/topicsSelector';
import { ITopicSummary } from '../types/topic.type';

export const LandingTopicsList: React.FC<{ isStatic: boolean }> = ({
  isStatic = true
}) => {
  const topics = useAppSelector(selectLandingTopics);

  const displayTopics = isStatic ? staticTopics : topics?.page;

  return (
    <div id="topic-list">
      {displayTopics?.map(
        (topic: ITopicSummary | LandingTopic, index: number) => (
          <Link
            key={index}
            to={
              'link' in topic
                ? (topic as LandingTopic).link
                : `/topic/${topic.urlKey}`
            }
            className="btn btn--fit"
          >
            {topic.name}
          </Link>
        )
      )}
    </div>
  );
};
