import classNames from 'classnames';

interface Props {
  className?: string;
}

export const LinkCopy: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      className={classNames('block  h-full', className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="copy_24px">
        <path
          id="icon/content/copy_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.6875 0.625H2.8125C2.125 0.625 1.5625 1.1875 1.5625 1.875V10C1.5625 10.3438 1.84375 10.625 2.1875 10.625C2.53125 10.625 2.8125 10.3438 2.8125 10V2.5C2.8125 2.15625 3.09375 1.875 3.4375 1.875H9.6875C10.0312 1.875 10.3125 1.59375 10.3125 1.25C10.3125 0.90625 10.0312 0.625 9.6875 0.625ZM12.1875 3.125H5.3125C4.625 3.125 4.0625 3.6875 4.0625 4.375V13.125C4.0625 13.8125 4.625 14.375 5.3125 14.375H12.1875C12.875 14.375 13.4375 13.8125 13.4375 13.125V4.375C13.4375 3.6875 12.875 3.125 12.1875 3.125ZM5.9375 13.125H11.5625C11.9062 13.125 12.1875 12.8438 12.1875 12.5V5C12.1875 4.65625 11.9062 4.375 11.5625 4.375H5.9375C5.59375 4.375 5.3125 4.65625 5.3125 5V12.5C5.3125 12.8438 5.59375 13.125 5.9375 13.125Z"
          fill="#1A202C"
        />
      </g>
    </svg>
  );
};
