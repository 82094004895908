import React from 'react';
import { ToggleChecked } from '../../svgs/ToggleChecked';
import { ToggleUnChecked } from '../../svgs/ToggleUnChecked';
import { useAppDispatch, useAppSelector } from '../../state';
import { toggleAdminSidebar } from '../../state/actions/modalsActions';
import { ArrowLeft, ArrowRight } from '../../svgs';

interface AdminSidebarProps {
  showDeletedPosts: boolean;
  setShowDeletedPosts: (value: boolean) => void;
}

export const AdminSidebar: React.FC<AdminSidebarProps> = ({
  showDeletedPosts,
  setShowDeletedPosts
}) => {
  const dispatch = useAppDispatch();

  const isSidebarOpen = useAppSelector(
    (state) => state.modal.adminSidebar.isOpen
  );

  const handleToggleClick = () => {
    dispatch(toggleAdminSidebar());
  };

  return (
    <div className="admin-sidebar-container relative">
      <div className={`admin-sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
        <div className="admin-sidebar-content p-4">
          <div className="mb-4 text-md">Admin Sidebar</div>
          <div
            onClick={() => setShowDeletedPosts(!showDeletedPosts)}
            className="cursor-pointer flex items-center space-x-2"
          >
            {showDeletedPosts ? <ToggleChecked /> : <ToggleUnChecked />}
            <span className="text-ssm">Show Only Deleted Posts</span>
          </div>
        </div>
        <button className="toggle-sidebar-btn" onClick={handleToggleClick}>
          {isSidebarOpen ? (
            <ArrowRight className="w-4 h-4" />
          ) : (
            <ArrowLeft className="w-4 h-4" />
          )}
        </button>
      </div>
    </div>
  );
};
