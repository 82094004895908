import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useAppSelector } from '../../state';
import { CommunitySummaryV2 } from '../topicsAndCommunities/community-summary';
import { SearchPagination } from '../search/search-pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import qs from 'qs';
import { Loading } from '../../components/core';
import { useGetCommunitiesData } from './hooks/useGetCommunitiesData';
import { ErrorComponent } from '../../components/core/error';
import { useQueryParams } from '../../hooks/useQueryParams';

interface CommunitiesContentProps {}

export const CommunitiesList: React.FC<CommunitiesContentProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const communitySortMethod = useAppSelector(
    (state) => state.sort.communitySortMethod
  );

  const queryParams = useQueryParams();
  const initialPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
  const [currentCommunityPage, setCurrentCommunityPage] = useState(initialPage);

  const { communities, loading, error } = useGetCommunitiesData({
    page: currentCommunityPage,
    pageSize: 10,
    communityType: 1,
    sortMethod: communitySortMethod
  });
  const viewMode = useAppSelector((state) => state.viewmode.mode);
  const isTripleView = viewMode === 'triple';

  const updatePage = (newPage: number) => {
    setCurrentCommunityPage(newPage);
    const newSearch = qs.stringify({ p: newPage }, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  useEffect(() => {
    const newPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
    setCurrentCommunityPage(newPage);
  }, [location.search, queryParams]);

  if (loading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <ErrorComponent error={error}></ErrorComponent>;
  }

  if (communities && communities.page?.length === 0) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <p>No communities yet.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex flex-col items-center h-full w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <div className="flex flex-wrap w-full w-full-for-all">
            {communities && communities.page && communities.page.length > 0 && (
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  350: 1,
                  600: isTripleView ? 3 : 1,
                  900: isTripleView ? 3 : 1
                }}
              >
                <Masonry gutter="14px">
                  {communities.page.map((community, idx) => (
                    <CommunitySummaryV2 key={idx} community={community} />
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            )}
          </div>
        </div>
      </div>
      {communities && communities.numPages && currentCommunityPage && (
        <div className="max-w-3xl mx-auto mt-8 w-full">
          <SearchPagination
            currentPage={currentCommunityPage}
            lastPage={communities.numPages}
            onPageChange={updatePage}
          />
        </div>
      )}
    </div>
  );
};

export default CommunitiesList;
