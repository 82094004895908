import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { closeModal, openModal } from '../../../state/actions/modalsActions';
import { useAppDispatch } from '../../../state';

interface ModalMap {
  [key: string]: string;
}

export const useModalHash = (modalMap: ModalMap) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const hash = location.hash.replace('#', '').toLowerCase();
    Object.keys(modalMap).forEach((modalName) => {
      if (hash === modalName.toLowerCase()) {
        dispatch(openModal(modalMap[modalName]));
      } else {
        dispatch(closeModal(modalMap[modalName]));
      }
    });
  }, [location.hash, dispatch, modalMap]);

  const closeModalAndRemoveHash = (modalName: string) => {
    dispatch(closeModal(modalName));
    const currentSearch = location.search;
    navigate(`${location.pathname}${currentSearch}`, { replace: true });
  };

  return { closeModalAndRemoveHash };
};
