import { Spinner } from '../../svgs';

export interface Props {
  text?: string;
  className?: string; // Add className as a prop
}

export const Loading: React.FC<Props> = ({ text, className }: Props) => {
  return (
    <div
      className={`flex flex-col items-center h-auto w-full py-3 mb-2 px-4 relative ${className}`}
    >
      <div className="flex w-full max-w-3xl">
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div className="flex items-center">
            <div className="animate-spin w-6 h-6 text-gray-600 transition-transform duration-700">
              <Spinner />
            </div>
            {text && (
              <span className="ml-2 text-sm text-gray-500"> {text}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
