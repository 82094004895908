import { FC, useState, useCallback } from 'react';
import { IPaperCitations, IPaperReference } from '../../../types/paper.type';
import { useAppDispatch } from '../../../state';
import { setCitesCitedByState } from '../../../state/slices/tabsReducer';
import { CitesList } from './citesList';
import { CitedByList } from './citedByList';
import { CitesCitedByViewToggle } from '../../../components/viewModeToggle/viewModeToggleCitesCitedBy';
import { formatCount } from '../../../components/papersDiscussionRadio/hooks/usePaperCount';

interface CitesCitedByTabsProps {
  references?: IPaperReference[] | null;
  citations?: IPaperCitations[] | null;
}

/**
 * @param {CitesCitedByTabsProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
export const CitesCitedByTabs: FC<CitesCitedByTabsProps> = ({
  references = [],
  citations = []
}) => {
  const [activeTab, setActiveTab] = useState<'cites' | 'citedBy'>('cites');
  const dispatch = useAppDispatch();
  const handleTabClick = useCallback(
    (tab: 'cites' | 'citedBy') => {
      setActiveTab(tab);
      dispatch(setCitesCitedByState(tab));
    },
    [dispatch]
  );

  const referencesLenght = formatCount(references?.length);
  const citationsLenght = formatCount(citations?.length);

  return (
    <div className="cites-citedby-tabs-container ">
      <div className="flex border-b border-gray-200 tabs mb-2 tabsCitesCitedBy">
        <button
          className={`py-4 px-6 text-sm font-medium ${
            activeTab === 'cites' ? 'active' : ''
          }`}
          onClick={() => handleTabClick('cites')}
        >
          Cites{' '}
          {referencesLenght?.length !== 0 ? (
            <span>({referencesLenght})</span>
          ) : null}
        </button>
        <button
          className={`py-4 px-6 text-sm font-medium ${
            activeTab === 'citedBy' ? 'active' : ''
          }`}
          onClick={() => handleTabClick('citedBy')}
        >
          Cited By{' '}
          {citations?.length !== 0 ? <span>({citationsLenght})</span> : null}
        </button>
      </div>

      <div className="">
        {(activeTab === 'cites' && references && references.length > 0) ||
        (activeTab === 'citedBy' && citations && citations.length > 0) ? (
          <CitesCitedByViewToggle />
        ) : (
          ''
        )}
        <div className="pt-3">
          {activeTab === 'cites' && <CitesList references={references!} />}
          {activeTab === 'citedBy' && <CitedByList citations={citations!} />}
        </div>
      </div>
    </div>
  );
};
