import React from 'react';

interface RequiredFieldLabelProps {
  label: string;
}

export const RequiredFieldLabel: React.FC<RequiredFieldLabelProps> = ({
  label
}) => {
  return (
    <span>
      {label} <span style={{ color: 'red' }}>*</span>
    </span>
  );
};
