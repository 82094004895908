import React from 'react';
import { Flex, HeadingOne } from '../../atoms';

interface TopicProps {
  topicName: string | null | undefined;
}

const TopicTitlePlace: React.FC<TopicProps> = ({ topicName }) => {
  return (
    <main className="page-content pt-10 pb-8 px-4">
      <div className="max-w-3xl m-auto">
        <Flex direction="flex-row w-full">
          <Flex>
            <HeadingOne color="black" className="mb-0">
              {topicName}
            </HeadingOne>
            <div className="text-primary-500 text-sm">#T[{topicName}]</div>
          </Flex>
        </Flex>
      </div>
    </main>
  );
};

export default TopicTitlePlace;
