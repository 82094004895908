import { FC } from 'react';
import { Discussion } from '.';
import { Loading } from '../core';
import { useCommentsUserPost } from './hooks/useCommentsUserPost';
import { useAppSelector } from '../../state';
import {
  selectComments,
  selectCommentsLoading
} from '../../state/selectors/commentsSelector';

export const DiscussionContainer: FC = () => {
  const { handleReply, handleEdit, handleDelete } = useCommentsUserPost();
  const comments = useAppSelector(selectComments)?.comments;
  const loading = useAppSelector(selectCommentsLoading);

  if (loading) {
    return <Loading />;
  }

  return (
    <Discussion
      type={'post'}
      comments={comments || null}
      onReply={handleReply}
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  );
};
