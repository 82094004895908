import React from 'react';
import { fetchTopicPapersData, useAppDispatch } from '../../state';
import {
  resetTopicPaperFilterData,
  setTopicPaperFilterData
} from '../../state/slices/filterPapersReducer';
import { PapersMainFilter } from '.';
import { useParams } from 'react-router-dom';

export const PapersTopicFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();

  return (
    <PapersMainFilter
      urlKey={urlKey!}
      setFilterData={(filterData) =>
        dispatch(setTopicPaperFilterData(filterData))
      }
      fetchData={(filterData) =>
        dispatch(fetchTopicPapersData({ ...filterData, topicUrlKey: urlKey }))
      }
      resetFilterData={() => dispatch(resetTopicPaperFilterData())}
    />
  );
};
