import { env } from '../env';
import { ICommunitySummary } from '../types/community.type';

export interface LandingCommunity {
  name: string;
  link: string;
  urlKey?: string;
  updatedName?: string;
}

export type CommunityType = ICommunitySummary | LandingCommunity;

export interface LandingTopic {
  name: string;
  link: string;
  urlKey?: string;
}

export interface ListComponentProps {
  isStatic?: boolean;
  staticData?: (LandingCommunity | LandingTopic)[];
}

const mainUrl = env.apiUrl;

export const staticCommunities = [
  {
    name: 'Climate Change Modelling',
    link: mainUrl + '/community/climate-change-modelling-community',
    urlKey: 'climate-change-modelling-community'
  },
  {
    name: 'Artificial Intelligence and Machine Learning Community',
    link:
      mainUrl +
      '/community/artificial-intelligence-and-machine-learning-community',
    urlKey: 'artificial-intelligence-and-machine-learning-community'
  },
  {
    name: 'Batteries & Energy Storage Systems',
    link: mainUrl + '/community/batteries-energy-storage-systems-community',
    urlKey: 'batteries-energy-storage-systems-community'
  },
  {
    name: 'Computing and Big Data in Scientific Research Community',
    link:
      mainUrl +
      '/community/computing-and-big-data-in-scientific-research-community',
    urlKey: 'computing-and-big-data-in-scientific-research-community'
  },
  {
    name: 'Qalypso 2024 Precision in Quantum Information and Computation Community',
    link:
      mainUrl +
      '/community/qalypso-2024-precision-in-quantum-information-and-computation-community',
    urlKey:
      'qalypso-2024-precision-in-quantum-information-and-computation-community'
  },
  {
    name: 'Paediatric Infectious Diseases Community',
    link: mainUrl + '/community/paediatric-infectious-diseases-community',
    urlKey: 'paediatric-infectious-diseases-community'
  },
  {
    name: 'Data Visualisation and Analytics Community',
    link: mainUrl + '/community/data-visualisation-and-analytics-community',
    urlKey: 'data-visualisation-and-analytics-community'
  },
  {
    name: 'Anaesthesia & Anaesthetics',
    link: mainUrl + '/community/anaesthesia-anaesthetics-community',
    urlKey: 'anaesthesia-anaesthetics-community'
  },
  {
    name: 'Finite Element Methods',
    link: mainUrl + '/community/finite-element-methods-community',
    urlKey: 'finite-element-methods-community'
  },

  {
    name: 'Mathematical Biology',
    link: mainUrl + '/community/mathematical-biology-community',
    urlKey: 'mathematical-biology-community'
  },
  {
    name: 'Mediterranean Archaeology',
    link: mainUrl + '/community/mediterranean-archaeology-community',
    urlKey: 'mediterranean-archaeology-community'
  },
  {
    name: 'Quantum Information',
    link: mainUrl + '/community/quantum-information-community',
    urlKey: 'quantum-information-community'
  }
];

export const staticTopics = [
  { name: 'crispr', link: mainUrl + '/topic/crispr', urlKey: 'crispr' },
  {
    name: 'gut microbiota',
    link: mainUrl + '/topic/gut-microbiota',
    urlKey: 'gut-microbiota'
  },
  {
    name: 'gravitational waves',
    link: mainUrl + '/topic/gravitational-waves',
    urlKey: 'gravitational-waves'
  },
  {
    name: 'dark matter',
    link: mainUrl + '/topic/dark-matter',
    urlKey: 'dark-matter'
  },
  {
    name: 'epigenetic',
    link: mainUrl + '/topic/epigenetic',
    urlKey: 'epigenetic'
  },
  {
    name: 'chromophore',
    link: mainUrl + '/topic/chromophore',
    urlKey: 'chromophore'
  },
  {
    name: 'dna sequencing',
    link: mainUrl + '/topic/dna-sequencing',
    urlKey: 'dna-sequencing'
  },
  {
    name: 'cybersecurity',
    link: mainUrl + '/topic/cybersecurity',
    urlKey: 'cybersecurity'
  },
  {
    name: 'mangroves',
    link: mainUrl + '/topic/mangroves',
    urlKey: 'mangroves'
  },
  {
    name: 'quantum computing',
    link: mainUrl + '/topic/quantum-computing',
    urlKey: 'quantum-computing'
  },
  {
    name: 'quasicrystals',
    link: mainUrl + '/topic/quasicrystals',
    urlKey: 'quasicrystals'
  },
  {
    name: 'quantum dots',
    link: mainUrl + '/topic/quantum-dots',
    urlKey: 'quantum-dots'
  },
  {
    name: 'finite element analysis',
    link: mainUrl + '/topic/finite-element-analysis',
    urlKey: 'finite-element-analysis'
  },
  {
    name: 'big data',
    link: mainUrl + '/topic/big-data',
    urlKey: 'big-data'
  }
];

export const SURVEY_FORM_LINK = 'https://forms.gle/o9xvhGsaiGaJVVVg9';
