import { Accordion, accordionItems } from '../../components/accordion';

interface Props {}

export const ContactPageContainer: React.FC<Props> = () => {
  return (
    <div className="">
      <main className="page-content tablet:mt-20 tablet:px-4 w-full max-w-3xl px-2 pb-24 mx-auto mt-10">
        <h1>Contact</h1>
        <p>
          Are you looking to get in touch? You&apos;ve come to the right place.
        </p>
        <p>
          We are always happy to hear from our visitors. If you have feedback,
          queries, or just want to discuss our vision for Open Science, please
          get get in touch!
        </p>
        <p>
          <a href="mailto:info@oipub.com">Send us an email on info@oipub.com</a>
        </p>
        <p>
          Please include your name and any organizations you represent in your
          email.
        </p>
      </main>
      <div className="page-content max-w-3xl mx-auto pb-16 tablet:px-4 px-2">
        <h1 className="mb-4">FAQ</h1>
        <div className="">
          <Accordion items={accordionItems} />
        </div>
      </div>
    </div>
  );
};
