import axios from 'axios';
import { env } from '../../env';

import { ApiIota } from '..';
import {
  ApiPaperPaged,
  ApiUserPostPaged,
  ApiTopic,
  ApiDiscussionsPaged
} from '../oipub-api';
import oipubApi from '../api-instance';

const baseUrl = `${env.apiUrl}/api/v1/topics`;

export const TopicApi = {
  async getTopic(urlKey: string): Promise<ApiTopic> {
    const response = await oipubApi.v1TopicsDetail(urlKey);
    return response.data;
  },

  async getAllPaperByTopicUrlKey(
    page: number,
    pageSize: number,
    urlKey: string,
    sortMethod?: string
  ): Promise<ApiPaperPaged> {
    const response = await oipubApi.v1TopicsPapersDetail(urlKey, {
      p: page,
      s: pageSize,
      order: sortMethod
    });
    return response.data;
  },

  async getAllPostsByTopicUrlKey(
    page: number,
    pageSize: number,
    urlKey: string
  ): Promise<ApiUserPostPaged> {
    const response = await oipubApi.v1TopicsPostsDetail(urlKey, {
      p: page,
      s: pageSize
    });
    return response.data;
  },

  async getAllDiscussionsByTopicUrlKey(
    page: number,
    pageSize: number,
    urlKey: string,
    sortMethod?: string
  ): Promise<ApiDiscussionsPaged> {
    const response = await oipubApi.v1TopicsDiscussionsDetail(urlKey, {
      p: page,
      s: pageSize,
      order: sortMethod
    });
    return response.data;
  },

  async getPapers(name: string, page: number): Promise<ApiPaperPaged> {
    const response = await oipubApi.v1TopicsPapersDetail(name, { p: page });
    return response.data;
  },

  async createTopic(name: string): Promise<void> {
    const response = await oipubApi.v1TopicsCreate({ name });
    return response.data;
  },

  async deleteTopic(name: string): Promise<void> {
    const url = `${baseUrl}/${name}`;
    const response = await axios.delete<void>(url);
    return response.data;
  },

  async getPosts(name: string): Promise<ApiUserPostPaged> {
    const response = await oipubApi.v1TopicsPostsDetail(name);
    return response.data;
  },

  async getIotas(name: string): Promise<ApiIota[]> {
    const url = `${baseUrl}/${name}/iotas`;
    const response = await axios.get<ApiIota[]>(url);
    return response.data;
  },

  async postIota(name: string, iota: string): Promise<void> {
    const url = `${baseUrl}/${name}/iotas`;
    const request = { iota };
    const response = await axios.post<void>(url, request);
    return response.data;
  },

  async deleteIota(name: string, iota: string): Promise<void> {
    const url = `${baseUrl}/${name}/iotas/${iota}`;
    const response = await axios.delete<void>(url);
    return response.data;
  }
};
