import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';

export interface Props {
  className?: string;
  autoComplete?: 'on' | 'off';
  disabled?: boolean;
  id?: string;
  maxRows?: number;
  minRows?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  value?: string;
  name?: string;
}

export const Textarea: React.FC<Props> = (props: Props) => {
  return (
    <TextareaAutosize
      className={classNames(
        'w-full rounded px-3 py-2 placeholder-gray-400 duration-200 border-grayBorder focus:placeholder-gray-300  transition-colors outline-none border resize-none',
        props.className
      )}
      autoComplete={props.autoComplete}
      disabled={props.disabled}
      id={props.id}
      maxRows={props.maxRows}
      minRows={props.minRows}
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value}
      name={props.name}
    />
  );
};
