import classNames from 'classnames';

export interface Props {
  className?: string;
  children: React.ReactNode;
}

export const Field: React.FC<Props> = ({ className, children }: Props) => {
  return (
    <div className={classNames('w-full max-w-lg mb-5', className)}>
      {children}
    </div>
  );
};
