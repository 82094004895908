import classNames from 'classnames';

interface Props {
  className?: string;
}

export const TermsMenuLink: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      className={classNames('block', className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="13" r="10" fill="#737373" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2 7H9.15C8.50375 7 7.975 7.52875 7.975 8.175V15.225C7.975 15.8712 8.50375 16.4 9.15 16.4H16.2C16.8462 16.4 17.375 15.8712 17.375 15.225V8.175C17.375 7.52875 16.8462 7 16.2 7ZM6.2125 9.35C5.88937 9.35 5.625 9.61437 5.625 9.9375V17.575C5.625 18.2212 6.15375 18.75 6.8 18.75H14.4375C14.7606 18.75 15.025 18.4856 15.025 18.1625C15.025 17.8394 14.7606 17.575 14.4375 17.575H7.3875C7.06437 17.575 6.8 17.3106 6.8 16.9875V9.9375C6.8 9.61437 6.53562 9.35 6.2125 9.35ZM10.325 12.2875H15.025C15.3481 12.2875 15.6125 12.0231 15.6125 11.7C15.6125 11.3769 15.3481 11.1125 15.025 11.1125H10.325C10.0019 11.1125 9.7375 11.3769 9.7375 11.7C9.7375 12.0231 10.0019 12.2875 10.325 12.2875ZM12.675 14.6375H10.325C10.0019 14.6375 9.7375 14.3731 9.7375 14.05C9.7375 13.7269 10.0019 13.4625 10.325 13.4625H12.675C12.9981 13.4625 13.2625 13.7269 13.2625 14.05C13.2625 14.3731 12.9981 14.6375 12.675 14.6375ZM10.325 9.9375H15.025C15.3481 9.9375 15.6125 9.67313 15.6125 9.35C15.6125 9.02688 15.3481 8.7625 15.025 8.7625H10.325C10.0019 8.7625 9.7375 9.02688 9.7375 9.35C9.7375 9.67313 10.0019 9.9375 10.325 9.9375Z"
        fill="white"
      />
    </svg>
  );
};
