import { useEffect, useState } from 'react';

export const useCountdownForNavigate = (initialTime: number | null) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    if (time === null) return;

    const countdown = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime && prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(countdown);
          if (prevTime === 0) {
            setTimeout(() => setTime(null), 1000);
            return prevTime;
          }
          return null;
        }
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [time]);

  useEffect(() => {
    setTime(initialTime);
  }, [initialTime]);

  return time;
};
