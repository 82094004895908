import React, { useState } from 'react';
import { AccordionItem } from '.';

type AccordionProps = {
  items: { title: string; content: React.ReactNode }[];
};

export const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  const handleToggle = (index: number) => {
    setExpandedIndices((prevExpandedIndices) => {
      if (prevExpandedIndices.includes(index)) {
        return prevExpandedIndices.filter((i) => i !== index);
      } else {
        return [...prevExpandedIndices, index];
      }
    });
  };

  return (
    <div className="flex justify-between flex-wrap">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          isExpanded={expandedIndices.includes(index)}
          onToggle={() => handleToggle(index)}
        >
          {item.content}
        </AccordionItem>
      ))}
    </div>
  );
};
