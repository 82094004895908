import { useState, useEffect } from 'react';
import { selectUserPost } from '../../../../state/selectors/userPostSelector';
import { useAppSelector } from '../../../../state';

export const usePostFormData = () => {
  const post = useAppSelector(selectUserPost);
  const [postName, setPostName] = useState(post?.title || '');
  const [markdown, setMarkdown] = useState(post?.markdown || '');
  const [userCreatorId, setUserCreatorId] = useState(
    String(post?.userId || '')
  );

  // Initialize post data when post changes
  useEffect(() => {
    setPostName(post?.title || '');
    setMarkdown(post?.markdown || '');
    setUserCreatorId(String(post?.userId) || '');
  }, [post]);

  // Handler to change post name
  const onChangePostName = (name: string) => setPostName(name);

  // Handler to change markdown content
  const onChangeMarkdown = (value: string) => setMarkdown(value);

  return {
    postName,
    markdown,
    userCreatorId,
    onChangePostName,
    onChangeMarkdown
  };
};
