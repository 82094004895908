import { AppState } from '../store';

export const selectCommunities = (state: AppState) =>
  state.communities.communities;
export const selectCommunitiesLoading = (state: AppState) =>
  state.communities.loading;
export const selectCommunitiesError = (state: AppState) =>
  state.communities.error;

export const selectCommunitiesCreation = (state: AppState) =>
  state.communities.creationDefaultCommunities;
export const selectCommunitiesCreationLoading = (state: AppState) =>
  state.communities.creationDefaultLoading;
export const selectCommunitiesCreationError = (state: AppState) =>
  state.communities.creationDefaultError;

export const selectLandingCommunities = (state: AppState) =>
  state.communities.landingCommunities;
export const selectLandingCommunitiesLoading = (state: AppState) =>
  state.communities.landingLoading;
export const selectLandingCommunitiesError = (state: AppState) =>
  state.communities.landingError;

export const selectCommunitiesSearchResults = (state: AppState) =>
  state.communities.searchResults;
export const selectCommunitiesSearchLoading = (state: AppState) =>
  state.communities.searchLoading;
export const selectCommunitiesSearchError = (state: AppState) =>
  state.communities.searchError;

export const selectCommunitiesCreationSearchResults = (state: AppState) =>
  state.communities.creationSearchCommunities;
export const selectCommunitiesCreationSearchLoading = (state: AppState) =>
  state.communities.creationSearchLoading;
export const selectCommunitiesCreationSearchError = (state: AppState) =>
  state.communities.creationSearchError;
