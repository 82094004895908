import classNames from 'classnames';
import { textColors } from '../textColors';

export interface Props {
  className?: string;
  size?: boolean;
  color?: string;
  children: React.ReactNode;
}

export const HeadingOne: React.FC<Props> = (props: Props) => {
  const { className = '', children, color = 'white', size = false } = props;
  const headingClass = classNames(
    `${textColors[color as keyof typeof textColors]}`,
    { 'text-3xl': !size },
    className
  );

  return <h1 className={headingClass}>{children}</h1>;
};
