import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import { selectUserPost } from '../../../state/selectors/userPostSelector';
import { getUserProfile } from '../../../state/actions/userActions';
import { selectUserProfile } from '../../../state/selectors/userSelector';
import { useAuth } from '../../../state/hooks/useAuth';

export const useGetPostUserDetails = () => {
  const dispatch = useAppDispatch();
  const userPostData = useAppSelector(selectUserPost);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserCreator, setIsUserCreator] = useState(false);
  const profileData = useAppSelector(selectUserProfile);
  const { user } = useAuth();
  useEffect(() => {
    if (!profileData) {
      dispatch(getUserProfile());
    }
  }, [dispatch, profileData]);

  useEffect(() => {
    if (profileData) {
      setIsUserAdmin(profileData.superUser);
      setIsUserCreator(
        String(profileData.id) === String(userPostData?.createdByUser?.id)
      );
    }
  }, [profileData, userPostData, user]);

  return { isUserAdmin, isUserCreator };
};
