import classNames from 'classnames';

interface Props {
  className?: string;
}

export const HelpMenuLink: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 2.99512C6.48 2.99512 2 7.47512 2 12.9951C2 18.5151 6.48 22.9951 12 22.9951C17.52 22.9951 22 18.5151 22 12.9951C22 7.47512 17.52 2.99512 12 2.99512ZM13 19.9951H11V17.9951H13V19.9951ZM15.07 12.2451L14.17 13.1651C13.45 13.8951 13 14.4951 13 15.9951H11V15.4951C11 14.3951 11.45 13.3951 12.17 12.6651L13.41 11.4051C13.78 11.0451 14 10.5451 14 9.99512C14 8.89512 13.1 7.99512 12 7.99512C10.9 7.99512 10 8.89512 10 9.99512H8C8 7.78512 9.79 5.99512 12 5.99512C14.21 5.99512 16 7.78512 16 9.99512C16 10.8751 15.64 11.6751 15.07 12.2451Z"
        fill="black"
      />
    </svg>
  );
};
