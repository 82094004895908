import { useState, useEffect, useCallback, FormEvent } from 'react';
import debounce from 'lodash/debounce';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { notEmpty } from '../../../../utils/helperFunctions';
import { Tag } from '../../updatePostV2/updatePostContainer';

interface UseTopicsCommunitiesFormDataParams {
  selectInitialData: (state: any) => any;
  selectSearchResults: (state: any) => any;
  selectLoading: (state: any) => boolean;
  selectSearchLoading: (state: any) => boolean;
  fetchDataAction: (params: any) => any;
  searchDataAction: (params: any) => any;
  target: string;
  entityType: 'topic' | 'community';
}

export const useTopicsCommunitiesFormData = ({
  selectInitialData,
  selectSearchResults,
  selectLoading,
  selectSearchLoading,
  fetchDataAction,
  searchDataAction,
  target,
  entityType
}: UseTopicsCommunitiesFormDataParams) => {
  const dispatch = useAppDispatch();

  const initialData = useAppSelector(selectInitialData);
  const searchResults = useAppSelector(selectSearchResults);
  const defaultLoading: boolean = useAppSelector(selectLoading);
  const searchLoading: boolean = useAppSelector(selectSearchLoading);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isInitialTagManuallyRemoved, setIsInitialTagManuallyRemoved] =
    useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  // Assume selectedEntity comes from a similar state slice as in original hooks
  const { selectedCommunityOrTopic } = useAppSelector(
    (state) => state.modal.createPost
  );

  // Fetch initial data on mount
  useEffect(() => {
    dispatch(
      fetchDataAction({
        page: 1,
        pageSize: 20,
        sortMethod: 'comments_activity_desc',
        communityType: entityType === 'community' ? 1 : undefined,
        target
      })
    );
  }, [dispatch, fetchDataAction, target, entityType]);

  // Debounced search function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((newValue: string) => {
      if (newValue.length >= 1) {
        setIsSearching(true);
        setCurrentPage(1);
        dispatch(
          searchDataAction({
            page: 1,
            pageSize: 20,
            term: newValue,
            target
          })
        );
      } else if (newValue.length === 0) {
        setIsSearching(false);
        setCurrentPage(1);
        dispatch(
          fetchDataAction({
            page: 1,
            pageSize: 20,
            sortMethod: 'comments_activity_desc',
            communityType: entityType === 'community' ? 1 : undefined,
            target
          })
        );
      }
    }, 500),
    [dispatch, searchDataAction, fetchDataAction, target, entityType]
  );

  // Handle input changes and trigger debounced search
  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setInputValue(newValue);
    debouncedSearch(newValue);
  };

  // Add the selectedCommunityOrTopic to selectedTags if it matches the entityType
  useEffect(() => {
    if (
      selectedCommunityOrTopic?.entityType === entityType &&
      !isInitialTagManuallyRemoved
    ) {
      const { name, urlKey } = selectedCommunityOrTopic;

      // Add only if it's not already selected
      if (!selectedTags.some((tag) => tag.urlKey === urlKey)) {
        setSelectedTags((prev) => [...prev, { name, urlKey }]);
        // Remove from availableTags if present
        setAvailableTags((prev) => prev.filter((tag) => tag.urlKey !== urlKey));
      }
    }
  }, [
    selectedCommunityOrTopic,
    selectedTags,
    isInitialTagManuallyRemoved,
    entityType
  ]);

  // Function to update available tags with pagination
  const updateAvailableTags = useCallback(
    (newTags: Tag[], numPagesFromResponse: number) => {
      setAvailableTags((prevTags) => {
        // Filter out any tags that are already selected
        const filteredNewTags = newTags.filter(
          (tag) =>
            !selectedTags.some(
              (selectedTag) => selectedTag.urlKey === tag.urlKey
            )
        );

        // Update hasMore based on server response
        setHasMore(currentPage < numPagesFromResponse);

        if (currentPage === 1) {
          // Replace availableTags for the first page
          return filteredNewTags;
        } else {
          // Append to availableTags for subsequent pages, ensuring no duplicates
          const uniqueTags = filteredNewTags.filter(
            (tag) => !prevTags.some((prevTag) => prevTag.urlKey === tag.urlKey)
          );
          return [...prevTags, ...uniqueTags];
        }
      });
    },
    [selectedTags, currentPage]
  );

  // Update available tags when initialData or searchResults change
  useEffect(() => {
    const source = isSearching ? searchResults : initialData;
    if (!source) return;

    const newTags: Tag[] =
      source.page
        ?.map((item: any) => ({
          name: item.name || '',
          urlKey: item.urlKey || ''
        }))
        .filter(notEmpty) || [];

    updateAvailableTags(newTags, source.numPages || 1);
  }, [initialData, searchResults, isSearching, updateAvailableTags]);

  // Remove a tag from selected tags and add it back to the available list
  const removeTag = (tagUrlKey: string) => {
    const newList = selectedTags.filter((tag) => tag.urlKey !== tagUrlKey);
    setSelectedTags(newList);

    if (selectedCommunityOrTopic?.urlKey === tagUrlKey) {
      setIsInitialTagManuallyRemoved(true);
    }

    // Re-add the tag to availableTags
    setAvailableTags((prevTags) => {
      const tagToReAdd = isSearching
        ? searchResults?.page?.find((item: any) => item.urlKey === tagUrlKey)
        : initialData?.page?.find((item: any) => item.urlKey === tagUrlKey);

      if (tagToReAdd) {
        const tag: Tag = {
          name: tagToReAdd.name || '',
          urlKey: tagToReAdd.urlKey || ''
        };
        // Ensure no duplicates
        if (!prevTags.some((t) => t.urlKey === tag.urlKey)) {
          return [...prevTags, tag];
        }
      }
      return prevTags;
    });
  };

  // Add a tag to selected tags and remove it from the available list
  const addTag = (tagUrlKey: string) => {
    const tagToAdd = availableTags.find((tag) => tag.urlKey === tagUrlKey);
    if (tagToAdd && !selectedTags.some((tag) => tag.urlKey === tagUrlKey)) {
      setSelectedTags([tagToAdd, ...selectedTags]);
      setAvailableTags((prev) => prev.filter((t) => t.urlKey !== tagUrlKey));
    }
  };

  // Fetch more data for infinite scrolling
  const fetchMoreData = useCallback(() => {
    if (!defaultLoading && !searchLoading && !isFetching && hasMore) {
      setIsFetching(true);
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      if (isSearching) {
        dispatch(
          searchDataAction({
            page: nextPage,
            pageSize: 20,
            term: inputValue,
            target
          })
        )
          .catch(() => {
            setHasMore(false);
          })
          .finally(() => setIsFetching(false));
      } else {
        dispatch(
          fetchDataAction({
            page: nextPage,
            pageSize: 20,
            sortMethod: 'comments_activity_desc',
            communityType: entityType === 'community' ? 1 : undefined,
            target
          })
        )
          .catch(() => {
            setHasMore(false);
          })
          .finally(() => setIsFetching(false));
      }
    }
  }, [
    defaultLoading,
    searchLoading,
    isFetching,
    hasMore,
    currentPage,
    dispatch,
    isSearching,
    inputValue,
    searchDataAction,
    fetchDataAction,
    target,
    entityType
  ]);

  return {
    selectedTags,
    setSelectedTags,
    availableTags,
    inputValue,
    setInputValue,
    removeTag,
    addTag,
    handleInputChange,
    fetchMoreData,
    hasMore
  };
};
