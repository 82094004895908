import React, { useCallback } from 'react';
import { Register } from '../../routes/register';
import { Login } from '../../routes/login';
import { CreateCommunityContainer } from '../../routes/community/createCommunity/createCommunityContainer';
import { EditCommunityContainer } from '../../routes/community/editCommunity/editCommunityContainer';
import { useAppSelector } from '../../state';
import { UpdatePostContainer } from '../../routes/post/updatePostV2/updatePostContainer';
import { Modal, useModalHash } from '../modals';
import { ShareIcons } from '../share';
import { NewPostContainer } from '../../routes/post/createPostV2';

interface TopBarModalsProps {
  closeModal: (modalName: string) => void;
}

export const TopBarModals: React.FC<TopBarModalsProps> = ({ closeModal }) => {
  const modalMap = {
    login: 'login',
    signup: 'signup'
  };

  const { closeModalAndRemoveHash } = useModalHash(modalMap);

  const modalState = useAppSelector((state) => state.modal['share']);
  const shareUrl = modalState?.url || '';

  const handleCloseSignup = useCallback(() => {
    closeModalAndRemoveHash('signup');
  }, [closeModalAndRemoveHash]);

  const handleCloseLogin = useCallback(() => {
    closeModalAndRemoveHash('login');
  }, [closeModalAndRemoveHash]);

  const handleCloseCreatePost = useCallback(() => {
    closeModal('createPost');
  }, [closeModal]);

  const handleCloseUpdatePost = useCallback(() => {
    closeModal('updatePost');
  }, [closeModal]);

  const handleCloseCreateCommunity = useCallback(() => {
    closeModal('createCommunity');
  }, [closeModal]);

  const handleCloseUpdateCommunity = useCallback(() => {
    closeModal('updateCommunity');
  }, [closeModal]);

  return (
    <>
      <Modal
        modalName="signup"
        className="authModal"
        title="Sign Up"
        onClose={handleCloseSignup}
      >
        <Register closeModal={handleCloseSignup} />
      </Modal>
      <Modal
        modalName="login"
        className="authModal"
        title="Login"
        onClose={handleCloseLogin}
      >
        <Login closeModal={handleCloseLogin} />
      </Modal>
      <Modal modalName="createPost" className="createPostPageModal">
        <NewPostContainer closeModal={handleCloseCreatePost} />
      </Modal>
      <Modal modalName="updatePost" className="createPostPageModal">
        <UpdatePostContainer closeModal={handleCloseUpdatePost} />
      </Modal>
      <Modal modalName="createCommunity" className="createPostPageModal">
        <CreateCommunityContainer closeModal={handleCloseCreateCommunity} />
      </Modal>
      <Modal modalName="updateCommunity" className="createPostPageModal">
        <EditCommunityContainer closeModal={handleCloseUpdateCommunity} />
      </Modal>
      <Modal modalName="share" title="Share" className="">
        <ShareIcons shareUrl={shareUrl} />
      </Modal>
    </>
  );
};
