const { REACT_APP_API_URL, NODE_ENV, PUBLIC_URL } = process.env;
export const env = {
  apiUrl:
    window.location.hostname === 'localhost'
      ? REACT_APP_API_URL
      : window.location.origin,
  env: NODE_ENV,
  public: PUBLIC_URL,
  recentTimeWindow: 72
};
