import { useCallback } from 'react';
import { useAppDispatch } from '../../../state';
import { deleteCommunity } from '../../../state/actions';

interface IUseDeleteCommunityParams {
  urlKey: string;
}

export function useDeleteCommunity(
  onSuccess?: () => void,
  onError?: (error: any) => void
) {
  const dispatch = useAppDispatch();

  return useCallback(
    ({ urlKey }: IUseDeleteCommunityParams) => {
      dispatch(deleteCommunity({ urlKey }))
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((error) => {
          if (onError) onError(error);
        });
    },
    [dispatch, onSuccess, onError]
  );
}
