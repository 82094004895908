import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CommunityType, staticCommunities } from './';
import { useAppSelector } from '../state';
import { selectLandingCommunities } from '../state/selectors/communitiesSelector';
import { randomInteger } from '../utils/helperFunctions';

export const LandingCommunitiesList: React.FC<{ isStatic: boolean }> = ({
  isStatic = true
}) => {
  const dynamicCommunities = useAppSelector(selectLandingCommunities);
  const [communities, setCommunities] = useState<CommunityType[]>([]);

  useEffect(() => {
    if (isStatic) {
      setCommunities(modifyCommunities(staticCommunities));
    }
  }, [isStatic]);

  useEffect(() => {
    if (!isStatic && dynamicCommunities?.page) {
      const filteredAndModified = modifyCommunities(
        dynamicCommunities.page.filter(
          (item) =>
            item.name && !item.name.includes('oipub') && item.numPapers !== 0
        )
      );
      setCommunities(filteredAndModified);
    }
  }, [dynamicCommunities, isStatic]);

  const modifyCommunities = (communities: CommunityType[]): CommunityType[] => {
    return communities
      .map((community) => {
        let modifiedName = community.name
          ? community.name.replace(/Community$/i, '').trim()
          : '';
        if (modifiedName.length > 20) {
          modifiedName = modifiedName.slice(0, randomInteger(20, 40));
        }

        return {
          ...community,
          updatedName: modifiedName
        };
      })
      .slice(0, 8);
  };

  useEffect(() => {
    updateTooltipEvents();

    window.addEventListener('resize', updateTooltipEvents);
    return () => {
      window.removeEventListener('resize', updateTooltipEvents);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communities]);

  const handleTooltipClick = (e: Event) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLElement;
    target.classList.toggle('tooltip-visible');
  };

  const updateTooltipEvents = () => {
    const isMobile = window.innerWidth < 768;
    const tooltipElements = document.querySelectorAll('.tooltipMobile');

    tooltipElements.forEach((elem) => {
      if (isMobile) {
        elem.removeEventListener('click', handleTooltipClick);
        elem.addEventListener('click', handleTooltipClick);
      } else {
        elem.removeEventListener('click', handleTooltipClick);
      }
    });
  };

  return (
    <div id="community-list">
      {communities.map((community, index) => (
        <Link
          key={index}
          to={
            'link' in community && community.link
              ? community.link
              : `/community/${community.urlKey}`
          }
          className="btn btn--fit pos-rel"
          data-tooltip={community.name}
        >
          {'updatedName' in community && community.updatedName ? (
            <>
              {community.updatedName}{' '}
              <span className="tooltipMobile" data-tooltip={community.name}>
                ...
              </span>
            </>
          ) : (
            community.name
          )}
        </Link>
      ))}
    </div>
  );
};
