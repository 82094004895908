import { FC } from 'react';
import { Loading } from '../../components/core';
import { TopicPage } from './topicPage';
import { useParams } from 'react-router-dom';
import { useGetUserIsAdmin } from '../../components/user';
import { useGetTopicData } from './hooks/useGetTopicData';
import { useAppSelector } from '../../state';
import {
  selectTopic,
  selectTopicError,
  selectTopicLoading
} from '../../state/selectors/topicSelector';
import { NotFound } from '../not-found';
import { TopicTopBar } from '../../components/core/topBars/topicTopBar';

interface Props {}

export const TopicContainer: FC<Props> = () => {
  const { urlKey } = useParams();
  useGetTopicData({ urlKey: urlKey || '' });
  useGetUserIsAdmin();

  const loading = useAppSelector(selectTopicLoading);
  const topic = useAppSelector(selectTopic);
  const topicError = useAppSelector(selectTopicError);

  if (loading) return <Loading className="imp-static" />;

  if (!topic && topicError) {
    return <NotFound />;
  }

  return (
    <TopicTopBar>
      <main className="page-content max-w-2/3">
        <TopicPage topic={topic} />
      </main>
    </TopicTopBar>
  );
};
