import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { search } from '../actions/searchActions';
import { ISearchType } from '../../types/search.type';

interface SearchState {
  term: string;
  pageNum: number;
  searchType: ISearchType;
  loading: boolean;
  error: string | null;
  requestAbortedForNew: boolean;
}

export const initialState: SearchState = {
  term: '',
  pageNum: 1,
  searchType: ISearchType.Papers,
  loading: false,
  error: null,
  requestAbortedForNew: false
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setQuery: (
      state,
      action: PayloadAction<{
        term: string;
        pageNum: number;
        searchType: ISearchType;
      }>
    ) => {
      state.term = action.payload.term;
      state.pageNum = action.payload.pageNum;
      state.searchType = action.payload.searchType;
    },
    clearSearch: (state) => {
      state.term = '';
      state.pageNum = 1;
      state.searchType = ISearchType.Papers;
      state.loading = false;
      state.error = null;
      state.requestAbortedForNew = false;
    },
    abortRequestForNew: (state) => {
      state.requestAbortedForNew = true;
    }
  },
  extraReducers: (builder) => {
    // Handle search lifecycle actions
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.requestAbortedForNew = false;
      })
      .addCase(search.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.requestAbortedForNew = false;
      })
      .addCase(search.rejected, (state, action) => {
        state.loading = false;
        if (action.error.name !== 'AbortError' && !state.requestAbortedForNew) {
          state.error =
            action.error.message || 'Failed to fetch topics/communities';
        }
      });
  }
});

export const { setQuery, clearSearch, abortRequestForNew } =
  searchSlice.actions;

export default searchSlice.reducer;
