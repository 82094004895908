import DOMPurify from 'dompurify';
import { ALLOWED_ATTR, ALLOWED_TAGS } from '../../utils/constants';

type RenderHTMLProps = {
  htmlContent: string | null | undefined;
  className?: string;
};

export const RenderHTML: React.FC<RenderHTMLProps> = ({
  htmlContent,
  className = ''
}: RenderHTMLProps) => {
  const content = htmlContent ?? '';

  const sanitizedMarkdown = DOMPurify.sanitize(content, {
    ALLOWED_TAGS: ALLOWED_TAGS,
    ALLOWED_ATTR: ALLOWED_ATTR
  });

  // Fallback to an empty string if htmlContent is null or undefined
  return (
    <div
      className={`${className}`}
      dangerouslySetInnerHTML={{ __html: sanitizedMarkdown }}
    />
  );
};
