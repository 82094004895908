import { FC } from 'react';
import { usePapersDiscussionsTabs } from './hooks/usePapersDiscussionsTabs';
import { usePapersCount } from './hooks/usePaperCount';
import { Tooltip } from '../core/tooltip';

interface PapersDiscussionsTabsProps {
  hidePapersTab?: boolean;
  contentUrlKey: string;
  type: 'topic' | 'community';
}

export const PapersDiscussionsTabs: FC<PapersDiscussionsTabsProps> = ({
  hidePapersTab,
  contentUrlKey,
  type
}) => {
  const { handlePapersClick, handleDiscussionsClick, radioState } =
    usePapersDiscussionsTabs({
      contentUrlKey,
      hidePapersTab
    });

  const { papersAllCount, formattedCount, loading } = usePapersCount(
    contentUrlKey,
    type
  );

  return (
    <main className="flex flex-col justify-start w-full">
      <div className="tabs-row flex justify-start px-4">
        <div className="tabs max-w-3xl w-full m-auto">
          {!hidePapersTab && (
            <button
              className={`tab-button py-4 ${radioState === 'papers' ? 'active' : ''}`}
              onClick={handlePapersClick}
            >
              <span className="papers-label">
                {!loading ? (
                  <Tooltip
                    className="tabsTooltip"
                    text={`${papersAllCount} papers in the ${type}`}
                  >
                    {'Papers '}
                    {papersAllCount !== 0 && (
                      <>
                        <span className="papers-count">({formattedCount})</span>
                      </>
                    )}
                  </Tooltip>
                ) : (
                  'Papers'
                )}
              </span>
            </button>
          )}
          <button
            className={`py-4 ${radioState === 'discussions' ? 'active' : ''}`}
            onClick={handleDiscussionsClick}
          >
            Discussions
          </button>
        </div>
      </div>
    </main>
  );
};
