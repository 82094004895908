import classNames from 'classnames';

interface Props {
  className?: string;
}

export const DiscussionIcon: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      width="14"
      height="12"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0Z"
        fill="#EE9844"
      />
      <path
        d="M25.5 4.5H9.5C8.4 4.5 7.5 5.4 7.5 6.5V18.5C7.5 19.6 8.4 20.5 9.5 20.5H23.5L27.5 24.5V6.5C27.5 5.4 26.6 4.5 25.5 4.5ZM23.5 18.5H10.5C9.95 18.5 9.5 18.05 9.5 17.5V7.5C9.5 6.95 9.95 6.5 10.5 6.5H24.5C25.05 6.5 25.5 6.95 25.5 7.5V20.5L23.5 18.5Z"
        fill="#EE9844"
      />
    </svg>
  );
};
