import { useState, useEffect } from 'react';

export const useCountdown = (initialTime: number | null) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    if (time === null) return;

    const countdown = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime && prevTime > 1) {
          return prevTime - 1;
        } else {
          clearInterval(countdown);
          return null;
        }
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [time]);

  useEffect(() => {
    setTime(initialTime);
  }, [initialTime]);

  return time;
};
