import React from 'react';
import './css/reset.css';
import './css/style.css';
import { env } from '../env';
import { createLink } from '../utils/helperFunctions';
import { Link } from 'react-router-dom';
import { LandingCommunitiesList, LandingTopicsList } from '.';
import { useGetLandingCommunitiesData } from './hooks/useGetLandingCommunitiesData';
import { useGetLandingTopicsData } from './hooks/useGetLandingTopicsData';
import { SURVEY_FORM_LINK } from './staticData.const';

export const LandingPage: React.FC = () => {
  useGetLandingCommunitiesData({
    page: 1,
    pageSize: 40,
    communityType: 1,
    sortMethod: 'comments_activity_desc'
  });
  useGetLandingTopicsData({
    page: 1,
    pageSize: 15,
    sortMethod: 'comments_activity_desc'
  });
  return (
    <div className="landing-page">
      <header>
        <div className="wrapper wrapper--header">
          <div className="container">
            <div className="header__block">
              <div className="logo_head">
                <Link className="location" to={'/'}>
                  <img
                    src={env.public + '/landing-images/logo.png'}
                    alt="Oipub"
                  />
                </Link>
              </div>
              <div className="nav_head">
                <nav id="head-nav">
                  <ul>
                    <li className="mr-15">
                      <Link
                        to={createLink('communities/#login')}
                        className="btn btn--white location"
                      >
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={createLink('communities/#signup')}
                        className="btn btn--orange location"
                      >
                        SIGN UP
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="header-fixed-space"></div>
      <section className="discover">
        <div className="wrapper wrapper--discover">
          <div className="discover__block">
            <div className="discover__block-text">
              <div className="discover__block-text-size">
                <h1>
                  <span className="color-orange weight-300">
                    Discover & Discuss research in your field
                  </span>
                </h1>
                <p className="color-silver">
                  OiPub makes scientific research easier for scientists to find,
                  follow and discuss. Our design concept is built around our
                  tagging, broadcasting and privileges system. This makes it
                  easier and better than ever to discover the right information
                  and share ideas with experts and peers in every niche.
                </p>
                <div className="discover__block-btns">
                  <Link
                    to={createLink('/communities')}
                    className="btn btn--orange btn--big mr-30 location"
                  >
                    Try Beta
                  </Link>
                  <div className="weight-600 text-mdd">Completely Free.</div>
                </div>
              </div>
            </div>
            <div className="discover__block-image">
              <img
                src={env.public + '/landing-images/discover.png'}
                alt="Discover"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="communities--sect">
        <div className="wrapper">
          <div
            className="communities bg-ellipse"
            style={{
              backgroundImage: `url(${env.public}/landing-images/ellipse2.png)`
            }}
          >
            <div className="comm-text bg-orange">
              <div className="comm-text-container">
                <div className="comm-text-size">
                  <h2 className="weight-500 mb-25">
                    Explore Popular Communities
                  </h2>
                  <div className="weight-300 mb-10 text-md">
                    Join a peer group, or start your own - you and your
                    colleagues can follow a set of topics together. Communities
                    are automatically kept up to date with all the latest and
                    most relevant research.
                  </div>
                  <div className="list-btns" id="community-list">
                    <LandingCommunitiesList isStatic={true} />
                  </div>
                </div>
              </div>
            </div>
            <div className="comm-imgs pos-rel">
              <img
                src={env.public + '/landing-images/comm-img.png'}
                alt="Communities image"
              />
              <div className="comm-img1">
                <img
                  src={env.public + '/landing-images/comm-img1.png'}
                  alt="separator"
                />
              </div>
              <div className="comm-img2">
                <img
                  src={env.public + '/landing-images/comm-img2.png'}
                  alt="separator"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="oipub">
        <div className="wrapper wrapper--oi">
          <div className="container">
            <div className="oi-parts">
              <div className="oi-text-left">
                <h3 className="mb-10 h1 weight-700">What is OiPub?</h3>
                <p className="color-silver">
                  OiPub is a web platform in the field of open science and
                  knowledge sharing that works to make information faster and
                  easier for researchers to find & share. We are building a new
                  research categorization and organization model with a social
                  network built around it. This is made possible by our NLP data
                  systems that automatically recognize and track research
                  subjects in articles, and adapt article-level metrics (like
                  citations, views, etc.) into topic-level metrics. This allows
                  us to create information & discussion hubs for any and every
                  topic, which users can further combine into custom communities
                  which track research and discussion in any custom research
                  niche they desire.
                </p>
              </div>
              <div className="oi-text-right">
                <div className="weight-800 text-lg color-orange mb-3">
                  Our Mission
                </div>
                <div className="weight-700 mb-3">We aim to:</div>
                <ul>
                  <li>Make Papers easier to find</li>
                  <li>Make Research niches easier to follow</li>
                  <li>Make Publishing open research faster and cheaper</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="partners">
        <div className="wrapper">
          <div className="container w-100">
            <h3 className="weight-300 text-center">
              Partners, Service Providers & Supporters
            </h3>
            <div className="partners-images">
              <div
                className="partners-image"
                style={{
                  backgroundImage: `url(${env.public}/landing-images/partner1.png)`
                }}
              ></div>
              <div
                className="partners-image"
                style={{
                  backgroundImage: `url(${env.public}/landing-images/partner2.png)`
                }}
              ></div>
              <div
                className="partners-image"
                style={{
                  backgroundImage: `url(${env.public}/landing-images/partner3.png)`
                }}
              ></div>
              <div
                className="partners-image"
                style={{
                  backgroundImage: `url(${env.public}/landing-images/partner4.png)`
                }}
              ></div>
              <div
                className="partners-image"
                style={{
                  backgroundImage: `url(${env.public}/landing-images/partner5.png)`
                }}
              ></div>
              <div
                className="partners-image"
                style={{
                  backgroundImage: `url(${env.public}/landing-images/partner6.png)`
                }}
              ></div>
              <div
                className="partners-image"
                style={{
                  backgroundImage: `url(${env.public}/landing-images/partner7.png)`
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section className="topics--sect">
        <div className="wrapper">
          <div
            className="communities topics bg-ellipse"
            style={{
              backgroundImage: `url(${env.public}/landing-images/ellipse3.png)`
            }}
          >
            <div className="comm-text bg-orange">
              <div className="comm-text-container">
                <div className="comm-text-size">
                  <h2 className="weight-500 mb-25">Explore Popular Topics</h2>
                  <div className="weight-300 mb-10 text-md">
                    Our proprietary AI system extracts and categories all
                    incoming papers into a wide range of topics. You can follow
                    as many as you like and receive updates in your own feed.
                  </div>
                  <div className="list-btns" id="topic-list">
                    <LandingTopicsList isStatic={true} />
                  </div>
                </div>
              </div>
            </div>
            <div className="comm-imgs pos-rel">
              <img
                src={env.public + '/landing-images/top-img.png'}
                alt="Communities image"
              />
              <div className="comm-img1 top-img1">
                <img
                  src={env.public + '/landing-images/top-img1.png'}
                  alt="separator"
                />
              </div>
              <div className="comm-img2 top-img2">
                <img
                  src={env.public + '/landing-images/top-img2.png'}
                  alt="separator"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="say">
        <div className="wrapper wrapper--oi">
          <div className="container">
            <div className="say-parts">
              <div className="say-text">
                <h3 className="h1 weight-700">Have your Say</h3>
                <p className="color-silver">
                  We value your experience and insights as we continue to
                  improve our website. Your feedback is crucial to us during
                  this beta phase. By taking a few moments to complete our
                  survey, you help us understand what works well and what needs
                  enhancement. This allows us to prioritize our efforts and
                  deliver the features and improvements that matter most to you,
                  more efficiently and effectively. Join us in shaping the
                  future of our platform - your input makes a real difference!
                </p>
                <a
                  href={SURVEY_FORM_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn--white"
                >
                  Take Survey
                </a>
              </div>
              <div className="say-img pos-rel">
                <img
                  src={env.public + '/landing-images/sayx2.png'}
                  alt="Have your Say"
                />
                <div className="say-img1">
                  <img
                    src={env.public + '/landing-images/say-img1.png'}
                    alt="separator"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="wrapper wrapper--oi">
          <div className="container">
            <div className="footer-row">
              <div className="footer-left">
                © Copyright 2024 Omni Iota Science Limited
              </div>
              <div className="footer-right">
                <div className="footer-right__text">
                  This work was co-funded by the EOSC Future project under the
                  European Union Horizon Programme call INFRAEOSC-03-2020 -
                  Project ID 101017536
                </div>
                <div className="footer-right__image">
                  <img
                    src={env.public + '/landing-images/Flag_of_Europe.png'}
                    alt="Flag of Europe"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
