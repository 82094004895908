import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectPapers,
  selectPapersLoading
} from '../../../state/selectors/papersSelector';
import { resetPapersCount } from '../../../state/slices/papersReducer';
import {
  selectCommunityLoading,
  selectCommunityPapersData,
  selectTopicLoading,
  selectTopicPapersData
} from '../../../state/selectors/filterSelector';

/**
 * Formats the count based on the specified rules:
 * - count === 0 → show nothing
 * - count < 1000 → `${count}`
 * - 1000 <= count < 1,000,000 → `x.xk`
 * - 1,000,000 <= count < 1,000,000,000 → `x.xM`
 * - 1,000,000,000 <= count → `x.xB`
 *
 * @param count - The number to format.
 * @returns The formatted string.
 */
export const formatCount = (count?: number): string => {
  if (count === 0) {
    return '';
  }
  if (!count) {
    return '';
  }

  const units = ['', 'k', 'M', 'B'];
  const order = Math.floor(Math.log10(count) / 3);

  if (order === 0) {
    return `${count}`;
  }

  const unit = units[order];
  const scaledCount = count / Math.pow(1000, order);

  const formattedScaledCount =
    scaledCount % 1 === 0 ? scaledCount.toFixed(0) : scaledCount.toFixed(1);
  return `${formattedScaledCount}${unit}`;
};

/**
 * Manage papers count.
 *
 * @param contentUrlKey - The key associated with the current content.
 * @returns An object containing the formatted count and loading state.
 */

export const usePapersCount = (
  contentUrlKey: string,
  type: 'topic' | 'community'
) => {
  const dispatch = useAppDispatch();
  const papersCount = useAppSelector(selectPapers).count || 0;
  const papersFilterTopic = useAppSelector(selectTopicPapersData).count || 0;
  const papersFilterCommunity =
    useAppSelector(selectCommunityPapersData).count || 0;

  const isTopicFilterApplied = useAppSelector(
    (state) => state.filterPapers.isTopicFilterApplied
  );
  const isCommunityFilterApplied = useAppSelector(
    (state) => state.filterPapers.isCommunityFilterApplied
  );

  const papersAllCount = useMemo(() => {
    if (type === 'topic' && isTopicFilterApplied) {
      return papersFilterTopic;
    } else if (type === 'community' && isCommunityFilterApplied) {
      return papersFilterCommunity;
    } else {
      return papersCount;
    }
  }, [
    type,
    papersCount,
    papersFilterTopic,
    papersFilterCommunity,
    isTopicFilterApplied,
    isCommunityFilterApplied
  ]);

  const topicLoading = useAppSelector(selectTopicLoading);
  const communityLoading = useAppSelector(selectCommunityLoading);
  const generalLoading = useAppSelector(selectPapersLoading);

  const loading = useMemo(() => {
    if (type === 'topic') {
      return isTopicFilterApplied ? topicLoading : generalLoading;
    } else if (type === 'community') {
      return isCommunityFilterApplied ? communityLoading : generalLoading;
    }
    return generalLoading;
  }, [
    type,
    isTopicFilterApplied,
    isCommunityFilterApplied,
    topicLoading,
    communityLoading,
    generalLoading
  ]);

  // Reset the count when contentUrlKey changes
  useEffect(() => {
    dispatch(resetPapersCount());
  }, [contentUrlKey, dispatch]);

  const formattedCount = useMemo(
    () => formatCount(papersAllCount),
    [papersAllCount]
  );

  return { papersAllCount, formattedCount, loading };
};
