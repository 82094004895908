export const SET_CITES_CITEDBY_VIEW_MODE = 'SET_CITES_CITEDBY_VIEW_MODE';

export type ViewModeCitesCitedBy = 'single' | 'triple';

export interface SetViewModeCitesCitedByAction {
  type: typeof SET_CITES_CITEDBY_VIEW_MODE;
  payload: ViewModeCitesCitedBy;
}

export type ViewModeCitesCitedByAction = SetViewModeCitesCitedByAction;

export const setViewModeCitesCitedBy = (
  mode: ViewModeCitesCitedBy
): ViewModeCitesCitedByAction => ({
  type: SET_CITES_CITEDBY_VIEW_MODE,
  payload: mode
});
