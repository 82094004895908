import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createLink } from '../../../utils/helperFunctions';
import { IUserPostPreview } from '../../../types/userPost.type';

interface UseUserPostDataProps {
  userPost: IUserPostPreview;
}

export const useUserPostPreviewData = ({ userPost }: UseUserPostDataProps) => {
  const navigate = useNavigate();
  const renderedTopicsKeywords: JSX.Element[] = [];
  const showTopicsKeywordsCount = 2;

  const extractPreviewFromHTML = (
    htmlString: string,
    maxLength: number = 200
  ): string => {
    let truncatedHtml = htmlString.slice(0, maxLength);
    // This regex matches a partial tag starting with "<" that does not close with ">"
    truncatedHtml = truncatedHtml.replace(/<[^>]*$/, '');
    const doc = new DOMParser().parseFromString(truncatedHtml, 'text/html');
    return doc.body.innerHTML.trim();
  };

  const fullUrl = useMemo(() => {
    const currentUrl = window.location.origin;
    const postLink = createLink(`/posts/${userPost.id}`);
    return currentUrl + postLink;
  }, [userPost.id]);

  const previewContent = useMemo(
    () => extractPreviewFromHTML(userPost.markdown || ''),
    [userPost.markdown]
  );

  const handleTopicTagClick = useCallback(
    (
      event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
      urlKey: string | null | undefined
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (urlKey) {
        navigate(createLink(`/topic/${urlKey}`));
      }
    },
    [navigate]
  );

  if (userPost.topics) {
    let i = 0;
    for (const tag of userPost.topics.slice(0, showTopicsKeywordsCount)) {
      if (renderedTopicsKeywords.length < showTopicsKeywordsCount) {
        if (tag && tag.urlKey && tag.id) {
          renderedTopicsKeywords.push(
            <span
              role="button"
              onClick={(e) => handleTopicTagClick(e, tag.urlKey)}
              className="text-ssm mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline leading-none mr-1 mb-2 transition-colors duration-200 text-center"
              key={tag.id}
              style={{ cursor: 'pointer' }}
            >
              {tag.name}
              {i < userPost.topics.length - 1 ? ', ' : ' '}
            </span>
          );
          i++;
        }
      }
    }
  }

  return {
    fullUrl,
    previewContent,
    renderedTopicsKeywords
  };
};
