import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getIsUserAdmin, getUserProfile } from '../actions/userActions';
import { IUserProfileIsAdmin } from '../../types/user.type';
import { logoutUser } from '../actions/authActions';

interface IUserState {
  profile: IUserProfileIsAdmin | null;
  loading: boolean;
  error: string | null;
}

const initialState: IUserState = {
  profile: null,
  loading: false,
  error: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.profile = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIsUserAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getIsUserAdmin.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.loading = false;
      })
      .addCase(getIsUserAdmin.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch user profile';
        state.loading = false;
      })
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUserProfile.fulfilled,
        (state, action: PayloadAction<IUserProfileIsAdmin>) => {
          state.profile = action.payload;
          state.loading = false;
        }
      )
      .addCase(getUserProfile.rejected, (state, action) => {
        state.error = action.payload || 'Failed to fetch user profile'; // Use payload if available
        state.loading = false;
      })
      .addCase(logoutUser.fulfilled, () => {
        logout();
      });
  }
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
