import classNames from 'classnames';

import { Label } from './label';

export interface Props {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  label: string | JSX.Element;
  required?: boolean;
}

export const Checkbox: React.FC<Props> = ({
  className,
  checked = false,
  disabled = false,
  id,
  onChange,
  name,
  label,
  required
}: Props) => {
  return (
    <div className="items-state relative flex">
      <Label
        className={classNames('select-none customCheckbox text-ssm ', {
          'text-gray-500': disabled
        })}
        htmlFor={id}
      >
        <input
          className={classNames(
            'form-checkbox mt-1 cursor-pointer transition-colors hover:bg-primary-500 focus:bg-primary-500 disabled:pointer-events-none disabled:bg-gray-400',
            className
          )}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          id={id}
          name={name}
          required={required}
        />
        <span
          className={classNames('checkmark border-gray-500', className)}
        ></span>
        {label}
      </Label>
    </div>
  );
};
