import { useEffect, useState } from 'react';
import { IComment, ICommentsResponse } from '../../../types/comment.type';

export const useCommentCount = (
  comments?: IComment[] | ICommentsResponse | null
) => {
  const [commentCount, setCommentCount] = useState(0);

  useEffect(() => {
    const commentsList: IComment[] = Array.isArray(comments)
      ? comments
      : comments?.comments || [];

    const countNestedComments = (
      nestedComments: IComment[] | null | undefined
    ): number => {
      if (!nestedComments) return 0;
      return nestedComments.reduce(
        (acc, comment) => acc + 1 + countNestedComments(comment.comments),
        0
      );
    };

    const count = countNestedComments(commentsList);
    setCommentCount(count);
  }, [comments]);

  return commentCount;
};
