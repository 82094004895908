import classNames from 'classnames';

interface Props {
  className?: string;
}

export const CommentPost: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        d="M1.33317 0.166687H10.6665C11.3082 0.166687 11.8332 0.691687 11.8332 1.33335V8.33335C11.8332 8.97502 11.3082 9.50002 10.6665 9.50002H2.49984L0.166504 11.8334L0.172337 1.33335C0.172337 0.691687 0.691504 0.166687 1.33317 0.166687ZM8.9165 4.25002H3.08317C2.76234 4.25002 2.49984 4.51252 2.49984 4.83335C2.49984 5.15419 2.76234 5.41669 3.08317 5.41669H8.9165C9.23734 5.41669 9.49984 5.15419 9.49984 4.83335C9.49984 4.51252 9.23734 4.25002 8.9165 4.25002ZM6.58317 7.16669H3.08317C2.76234 7.16669 2.49984 6.90419 2.49984 6.58335C2.49984 6.26252 2.76234 6.00002 3.08317 6.00002H6.58317C6.904 6.00002 7.1665 6.26252 7.1665 6.58335C7.1665 6.90419 6.904 7.16669 6.58317 7.16669ZM3.08317 3.66669H8.9165C9.23734 3.66669 9.49984 3.40419 9.49984 3.08335C9.49984 2.76252 9.23734 2.50002 8.9165 2.50002H3.08317C2.76234 2.50002 2.49984 2.76252 2.49984 3.08335C2.49984 3.40419 2.76234 3.66669 3.08317 3.66669Z"
        fill="#EE9844"
      />
    </svg>
  );
};
