import classNames from 'classnames';

interface Props {
  className?: string;
}

export const ToggleChecked: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={classNames('block', className)}
      width="28"
      height="14"
      viewBox="0 0 28 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33341 0.333496H20.6667C24.3467 0.333496 27.3334 3.32016 27.3334 7.00016C27.3334 10.6802 24.3467 13.6668 20.6667 13.6668H7.33341C3.65341 13.6668 0.666748 10.6802 0.666748 7.00016C0.666748 3.32016 3.65341 0.333496 7.33341 0.333496ZM16.6667 7.00016C16.6667 9.2135 18.4534 11.0002 20.6667 11.0002C22.8801 11.0002 24.6667 9.2135 24.6667 7.00016C24.6667 4.78683 22.8801 3.00016 20.6667 3.00016C18.4534 3.00016 16.6667 4.78683 16.6667 7.00016Z"
        fill="#EE9844"
      />
    </svg>
  );
};
